import React, { useContext, useEffect, useState } from "react";
import { Accordion, Button, Form, Table } from "react-bootstrap";
import Section from "../../components/Section";
import { EnemyContext } from "../../hooks/EmberbrandContext";
import { getDocs, query, where } from "firebase/firestore";
import { enemiesCol } from "../../firebaseSetup";
import QuillEditor from "../../components/QuillEditor";
import ExampleSection from "../../components/ExampleSection";

function EncounterBuilder() {
  const [selectedEnemy, setSelectedEnemy] = useState(null);
  const [encounterEnemies, setEncounterEnemies] = useState([]);

  const handleSetSelectedEnemy = enemy => {
    setSelectedEnemy(enemy);
  };

  const handleAddEnemyToEncounter = enemy => {
    const enemyQuery = query(enemiesCol, where("name", "==", enemy));

    getDocs(enemyQuery).then(snapshot => {
      snapshot.docs.forEach(doc => {
        const docData = doc.data();
        const enemyData = { ...docData, id: encounterEnemies.length + 1 };

        setEncounterEnemies(encounterEnemies => [
          ...encounterEnemies,
          enemyData,
        ]);
      });
    });
  };

  const handleRemoveEnemy = enemyId => {
    const filterEnemyList = encounterEnemies.filter(
      enemy => enemy.id !== enemyId
    );

    setEncounterEnemies(filterEnemyList);
  };

  return (
    <Section>
      <h1 className="title-header">Encounter Builder</h1>
      {encounterEnemies.map(enemy => {
        return (
          <EnemyCollapse
            key={enemy.id}
            enemy={enemy}
            remove={handleRemoveEnemy}
          />
        );
      })}
      <EnemyList change={handleSetSelectedEnemy} />
      <Button
        onClick={() => handleAddEnemyToEncounter(selectedEnemy)}
        style={{ marginTop: "20px" }}
      >
        Add Enemy to Encounter
      </Button>
    </Section>
  );
}

function EnemyCollapse({ enemy, remove }) {
  const [enemyId] = useState(enemy.id);
  const [enemyHealth, setEnemyHealth] = useState(enemy.health);
  const enemyStatSpecial = enemy.statSpecial;
  const enemyWeaponsSpecial = enemy.weaponsSpecial;
  const [enemyNotes, setEnemyNotes] = useState("");

  const handleSetEnemyHealth = e => {
    setEnemyHealth(e.target.value);
  };

  const handleSetEnemyNotes = e => {
    setEnemyNotes(e);
  };

  return (
    <Accordion className="enemy">
      <Accordion.Item eventKey={enemy.name}>
        <Accordion.Header>
          <h1 className="title-header">{enemy.name}</h1>
        </Accordion.Header>
        <Accordion.Body>
          <h2>Health</h2>
          <input value={enemyHealth} size={6} onChange={handleSetEnemyHealth} />

          {/* Enemy stats */}
          <Table className="text-left" striped style={{ marginTop: "20px" }}>
            <tbody>
              {enemy.armor ? (
                <tr>
                  <th>Armor</th>
                  <td>{enemy.armor}</td>
                </tr>
              ) : null}
              <tr>
                <td>
                  <b>Body</b>
                  <br />
                  <sub>STR, AGI, PRE, INV, RES</sub>
                </td>
                <td>{enemy.body}%</td>
              </tr>
              <tr>
                <td>
                  <b>Mind</b>
                  <br />
                  <sub>FOC, DEX, CUN, KND, WIL</sub>
                </td>
                <td>{enemy.mind}%</td>
              </tr>
              <tr>
                <td>
                  <b>Spirit</b>
                  <br />
                  <sub>LCK, SVL, MLE, IST, END</sub>
                </td>
                <td>{enemy.spirit}%</td>
              </tr>
            </tbody>
          </Table>
          {enemyStatSpecial &&
          enemyStatSpecial !== "<p><br></p>" &&
          enemyStatSpecial !== "<h2><br></h2>" ? (
            <ExampleSection>
              <div
                className="text-left"
                dangerouslySetInnerHTML={{ __html: enemyStatSpecial }}
              />
            </ExampleSection>
          ) : null}

          {/* Enemy weapons */}
          <Table striped responsive style={{ marginTop: "20px" }}>
            <thead className="text-left">
              <tr>
                <th>Weapon</th>
                <th>Damage</th>
                <th>Tags</th>
              </tr>
            </thead>
            <tbody>
              {enemy.weapons.map(weapon => (
                <tr key={weapon.id} className="text-left">
                  <td>{weapon.name}</td>
                  <td>{weapon.damage}</td>
                  <td>{weapon.tags}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {enemyWeaponsSpecial &&
          enemyWeaponsSpecial !== "<p><br></p>" &&
          enemyWeaponsSpecial !== "<h2><br></h2>" ? (
            <ExampleSection>
              <div
                className="text-left"
                dangerouslySetInnerHTML={{ __html: enemyWeaponsSpecial }}
              />
            </ExampleSection>
          ) : null}
          <h2>Injuries, Status Effects, Notes</h2>
          <QuillEditor
            id="enemy-notes"
            value={enemyNotes}
            change={handleSetEnemyNotes}
          />
          <Button
            variant="secondary"
            onClick={() => remove(enemyId)}
            style={{ marginTop: "30px" }}
          >
            Remove Enemy
          </Button>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

function EnemyList({ change }) {
  const enemiesContext = useContext(EnemyContext);
  const [enemiesList, setEnemiesList] = useState([]);

  useEffect(() => {
    // Sort enemy list by name alphabetically
    const extractedList = enemiesContext.enemyList;
    const sortedList = extractedList.sort((a, b) => {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    setEnemiesList(sortedList);
  }, [enemiesContext]);

  return (
    <Form.Select onChange={e => change(e.target.value)}>
      <option>Select an Enemy</option>
      {enemiesList.map(enemy => {
        return (
          <option key={enemy.name} value={enemy.name}>
            {enemy.name}
          </option>
        );
      })}
    </Form.Select>
  );
}

export default EncounterBuilder;
