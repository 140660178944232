import { useContext, useEffect, useRef, useState } from "react";
import Section from "../Section";
import {
  CharacterDataContext,
  CharacterInjuryContext,
} from "../../hooks/UserContext";
import { Button, Col, Row } from "react-bootstrap";
import { addDoc, collection, updateDoc, getDoc } from "firebase/firestore";
import { auth, charactersCol } from "../../firebaseSetup";
import InjuryReference from "../RulesReference/InjuryReference";
import Injury from "./Injury";
import ReferenceHeader from "../RulesReference/ReferenceHeader";
import QuillEditor from "../QuillEditor";
import { AutoSaveCharacter } from "../../backend/AutoSaveCharacter";

export function InjurySection({
  damageThreshold,
  traumaThreshold,
  exhaustionThreshold,
}) {
  const { currentCharacter, setCurrentCharacter } =
    useContext(CharacterDataContext);
  const autoSaveTimer = useRef(null);
  const characterInjuries = useContext(CharacterInjuryContext);
  const [injuryList, setInjuryList] = useState([]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (characterInjuries.characterInjuries !== null) {
  //       setInjuryList(characterInjuries.characterInjuries);
  //     }
  //   }, 200);
  // }, [characterInjuries]);

  const handleSetCharacterRichData = (e, id) => {
    const value = e;

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }

    setCurrentCharacter(prevValue => ({
      ...prevValue,
      [id]: value,
    }));

    autoSaveTimer.current = setTimeout(() => {
      AutoSaveCharacter(id, value);
    }, 1000);
  };

  return (
    <>
      <Section>
        <ReferenceHeader
          id="consequence-help-text"
          title="Conditions"
          defaultTab={<InjuryReference />}
          defaultEventKey="injuries"
          tabs={[
            {
              id: "injuries-tab",
              title: "Injuries",
              eventKey: "injuries",
              content: <InjuryReference />,
            },
          ]}
        />
        <QuillEditor
          id="conditions"
          value={currentCharacter?.conditions}
          change={e => handleSetCharacterRichData(e, "conditions")}
        />
        {/* {injuryList.map((injury) => {
          return (
            <Injury
              key={injury.id}
              injuryId={injury.id}
              type={injury.type}
              damageValue={injury.injuryDamage}
              details={injury.details}
              treated={injury.treated}
              damageThreshold={damageThreshold}
              traumaThreshold={traumaThreshold}
              exhaustionThreshold={exhaustionThreshold}
            />
          );
        })} */}
      </Section>
      {/* <AddInjuryButton /> */}
    </>
  );
}

function AddInjuryButton() {
  const { characterInjuries, setCharacterInjuries } = useContext(
    CharacterInjuryContext
  );

  const handleAddInjury = injuryType => {
    const ownerId = auth.currentUser.uid;
    const charId = localStorage.getItem("currentCharacterId");
    const charInjuriesCol = collection(charactersCol, charId, "charInjuries");

    addDoc(charInjuriesCol, {
      injuryDamage: 0,
      details: "",
      treated: false,
      owner: ownerId,
      type: injuryType,
    }).then(doc => {
      updateDoc(doc, {
        id: doc.id,
      }).then(() => {
        getDoc(doc).then(docSnap => {
          if (characterInjuries?.characterInjuries !== null) {
            const docData = docSnap.data();

            // check if character injuries already exist and append new injury
            setCharacterInjuries(injuries => [...(injuries || []), docData]);
          }
        });
      });
    });
  };

  return (
    <Section>
      <h2 className="text-primary">Suffer Consequence</h2>
      <Row>
        <Col sm={4} style={{ marginBottom: "10px" }}>
          <Button
            className="btn-secondary"
            onClick={() => handleAddInjury("injury")}
          >
            Physical Injury
          </Button>
        </Col>
        <Col sm={4} style={{ marginBottom: "10px" }}>
          <Button
            className="btn-secondary"
            onClick={() => handleAddInjury("trauma")}
          >
            Mental Trauma
          </Button>
        </Col>
        <Col sm={4} style={{ marginBottom: "10px" }}>
          <Button
            className="btn-secondary"
            onClick={() => handleAddInjury("exhaustion")}
          >
            Exhaustion
          </Button>
        </Col>
      </Row>
    </Section>
  );
}
