import { Link } from "react-router-dom";
import Section from "../../../components/Section";

function Magic() {
  return (
    <>
      <Section>
        <h1 className="title-header">Magic on Nilihaad</h1>
        <p>
          Magic is gifted to the inhabitants of Nilihaad through its plants. All
          manner of flora holds the power of the elements, time, space, and even
          life and death. Through the arcane properties of Esael parchment, the
          magical properties of these plants can be activated and used to cast
          spells.
        </p>
        <p>
          Alchemy and the arcane arts are closely related, sharing similar
          ingredients and effects, with one important difference. Where potions
          are a single-use consumable, through the infusion of Esael, spells can
          be cast several times through a focus such as a wand. Often, simple
          magic is written upon Esael using ink made from a specific plant and
          inserted into a wand, giving that wand a singular purpose to cast
          spells related to that plant. These wands have several charges and can
          be used a number of times before losing their potency, needing their
          Esael replaced.
        </p>
        <p>
          Anyone with the proper education in a{" "}
          <Link to="/rules/magic-disciplines" className="link">
            school of magic
          </Link>{" "}
          can craft spells by spending magical ingredients, Esael, time, and
          money. The spellcrafting process can be long and difficult to those
          building brand new or customized spells. Because of this, magic is an
          expensive but worthwhile investment. With the proper knowledge and
          resources, one can craft powerful spells and valuable arcane
          artifacts.
        </p>
      </Section>
      <Section>
        <h1 className="title-header">Potions & Elixirs</h1>
        <p>
          The same fauna and plant-based ingredients used to write elaborate
          multi-charge spells can also be used to brew potions at a more
          affordable price. Though potion brewing is still an involved process
          of mixing the proper ratio of ingredients, simmering concoctions at
          just the right heat, and stirring with precise rotations, the work
          costs less and takes less time to produce results compared to
          spellcraft. Knowing when a situation calls for a full-fledged spell or
          a single potion is a craft in itself and as such, Higzaar proudly
          supports proprietors of both the arcane and the alchemical.
        </p>
      </Section>
    </>
  );
}

export default Magic;
