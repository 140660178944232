import { Route } from "react-router-dom";
import CharacterSheet from "../../pages/CharacterSheet";
import { Navigate } from "react-router-dom";
import CharacterTab from "../../components/CharacterSheet/CharacterTab";
import AbilitiesTab from "../../components/CharacterSheet/AbilitiesTab";
import InventoryTab from "../../components/CharacterSheet/InventoryTab";

export default (
  <Route path="character-sheet" element={<CharacterSheet />}>
    <Route path="character" element={<CharacterTab />} />
    <Route path="talents" element={<AbilitiesTab />} />
    <Route path="inventory" element={<InventoryTab />} />
    <Route index element={<Navigate to="character" />} />
  </Route>
);
