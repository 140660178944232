import { Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { Rules } from "../../../pages/Rules";
import CoreRulesRoutes from "./CoreRulesRoutes";
import CharacterRoutes from "./CharacterRoutes";
import CombatRoutes from "./CombatRoutes";
import WealthItemsRoutes from "./WealthItemsRoutes";
import MagicRoutes from "./MagicRoutes";

export default (
  <Route path="rules" element={<Rules />}>
    {CoreRulesRoutes}
    {CharacterRoutes}
    {CombatRoutes}
    {WealthItemsRoutes}
    {MagicRoutes}

    <Route index element={<Navigate to="core-rules/basics" />} />
  </Route>
);
