import { Outlet } from "react-router-dom";
import MainContainer from "../components/MainContainer";
import Titlebar from "../components/Titlebar";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import {
  RulesLink,
  RulesSectionCollapse,
} from "../components/Rules/RulesSectionCollapse";
import { UserPrefsContext } from "../hooks/UserContext";

export function Rules() {
  const userPrefs = useContext(UserPrefsContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const breakPoint = 992;

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
  }, [windowWidth]);

  return (
    <>
      <Titlebar>Emberbrand Rules</Titlebar>
      <MainContainer>
        {windowWidth < breakPoint ? (
          <RulesMobile theme={userPrefs.userPrefs?.theme} />
        ) : (
          <RulesDesktop theme={userPrefs.userPrefs?.theme} />
        )}
      </MainContainer>
    </>
  );
}

function RulesMobile({ theme }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Outlet />
      <div className="rules-nav">
        <div className={`rules-nav-content footer bg-info`}>
          <Navbar expand="lg" expanded={expanded} className="navbar-nav-scroll">
            <Container>
              <h2>Rules</h2>
              <Navbar.Toggle
                aria-controls="rules-navbar-nav"
                onClick={() => setExpanded(expanded ? false : "expanded")}
              />
              <Navbar.Collapse id="rules-navbar-nav">
                <Nav className="me-auto">
                  <RulesLinks setExpanded={setExpanded} />
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <div style={{ paddingBottom: "10px" }}></div>
        </div>
      </div>
    </>
  );
}

function RulesDesktop({ theme }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Row>
      <Col sm={2} md={2} lg={2} className="rules-nav">
        <div className={`rules-nav-content rules-nav-content-desktop bg-info`}>
          <h2>Rules</h2>
          <RulesLinks expanded={expanded} setExpanded={setExpanded} />
        </div>
      </Col>
      <Col sm={6} md={6} lg={6} className="rules-content">
        <Outlet />
      </Col>
    </Row>
  );
}

function RulesLinks({ setExpanded }) {
  return (
    <div>
      <hr />
      <RulesSectionCollapse
        link="core-rules/basics"
        title="Core Rules"
        id="basic-collapse-links"
        setExpanded={setExpanded}
      >
        <RulesLink
          link="core-rules/dice"
          title="Dice"
          setExpanded={setExpanded}
        />
        <RulesLink
          link="core-rules/skills"
          title="Skills"
          setExpanded={setExpanded}
        />
        <RulesLink
          link="core-rules/participants"
          title="Participants"
          setExpanded={setExpanded}
        />
        <RulesLink
          link="core-rules/actions-outcomes"
          title="Actions & Outcomes"
          setExpanded={setExpanded}
        />
        <RulesLink
          link="core-rules/damage"
          title="Damage & Consequences"
          setExpanded={setExpanded}
        />
        <RulesLink
          link="core-rules/taking-rest"
          title="Resting"
          setExpanded={setExpanded}
        />
        <RulesLink
          link="core-rules/supply-roll"
          title="Supply Rolls"
          setExpanded={setExpanded}
        />
      </RulesSectionCollapse>
      <hr />
      <RulesSectionCollapse
        link="your-character/character-creation"
        title="Your Character"
        id="character-collapse-links"
        setExpanded={setExpanded}
      >
        <RulesLink
          link="your-character/character-skills"
          title="Starting Skills"
          setExpanded={setExpanded}
        />
        <RulesLink
          link="your-character/fate"
          title="Fate"
          setExpanded={setExpanded}
        />
      </RulesSectionCollapse>
      <hr />
      <RulesSectionCollapse
        link="combat"
        title="Combat"
        id="combat-collapse-links"
        setExpanded={setExpanded}
      >
        <RulesLink
          link="combat/combat-attacking"
          title="Attacking"
          setExpanded={setExpanded}
        />
        <RulesLink
          link="combat/combat-range"
          title="Range"
          setExpanded={setExpanded}
        />
      </RulesSectionCollapse>
      <hr />
      <RulesSectionCollapse
        link="wealth-items/wealth-items-equipment"
        title="Wealth, Items, & Equipment"
        id="items-collapse-links"
        setExpanded={setExpanded}
      >
        <RulesLink
          link="wealth-items/wealth"
          title="Wealth"
          setExpanded={setExpanded}
        />
        <RulesLink
          link="wealth-items/items"
          title="Items"
          setExpanded={setExpanded}
        />
        <RulesLink
          link="wealth-items/weapons"
          title="Weapons"
          setExpanded={setExpanded}
        />
      </RulesSectionCollapse>
      <hr />
      <RulesSectionCollapse
        link="magic/magic-alchemy"
        title="Magic & Alchemy"
        id="magic-collapse-links"
        setExpanded={setExpanded}
      >
        <RulesSectionCollapse
          link="magic/magic-disciplines"
          title="The Disciplines of Magic"
          id="disciplines-collapse-links"
          setExpanded={setExpanded}
        >
          <RulesLink
            link="magic/magic-evocation"
            title="Evocation"
            setExpanded={setExpanded}
          />
          <RulesLink
            link="magic/magic-illusion"
            title="Illusion"
            setExpanded={setExpanded}
          />
          <RulesLink
            link="magic/magic-divination"
            title="Divination"
            setExpanded={setExpanded}
          />
          <RulesLink
            link="magic/magic-abjuration"
            title="Abjuration"
            setExpanded={setExpanded}
          />
          <RulesLink
            link="magic/magic-conjuration"
            title="Conjuration"
            setExpanded={setExpanded}
          />
          <RulesLink
            link="magic/magic-necromancy"
            title="Necromancy"
            setExpanded={setExpanded}
          />
          <RulesLink
            link="magic/magic-transmutation"
            title="Transmutation"
            setExpanded={setExpanded}
          />
        </RulesSectionCollapse>
        <RulesLink
          link="magic/spellcrafting"
          title="Spellcrafting"
          setExpanded={setExpanded}
        />
        <RulesLink
          link="magic/magic-enchanting"
          title="Enchanting"
          setExpanded={setExpanded}
        />
      </RulesSectionCollapse>
      <hr />
    </div>
  );
}
