import { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { LoadLwCharacter } from "../../../hooks/leviathansWake/LoadLwCharacter";
import { Accordion } from "react-bootstrap";
import {
  Attributes,
  Core,
  Curse,
  Harm,
  Move,
  Progress,
} from "../../RulesReference/LeviathansWakeReference/LwGlossary";
import { LwMoveData } from "../../RulesReference/LeviathansWakeReference/LwMoveData";

function LwCharacterSidebar({ collapsed, toggled, setCollapsed }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const breakPoint = 992;

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
  }, [windowWidth]);

  return (
    <>
      {windowWidth < breakPoint ? (
        <MobileSidebar
          collapsed={collapsed}
          toggled={toggled}
          setCollapsed={setCollapsed}
        />
      ) : (
        <DesktopSidebar collapsed={collapsed} />
      )}
    </>
  );
}

function SidebarMenu() {
  return (
    <Menu className="text-left">
      <SubMenu label="Moves">
        <Accordion>
          {Object.keys(LwMoveData).map((move, index) => (
            <Accordion.Item key={index} eventKey={index}>
              <Accordion.Header>{LwMoveData[move].name}</Accordion.Header>
              <Accordion.Body>
                {
                  <div>
                    <p>
                      <i>{LwMoveData[move].category}</i>
                    </p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: LwMoveData[move].description,
                      }}
                    ></div>
                  </div>
                }
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </SubMenu>
      <SubMenu label="Glossary">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Attributes</Accordion.Header>
            <Accordion.Body>
              <Attributes />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Core</Accordion.Header>
            <Accordion.Body>
              <Core />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Curse</Accordion.Header>
            <Accordion.Body>
              <Curse />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Harm</Accordion.Header>
            <Accordion.Body>
              <Harm />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Move</Accordion.Header>
            <Accordion.Body>
              <Move />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Progress</Accordion.Header>
            <Accordion.Body>
              <Progress />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </SubMenu>
      <MenuItem component={<LoadLwCharacter />} />
    </Menu>
  );
}

function MobileSidebar({ toggled, setCollapsed }) {
  return (
    <>
      <Sidebar
        rtl={false}
        breakPoint="all"
        width="90%"
        toggled={toggled}
        onBackdropClick={setCollapsed}
        style={{ top: "81px" }}
      >
        <SidebarMenu />
      </Sidebar>
    </>
  );
}

function DesktopSidebar({ collapsed }) {
  return (
    <>
      <Sidebar
        collapsed={collapsed}
        width="400px"
        collapsedWidth="0px"
        rtl={false}
        style={{ top: "-20px" }}
      >
        <SidebarMenu />
      </Sidebar>
    </>
  );
}

export default LwCharacterSidebar;
