import MagicIngredient from "../../../components/MagicIngredient";
import Section from "../../../components/Section";

const necromancyData = [
  {
    name: "Eternal Nightshade",
    cost: "Poor",
    effect:
      "Temporarily weakens the fortitude of living creatures, making them more susceptible to necromantic spells.",
    example:
      "Include in another Necromancy spell or enchantment to increase its potency.",
  },
  {
    name: "Specterlily",
    cost: "Comfortable",
    effect:
      "Allows the user to see and communicate with the spirits of the deceased.",
    example:
      "Commune with the dead. Combine with Divination magic to ask guidance from the souls of the lost.",
  },
  {
    name: "Soulgust Rose",
    cost: "Comfortable",
    effect:
      "Unleashes the essence of wrathful spirits, allowing the user to create weapons and cast harmful spells of necromantic energy.",
    example:
      "Create a sword of ghostly energy. Unleash a wave of spirits to attack. Combine with an Abjuration effect to create spectral armor.",
  },
  {
    name: "Gravebloom",
    cost: "Wealthy",
    effect:
      "Revitalizes a pile of bones or a corpse, granting reanimation to a humanoid creature.",
    example:
      "Create a skeleton from a pile of bones. Reanimate a corpse into a zombie.",
  },
  {
    name: "Cursed Heartwood",
    cost: "Modest",
    effect: "Drains the life force of another, dealing necromantic damage.",
  },
  {
    name: "Vampire's Tooth",
    cost: "Wealthy",
    effect:
      "Absorbs the lifeforce from blood outside the body to heal the user.",
    example:
      "Create a potion of healing using blood harvested from a creature. Combine with another harmful effect to create a vampiric spell to steal the life force from an enemy.",
  },
  {
    name: "Mendberry",
    cost: "Modest",
    effect:
      "Stimulates cellular regeneration, accelerating the healing of injuries and regrowing damaged tissue.",
  },
];

function Necromancy() {
  return (
    <Section>
      <h1 className="title-header">Necromancy</h1>
      <p>
        Necromancy spells encompass a diverse range of effects, allowing casters
        to heal injuries, restore vitality, and offer solace to the wounded and
        ailing. Necromancers can summon life-giving energies to mend wounds,
        cure ailments, and rejuvenate the weary. In addition, they can channel
        their mastery over the forces of life and death to commune with spirits,
        raise corpses, gain insight into the mysteries of the beyond, and
        provide closure and guidance to those in need.
      </p>
      <p>
        While Necromancy is often associated with the darker aspects of magic,
        its applications extend far beyond the macabre. Necromancers are adept
        healers, capable of mending injuries and restoring vitality with their
        unparalleled connection to life energy. By wielding both life-affirming
        and death-infused magic, they offer a unique and complex perspective on
        the intricate web of existence.
      </p>
      <p>
        The practice of Necromancy demands an intimate understanding of the ebb
        and flow of life force, as well as a deep empathy for the struggles and
        triumphs of living beings. Necromancers possess an innate ability to
        sense the rhythms of life, allowing them to harmonize with the natural
        world and channel its energies to both mend and empower.
      </p>
      <p>
        Necromancers must also tread carefully the line between life and death,
        as the practice of raising the dead and summoning spirits can cause deep
        corruption of the soul. Because of this, the dark magic side of
        Necromancy may lead to legal consequences. Spells of decay, vampirism,
        and soul manipulation are illegal in many places.
      </p>
      <hr />
      <MagicIngredient data={necromancyData} />
    </Section>
  );
}

export default Necromancy;
