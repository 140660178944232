import { Table } from "react-bootstrap";
import Section from "../../../components/Section";

function MagicEffects() {
  return (
    <>
      <Section>
        <h1 className="title-header">Magical Ingredients and Effects</h1>
        <p>
          The magical flora of Higzaar is used to create ink for the writing of
          intricate spells and for the concocting of powerful elixirs. The
          following tables provide the names, effects, and cost per unit of the
          arcane plants found throughout the continent. Whether you're writing
          spells or brewing potions, you'll utilize the same flora.
        </p>
        <h2>Evocation</h2>
        

        <h2>Illusion</h2>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Cost</th>
              <th>Effects</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>1</th>
              <td>Shadowmoss</td>
              <td>Modest (-1)</td>
              <td>
                Creates a shroud around the user, enhancing stealth and enabling
                the user to move unseen.
              </td>
            </tr>
            <tr>
              <th>2</th>
              <td>Darkroot Thorn</td>
              <td>Modest (-2)</td>
              <td>
                Creates an area of darkness and shadow, obscuring vision and
                providing cover.
              </td>
            </tr>
            <tr>
              <th>3</th>
              <td>Shimmerweed</td>
              <td>Wealthy (-3)</td>
              <td>
                Emits shimmering particles that provide temporary invisibility.
              </td>
            </tr>
            <tr>
              <th>4</th>
              <td>Dreamstone Moss</td>
              <td>Comfortable (-4)</td>
              <td>
                Causes a deep sleep and allows the manipulation of dreams.
              </td>
            </tr>
            <tr>
              <th>6</th>
              <td>Mistwhisper Vine</td>
              <td>Poor (-2)</td>
              <td>Releases a mist which confuses those who breathe it in.</td>
            </tr>
            <tr>
              <th>7</th>
              <td>Mirage Lily</td>
              <td>Modest (-1)</td>
              <td>
                Creates illusory duplicates of the user, which can be used to
                confuse or distract others.
              </td>
            </tr>
            <tr>
              <th>8</th>
              <td>Spectral Willow</td>
              <td>Comfortable (-3)</td>
              <td>
                Creates illusory phantoms with grotesque features, able to
                shriek and terrorize others.
              </td>
            </tr>
            <tr>
              <th>9</th>
              <td>Mirashroom</td>
              <td>Comfortable (-4)</td>
              <td>
                Reflects the surrounding area to camouflage the user and provide
                increased stealth.
              </td>
            </tr>
            <tr>
              <th>10</th>
              <td>Enigmalily</td>
              <td>Comfortable (-1)</td>
              <td>
                Obscures the user's true form, making them appear as someone or
                something else.
              </td>
            </tr>
            <tr>
              <th>11</th>
              <td>Whimsyweed</td>
              <td>Modest (-2)</td>
              <td>
                Grants the ability to alter the appearance of an object to
                something playful or mischievous.
              </td>
            </tr>
            <tr>
              <th>12</th>
              <td>Phantomroot</td>
              <td>Wealthy (-4)</td>
              <td>
                Creates an ethereal mist which distorts reality, making it
                difficult to discern truth from illusion and masking magical
                effects.
              </td>
            </tr>
            <tr>
              <th>13</th>
              <td>Mimicry Moss</td>
              <td>Modest (-2)</td>
              <td>
                Grants the ability to create illusory duplicates of an object.
              </td>
            </tr>
            <tr>
              <th>14</th>
              <td>Reflection Reed</td>
              <td>Modest (-1)</td>
              <td>
                Creates illusory mirrors, projecting reflections to confuse and
                mislead others.
              </td>
            </tr>
            <tr>
              <th>15</th>
              <td>Ethereal Blossom</td>
              <td>Wealthy (-5)</td>
              <td>
                Causes temporary disorientation and vivid hallucinations,
                allowing the user to create an entirely false reality for
                someone else.
              </td>
            </tr>
            <tr>
              <th>16</th>
              <td>Spectral Thistle</td>
              <td>Modest (-2)</td>
              <td>
                Causes the user to appear as a spirit, with a transluscent
                ethereal form.
              </td>
            </tr>
            <tr>
              <th>17</th>
              <td>Phantombell Lily</td>
              <td>Comfortable (-3)</td>
              <td>
                Emits a low chime which amplifies illusionary effects, making
                illusions appear more realistic and convincing.
              </td>
            </tr>
            <tr>
              <th>18</th>
              <td>Echoleaf</td>
              <td>Modest (-2)</td>
              <td>
                Emits echoes of sounds that reverberate through the area,
                creating illusions of footsteps, whispers, or other auditory
                distractions to mislead and confuse others.
              </td>
            </tr>
            <tr>
              <th>19</th>
              <td>Harmonia Blossom</td>
              <td>Comfortable (-3)</td>
              <td>
                Resonates with harmonic frequencies to create sounds of soothing
                melodies or captivating musing to mesmerize and enchant those
                who hear it.
              </td>
            </tr>
            <tr>
              <th>20</th>
              <td>Resonant Reed</td>
              <td>Modest (-1)</td>
              <td>Allows direct communication to others across a distance.</td>
            </tr>
          </tbody>
        </Table>

        <h2>Divination</h2>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Cost</th>
              <th>Effects</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>1</th>
              <td>Glimmerroot</td>
              <td>Poor (-1)</td>
              <td>
                Emits a faint, ethereal glow that enhances perception and
                enables detection of hidden objects or creatures.
              </td>
            </tr>
            <tr>
              <th>2</th>
              <td>Willowshade Bark</td>
              <td>Poor (-2)</td>
              <td>
                Grants the user the ability to communicate with plants and
                receive their guidance.
              </td>
            </tr>
            <tr>
              <th>3</th>
              <td>Soulwood Tree</td>
              <td>Comfortable (-3)</td>
              <td>
                Possesses a connection to the spiritual realm, enabling
                communication with spirits.
              </td>
            </tr>
            <tr>
              <th>4</th>
              <td>Dreamroot</td>
              <td>Poor (-2)</td>
              <td>
                Induces deep sleep and vivid dreams, offering insights into the
                subconscious.
              </td>
            </tr>
            <tr>
              <th>5</th>
              <td>Sageleaf</td>
              <td>Modest (-2)</td>
              <td>
                Enhances clarity of thought and grants very brief visions of the
                immediate future.
              </td>
            </tr>
            <tr>
              <th>6</th>
              <td>Seer's Rose</td>
              <td>Modest (-1)</td>
              <td>
                Grants temporary glimpses into the past, allowing the user to
                uncover forgotten knowledge.
              </td>
            </tr>
            <tr>
              <th>7</th>
              <td>Clairvoyant Iris</td>
              <td>Comfortable (-2)</td>
              <td>
                Enables the user to temporarily see events occurring at a
                distant location.
              </td>
            </tr>
            <tr>
              <th>8</th>
              <td>Truthvine</td>
              <td>Comfortable (-1)</td>
              <td>
                Provides temporary insight into the intentions of others and the
                truthfulness of their words, aiding in the detecting deception.
              </td>
            </tr>
            <tr>
              <th>9</th>
              <td>Eldrishroom</td>
              <td>Modest (-2)</td>
              <td>
                Enables the user to ask a single question and receive a cryptic
                but revealing answer.
              </td>
            </tr>
            <tr>
              <th>10</th>
              <td>Guiding Glowvine</td>
              <td>Modest (-1)</td>
              <td>
                Emits a soft, ethereal light that acts as a beacon, guiding the
                user toward their intended destination or leading them away from
                danger.
              </td>
            </tr>
            <tr>
              <th>11</th>
              <td>Prophetic Bells</td>
              <td>Comfortable (-3)</td>
              <td>
                Reveals audible visions of future events, allowing the user to
                hear snippets of conversations, warnings, or foretelling omens.
              </td>
            </tr>
            <tr>
              <th>12</th>
              <td>Mind's Eye Lotus</td>
              <td>Wealthy (-2)</td>
              <td>
                Enhances mental acuity and psychic perception, granting limited
                telepathic abilities.
              </td>
            </tr>
            <tr>
              <th>13</th>
              <td>Revealroot</td>
              <td>Modest (-2)</td>
              <td>Grants a temporary ability to see hidden magical auras.</td>
            </tr>
            <tr>
              <th>14</th>
              <td>Intuition Fern</td>
              <td>Modest (-1)</td>
              <td>
                Enhances intuition and grants a heightened sense of awareness,
                allowing the user to make better choices and sense imminent
                danger.
              </td>
            </tr>
            <tr>
              <th>15</th>
              <td>Wisdomberry</td>
              <td>Wealthy (-4)</td>
              <td>
                Temporarily enhances mental clarity, granting the user increased
                knowledge, insights, and problem-solving abilities.
              </td>
            </tr>
            <tr>
              <th>16</th>
              <td>Diviner's Orchid</td>
              <td>Comfortable (-2)</td>
              <td>
                Clears the mind of external distractions, allowing the user to
                focus more easily and amplify divining abilities.
              </td>
            </tr>
            <tr>
              <th>17</th>
              <td>Farsight Blossom</td>
              <td>Modest (-1)</td>
              <td>
                Grants temporary farsight, enhancing the user's ability to see
                distant objects or read small text from afar.
              </td>
            </tr>
            <tr>
              <th>18</th>
              <td>Celestileaf</td>
              <td>Comfortable (-4)</td>
              <td>
                Grants the ability to interpret celestial signs and gain
                insights from the movements of the stars and planets.
              </td>
            </tr>
            <tr>
              <th>19</th>
              <td>Visage Lily</td>
              <td>Comfortable (-3)</td>
              <td>
                Enhances the user's empathic abilities, allowing them to
                perceive and interpret the emotions and intentions of others.
              </td>
            </tr>
            <tr>
              <th>20</th>
              <td>Twilight Petal</td>
              <td>Aristocratic (-5)</td>
              <td>
                Grants glimpses of alternate timelines or potential futures,
                allowing the user to explore different possibilities.
              </td>
            </tr>
          </tbody>
        </Table>

        <h2>Abjuration</h2>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Cost</th>
              <th>Effects</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>1</th>
              <td>Sunshroud Fern</td>
              <td>Poor (-1)</td>
              <td>
                Generates a radiant aura that offers protection against harmful
                light-based effects and burns inflicted by sunlight.
              </td>
            </tr>
            <tr>
              <th>2</th>
              <td>Silverleaf</td>
              <td>Modest (-1)</td>
              <td>
                Provides resistance to the bitter cold, granting protection
                against chilling frost effects.
              </td>
            </tr>
            <tr>
              <th>3</th>
              <td>Flameheart Rose</td>
              <td>Poor</td>
              <td>
                Burns with inner fire, granting resistance to heat and
                fire-based attacks.
              </td>
            </tr>
            <tr>
              <th>4</th>
              <td>Illusionist's Bane</td>
              <td>Wealthy (-4)</td>
              <td>
                Grants protection from illusory magic and dispells any created
                illusions.
              </td>
            </tr>
            <tr>
              <th>5</th>
              <td>Mirrorchid</td>
              <td>Comfortable (-3)</td>
              <td>
                Provides defense against offensive spells, creating a reflective
                barrier that bounces harmful effects back toward the caster with
                reduced potency.
              </td>
            </tr>
            <tr>
              <th>6</th>
              <td>Stormshield Willow</td>
              <td>Modest (-2)</td>
              <td>
                Grants resistance to electrical-based effects, reducing damage
                taken from lightning and providing insulation against electrical
                shocks.
              </td>
            </tr>
            <tr>
              <th>7</th>
              <td>Spectral Wardbloom</td>
              <td>Comfortable (-4)</td>
              <td>
                Grants resistance to ethereal effects, providing defense against
                spirits and spectral illusions.
              </td>
            </tr>
            <tr>
              <th>8</th>
              <td>Shadowveil Thistle</td>
              <td>Modest (-1)</td>
              <td>
                Provides protection against shadow magic and illusions,
                rendering the user less susceptible to manipulation and
                shadow-based effects.
              </td>
            </tr>
            <tr>
              <th>9</th>
              <td>Enchanted Ironbark</td>
              <td>Modest (-2)</td>
              <td>Provides increased defense against physical damage.</td>
            </tr>
            <tr>
              <th>10</th>
              <td>Netherbane Bramble</td>
              <td>Modest (-2)</td>
              <td>
                Weakens necromantic effects, reducing the effectiveness of
                curses, life-draining spells, and undead creatures.
              </td>
            </tr>
            <tr>
              <th>11</th>
              <td>Mindshield Blossom</td>
              <td>Modest (-1)</td>
              <td>
                Enhances mental fortitude and grants resistance against
                psychic-based effects, reducing the effectiveness of telepathic
                and illusory manipulation.
              </td>
            </tr>
            <tr>
              <th>12</th>
              <td>Serpentguard Fern</td>
              <td>Modest (-1)</td>
              <td>
                Applies resistance to poisons and venoms, reducing damage taken
                from toxins.
              </td>
            </tr>
            <tr>
              <th>13</th>
              <td>Celestialbane Thistle</td>
              <td>Comfortable (-4)</td>
              <td>
                Counters other abjuration or restoration effects, reducing the
                effectiveness of healing and protection.
              </td>
            </tr>
            <tr>
              <th>14</th>
              <td>Cyclone Shieldleaf</td>
              <td>Modest (-2)</td>
              <td>
                Grants protection against wind-based effects, reducing the
                impact of gusts and whirlwinds.
              </td>
            </tr>
            <tr>
              <th>15</th>
              <td>Earthguard Root</td>
              <td>Modest (-1)</td>
              <td>
                Fortifies the user against earth-based effects, reducing damage
                taken from earthquakes, landslides, and other geomantic attacks.
              </td>
            </tr>
            <tr>
              <th>16</th>
              <td>Disrupetal</td>
              <td>Modest (-2)</td>
              <td>
                Reduces the power of spellcasting within the vicinity, lowering
                the effectiveness of any spells or magical effects.
              </td>
            </tr>
            <tr>
              <th>17</th>
              <td>Resonance Truffle</td>
              <td>Wealthy (-5)</td>
              <td>
                Greatly enhances protective magic, amplifying the power of
                abjuration spells and effects.
              </td>
            </tr>
            <tr>
              <th>18</th>
              <td>Wardstone Blossom</td>
              <td>Comfortable (-4)</td>
              <td>
                Creates a temporary ward in a designated area that prevents the
                entry of hostile characters and creatures.
              </td>
            </tr>
            <tr>
              <th>19</th>
              <td>Mystic Shieldfern</td>
              <td>Modest (-1)</td>
              <td>
                Boosts the defensive properties of equipment such as armor or
                shields.
              </td>
            </tr>
            <tr>
              <th>20</th>
              <td>Voidshroud</td>
              <td>Comfortable (-3)</td>
              <td>
                Creates a barrier against conjured entities and dispells
                transmutation effects.
              </td>
            </tr>
          </tbody>
        </Table>

        <h2>Restoration</h2>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Cost</th>
              <th>Effects</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>1</th>
              <td>Mistralberry</td>
              <td>Poor</td>
              <td>
                Releases a burst of refreshing mist, providing temporary relief
                from fatigue and exhaustion.
              </td>
            </tr>
            <tr>
              <th>2</th>
              <td>Radiantbark</td>
              <td>Poor (-2)</td>
              <td>
                Emits a radiant energy that enhances healing spells and provides
                a restorative aura in its vicinity.
              </td>
            </tr>
            <tr>
              <th>3</th>
              <td>Mosswhisper Fern</td>
              <td>Poor (-1)</td>
              <td>
                Creates a calming aura that soothes and heals wounds, promoting
                natural regeneration to any who rest in the vicinity.
              </td>
            </tr>
            <tr>
              <th>4</th>
              <td>Sunshower Orchid</td>
              <td>Modest (-2)</td>
              <td>
                Generates a brief rainstorm with warm sunlight, rejuvenating and
                healing those within its range.
              </td>
            </tr>
            <tr>
              <th>5</th>
              <td>Mendberry</td>
              <td>Comfortable (-3)</td>
              <td>
                Stimulates cellular regeneration, accelerating the healing of
                injuries and regrowing damaged tissue.
              </td>
            </tr>
            <tr>
              <th>6</th>
              <td>Vigorleaf Vine</td>
              <td>Poor (-2)</td>
              <td>
                Enhances the user's vitality and stamina, granting increased
                endurance and resistance to exhaustion.
              </td>
            </tr>
            <tr>
              <th>7</th>
              <td>Resser's Rose</td>
              <td>Modest (-1)</td>
              <td>
                Releases a fragrant aroma that calms and soothes, alleviating
                anxiety and stress, and boosting morale.
              </td>
            </tr>
            <tr>
              <th>8</th>
              <td>Elixir Lily</td>
              <td>Comfortable (-2)</td>
              <td>
                Restores magical properties, replenishing the charge of other
                magical items.
              </td>
            </tr>
            <tr>
              <th>9</th>
              <td>Serenity Petal</td>
              <td>Modest (-1)</td>
              <td>
                Enhances calmness and tranquility, allowing for greater
                concentration, focus, and willpower.
              </td>
            </tr>
            <tr>
              <th>10</th>
              <td>Renewal Grass</td>
              <td>Modest (-1)</td>
              <td>
                Aids in coagulation and congealing of bleeding wounds, reducing
                negative effects of bloody injuries and treats them.
              </td>
            </tr>
            <tr>
              <th>11</th>
              <td>Splinterbark</td>
              <td>Modest (-3)</td>
              <td>Hardens bone and aids regrowth, treating bone injuries.</td>
            </tr>
            <tr>
              <th>12</th>
              <td>Clarityroot</td>
              <td>Modest (-1)</td>
              <td>
                Envigorates the mind and aids in the treatment of mental and
                head injuries.
              </td>
            </tr>
            <tr>
              <th>13</th>
              <td>Restmoss</td>
              <td>Poor (-2)</td>
              <td>
                Applies the effects of a rest while allowing the user to
                continue activity.
              </td>
            </tr>
            <tr>
              <th>14</th>
              <td>Venom Nectar</td>
              <td>Modest (-2)</td>
              <td>
                Helps treat and cure injuries caused by poisons and toxins.
              </td>
            </tr>
            <tr>
              <th>15</th>
              <td>Auraheal Herb</td>
              <td>Poor (-1)</td>
              <td>
                Creates an aura which eases pain and discomfort to those in the
                vicinity.
              </td>
            </tr>
            <tr>
              <th>16</th>
              <td>Purifybloom Flower</td>
              <td>Modest (-1)</td>
              <td>Reduces negative effects caused by magic.</td>
            </tr>
            <tr>
              <th>17</th>
              <td>Mendleaf</td>
              <td>Modest (-2)</td>
              <td>
                When applied to an injury, allows enhanced healing to that
                injury.
              </td>
            </tr>
            <tr>
              <th>18</th>
              <td>Lucidshroom</td>
              <td>Poor (-2)</td>
              <td>
                Provides a spark of energy which helps awaken those who have
                been afflicted with unconsciousness or sluggishness.
              </td>
            </tr>
            <tr>
              <th>19</th>
              <td>Timeless Flower</td>
              <td>Aristocratic (-5)</td>
              <td>
                Grants a temporary suspension of aging, slowing the effects of
                time on the user's body and mind, delaying the onset of old age.
              </td>
            </tr>
            <tr>
              <th>20</th>
              <td>Everbloom Orchid</td>
              <td>Wealthy (-9)</td>
              <td>
                Grants temporary immortality, preventing the next instance of
                death to the user.
              </td>
            </tr>
          </tbody>
        </Table>

        <h2>Conjuration</h2>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Cost</th>
              <th>Effects</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>#</th>
              <td>Name</td>
              <td>Cost</td>
              <td>Effect</td>
            </tr>
          </tbody>
        </Table>

        <h2>Necromancy</h2>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Cost</th>
              <th>Effects</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>1</th>
              <td>Bloodthistle</td>
              <td>Comfortable</td>
              <td>
                Absorbs life force from the surrounding environment and nearby
                creatures, providing restorative properties and revitalization
                to the user.
              </td>
            </tr>
          </tbody>
        </Table>

        <h2>Transmutation</h2>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Cost</th>
              <th>Effects</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>1</th>
              <td>Serpentvine</td>
              <td>Poor (-1)</td>
              <td>
                Enhances agility and reflexes, granting increased dexterity and
                quickness.
              </td>
            </tr>
            <tr>
              <th>2</th>
              <td>Heartbloom</td>
              <td>Comfortable</td>
              <td>
                Amplifies the emotions and influences the feelings of others,
                affecting their moods and attitudes.
              </td>
            </tr>
            <tr>
              <th>3</th>
              <td>Soulfire Lotus</td>
              <td>Modest (-1)</td>
              <td>
                Burns with ethereal flames, enhancing magical potency and
                enabling the casting of empowered spells.
              </td>
            </tr>
            <tr>
              <th>4</th>
              <td>Bloodmoon Fern</td>
              <td>Comfortable (-1)</td>
              <td>
                Flourishes under the light of a blood moon, granting heightened
                strength and ferocity to the user.
              </td>
            </tr>
            <tr>
              <th>5</th>
              <td>Starfall Fern</td>
              <td>Modest (-2)</td>
              <td>
                Produces shimmering starlight, granting enhanced accuracy and
                improved aim.
              </td>
            </tr>
            <tr>
              <th>6</th>
              <td>Interweave Ivy</td>
              <td>Comfortable (-3)</td>
              <td>
                Binds the magical effects of one or more items, allowing them to
                interact with one another across great distances.
              </td>
            </tr>
          </tbody>
        </Table>
      </Section>
    </>
  );
}

export default MagicEffects;
