import { deleteField, doc, getDoc, updateDoc } from "firebase/firestore";
import { charactersCol } from "../firebaseSetup";
import { Button } from "react-bootstrap";
import { useState } from "react";

function DeleteOldContainers() {
  const [charId, setCharId] = useState("");

  const handleSetCharId = (value) => {
    setCharId(value);
  };

  const handleDeleteOldContainers = () => {
    const charDoc = doc(charactersCol, charId);

    updateDoc(charDoc, {
      container1Armor1Toggle: deleteField(),
      container1Item1: deleteField(),
      container1Item1Slots: deleteField(),
      container1Other1Toggle: deleteField(),
      container1Weapon1Damage: deleteField(),
      container1Weapon1Effect: deleteField(),
      container1Weapon1Toggle: deleteField(),
      container1Armor1Effect: deleteField(),
      container1Armor1Value: deleteField(),
      container1Other1Effect: deleteField(),
      container1Weapon1Requirements: deleteField(),
      container1Weapon1Type: deleteField(),

      container1Armor2Toggle: deleteField(),
      container1Item2: deleteField(),
      container1Item2Slots: deleteField(),
      container1Other2Toggle: deleteField(),
      container1Weapon2Damage: deleteField(),
      container1Weapon2Effect: deleteField(),
      container1Weapon2Toggle: deleteField(),
      container1Armor2Effect: deleteField(),
      container1Armor2Value: deleteField(),
      container1Other2Effect: deleteField(),
      container1Weapon2Requirements: deleteField(),
      container1Weapon2Type: deleteField(),

      container1Armor3Toggle: deleteField(),
      container1Item3: deleteField(),
      container1Item3Slots: deleteField(),
      container1Other3Toggle: deleteField(),
      container1Weapon3Damage: deleteField(),
      container1Weapon3Effect: deleteField(),
      container1Weapon3Toggle: deleteField(),
      container1Armor3Effect: deleteField(),
      container1Armor3Value: deleteField(),
      container1Other3Effect: deleteField(),
      container1Weapon3Requirements: deleteField(),
      container1Weapon3Type: deleteField(),

      container2Armor1Toggle: deleteField(),
      container2Item1: deleteField(),
      container2Item1Slots: deleteField(),
      container2Other1Toggle: deleteField(),
      container2Weapon1Damage: deleteField(),
      container2Weapon1Effect: deleteField(),
      container2Weapon1Toggle: deleteField(),
      container2Armor1Effect: deleteField(),
      container2Armor1Value: deleteField(),
      container2Other1Effect: deleteField(),
      container2Weapon1Requirements: deleteField(),
      container2Weapon1Type: deleteField(),

      container2Armor2Toggle: deleteField(),
      container2Item2: deleteField(),
      container2Item2Slots: deleteField(),
      container2Other2Toggle: deleteField(),
      container2Weapon2Damage: deleteField(),
      container2Weapon2Effect: deleteField(),
      container2Weapon2Toggle: deleteField(),
      container2Armor2Effect: deleteField(),
      container2Armor2Value: deleteField(),
      container2Other2Effect: deleteField(),
      container2Weapon2Requirements: deleteField(),
      container2Weapon2Type: deleteField(),

      container2Armor3Toggle: deleteField(),
      container2Item3: deleteField(),
      container2Item3Slots: deleteField(),
      container2Other3Toggle: deleteField(),
      container2Weapon3Damage: deleteField(),
      container2Weapon3Effect: deleteField(),
      container2Weapon3Toggle: deleteField(),
      container2Armor3Effect: deleteField(),
      container2Armor3Value: deleteField(),
      container2Other3Effect: deleteField(),
      container2Weapon3Requirements: deleteField(),
      container2Weapon3Type: deleteField(),

      container3Armor1Toggle: deleteField(),
      container3Item1: deleteField(),
      container3Item1Slots: deleteField(),
      container3Other1Toggle: deleteField(),
      container3Weapon1Damage: deleteField(),
      container3Weapon1Effect: deleteField(),
      container3Weapon1Toggle: deleteField(),
      container3Armor1Effect: deleteField(),
      container3Armor1Value: deleteField(),
      container3Other1Effect: deleteField(),
      container3Weapon1Requirements: deleteField(),
      container3Weapon1Type: deleteField(),

      container3Armor2Toggle: deleteField(),
      container3Item2: deleteField(),
      container3Item2Slots: deleteField(),
      container3Other2Toggle: deleteField(),
      container3Weapon2Damage: deleteField(),
      container3Weapon2Effect: deleteField(),
      container3Weapon2Toggle: deleteField(),
      container3Armor2Effect: deleteField(),
      container3Armor2Value: deleteField(),
      container3Other2Effect: deleteField(),
      container3Weapon2Requirements: deleteField(),
      container3Weapon2Type: deleteField(),

      container3Armor3Toggle: deleteField(),
      container3Item3: deleteField(),
      container3Item3Slots: deleteField(),
      container3Other3Toggle: deleteField(),
      container3Weapon3Damage: deleteField(),
      container3Weapon3Effect: deleteField(),
      container3Weapon3Toggle: deleteField(),
      container3Armor3Effect: deleteField(),
      container3Armor3Value: deleteField(),
      container3Other3Effect: deleteField(),
      container3Weapon3Requirements: deleteField(),
      container3Weapon3Type: deleteField(),

      container4Armor1Toggle: deleteField(),
      container4Item1: deleteField(),
      container4Item1Slots: deleteField(),
      container4Other1Toggle: deleteField(),
      container4Weapon1Damage: deleteField(),
      container4Weapon1Effect: deleteField(),
      container4Weapon1Toggle: deleteField(),
      container4Armor1Effect: deleteField(),
      container4Armor1Value: deleteField(),
      container4Other1Effect: deleteField(),
      container4Weapon1Requirements: deleteField(),
      container4Weapon1Type: deleteField(),

      container4Armor2Toggle: deleteField(),
      container4Item2: deleteField(),
      container4Item2Slots: deleteField(),
      container4Other2Toggle: deleteField(),
      container4Weapon2Damage: deleteField(),
      container4Weapon2Effect: deleteField(),
      container4Weapon2Toggle: deleteField(),
      container4Armor2Effect: deleteField(),
      container4Armor2Value: deleteField(),
      container4Other2Effect: deleteField(),
      container4Weapon2Requirements: deleteField(),
      container4Weapon2Type: deleteField(),

      container4Armor3Toggle: deleteField(),
      container4Item3: deleteField(),
      container4Item3Slots: deleteField(),
      container4Other3Toggle: deleteField(),
      container4Weapon3Damage: deleteField(),
      container4Weapon3Effect: deleteField(),
      container4Weapon3Toggle: deleteField(),
      container4Armor3Effect: deleteField(),
      container4Armor3Value: deleteField(),
      container4Other3Effect: deleteField(),
      container4Weapon3Requirements: deleteField(),
      container4Weapon3Type: deleteField(),

      container5Armor1Toggle: deleteField(),
      container5Item1: deleteField(),
      container5Item1Slots: deleteField(),
      container5Other1Toggle: deleteField(),
      container5Weapon1Damage: deleteField(),
      container5Weapon1Effect: deleteField(),
      container5Weapon1Toggle: deleteField(),
      container5Armor1Effect: deleteField(),
      container5Armor1Value: deleteField(),
      container5Other1Effect: deleteField(),
      container5Weapon1Requirements: deleteField(),
      container5Weapon1Type: deleteField(),

      container5Armor2Toggle: deleteField(),
      container5Item2: deleteField(),
      container5Item2Slots: deleteField(),
      container5Other2Toggle: deleteField(),
      container5Weapon2Damage: deleteField(),
      container5Weapon2Effect: deleteField(),
      container5Weapon2Toggle: deleteField(),
      container5Armor2Effect: deleteField(),
      container5Armor2Value: deleteField(),
      container5Other2Effect: deleteField(),
      container5Weapon2Requirements: deleteField(),
      container5Weapon2Type: deleteField(),

      container5Armor3Toggle: deleteField(),
      container5Item3: deleteField(),
      container5Item3Slots: deleteField(),
      container5Other3Toggle: deleteField(),
      container5Weapon3Damage: deleteField(),
      container5Weapon3Effect: deleteField(),
      container5Weapon3Toggle: deleteField(),
      container5Armor3Effect: deleteField(),
      container5Armor3Value: deleteField(),
      container5Other3Effect: deleteField(),
      container5Weapon3Requirements: deleteField(),
      container5Weapon3Type: deleteField(),

      container6Armor1Toggle: deleteField(),
      container6Item1: deleteField(),
      container6Item1Slots: deleteField(),
      container6Other1Toggle: deleteField(),
      container6Weapon1Damage: deleteField(),
      container6Weapon1Effect: deleteField(),
      container6Weapon1Toggle: deleteField(),
      container6Armor1Effect: deleteField(),
      container6Armor1Value: deleteField(),
      container6Other1Effect: deleteField(),
      container6Weapon1Requirements: deleteField(),
      container6Weapon1Type: deleteField(),

      container6Armor2Toggle: deleteField(),
      container6Item2: deleteField(),
      container6Item2Slots: deleteField(),
      container6Other2Toggle: deleteField(),
      container6Weapon2Damage: deleteField(),
      container6Weapon2Effect: deleteField(),
      container6Weapon2Toggle: deleteField(),
      container6Armor2Effect: deleteField(),
      container6Armor2Value: deleteField(),
      container6Other2Effect: deleteField(),
      container6Weapon2Requirements: deleteField(),
      container6Weapon2Type: deleteField(),

      container6Armor3Toggle: deleteField(),
      container6Item3: deleteField(),
      container6Item3Slots: deleteField(),
      container6Other3Toggle: deleteField(),
      container6Weapon3Damage: deleteField(),
      container6Weapon3Effect: deleteField(),
      container6Weapon3Toggle: deleteField(),
      container6Armor3Effect: deleteField(),
      container6Armor3Value: deleteField(),
      container6Other3Effect: deleteField(),
      container6Weapon3Requirements: deleteField(),
      container6Weapon3Type: deleteField(),

      itemContainerFive: deleteField(),
      itemContainerFiveArmor: deleteField(),
      itemContainerFiveName: deleteField(),
      itemContainerFiveNotes: deleteField(),
      itemContainerFour: deleteField(),
      itemContainerFourArmor: deleteField(),
      itemContainerFourName: deleteField(),
      itemContainerFourNotes: deleteField(),
      itemContainerOne: deleteField(),
      itemContainerOneArmor: deleteField(),
      itemContainerOneName: deleteField(),
      itemContainerOneNotes: deleteField(),
      itemContainerSix: deleteField(),
      itemContainerSixArmor: deleteField(),
      itemContainerSixName: deleteField(),
      itemContainerSixNotes: deleteField(),
      itemContainerThree: deleteField(),
      itemContainerThreeArmor: deleteField(),
      itemContainerThreeName: deleteField(),
      itemContainerThreeNotes: deleteField(),
      itemContainerTwo: deleteField(),
      itemContainerTwoArmor: deleteField(),
      itemContainerTwoName: deleteField(),
      itemContainerTwoNotes: deleteField(),
    });

    getDoc(charDoc).then((doc) => {
      console.log(doc.data());
    });
  };

  return (
    <>
      <input
        id="character-id"
        value={charId}
        onChange={(e) => handleSetCharId(e.target.value)}
      />
      <br />
      <Button onClick={handleDeleteOldContainers}>Delete Old Containers</Button>
    </>
  );
}

export default DeleteOldContainers;
