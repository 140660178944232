import React from "react";
import { FaTrash } from "react-icons/fa";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";

function CriticalCondition({
  conditionId,
  name,
  effects,
  recovery,
  removeCondition,
  updateCondition,
}) {
  const handleUpdateConditionRecovery = (conditionId, dataType, value) => {
    updateCondition(conditionId, dataType, value);
  };

  return (
    <div className="pb-2 mb-4 border-bottom">
      <Row className="mb-2">
        <Col xs={8}>
          <h4 className="text-primary">Condition</h4>
          <input
            id={`name-${conditionId}`}
            className="form-control"
            value={name}
            onChange={e =>
              handleUpdateConditionRecovery(
                conditionId,
                "injury",
                e.target.value
              )
            }
          />
        </Col>
        <Col xs={4}>
          <h4 className="text-primary">Recovery</h4>
          <input
            id={`recovery-${conditionId}`}
            className="form-control"
            value={recovery}
            onChange={e =>
              handleUpdateConditionRecovery(
                conditionId,
                "recovery",
                e.target.value
              )
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs={10}>
          <h4 className="text-primary">Effects</h4>
          <p>{effects}</p>
        </Col>
        <Col xs={2} className="my-auto">
          <Button
            id={`delete-${conditionId}`}
            onClick={() => removeCondition(conditionId)}
          >
            <FaTrash />
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default CriticalCondition;
