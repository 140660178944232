import AttackAction from "./AttackAction";

function Actions() {
  return (
    <>
      <div>
        <h2>Overcome</h2>
        <p>
          When you take on a challenging task, describe how you wish to overcome
          it. The Storyteller will choose a skill which best fits the situation,
          then you'll roll that skill.
        </p>
        <p>
          On a <b>total success</b>, Your action results in a favorable outcome
          with no complications.
        </p>
        <p>
          On a <b>partial success</b>, You complete your action, but with a
          cost. Choose one:
        </p>
        <ul>
          <li>
            You are delayed or face a new danger: The Storyteller will describe
            the situation.
          </li>
          <li>You are hurt or distressed: Suffer 1 damage.</li>
          <li>You are dispirited or afraid: Suffer 1 trauma.</li>
          <li>You are tired or exerted: Suffer 1 exhaustion.</li>
        </ul>
        <p>
          On a <b>failure</b>, Your action doesn't go as planned, and at great
          cost as described by the Storyteller.
        </p>
      </div>
      <hr />
      <AttackAction />
      <hr />
      <div>
        <h2>Avoid Danger</h2>
        <p>
          When you are faced with harm, such as an enemy attack or an
          environmental threat, you can try to avoid danger by describing how
          you mitigate the incoming threat. If the Storyteller determines your
          action makes sense in the situation, roll the skill they deem
          appropriate. If the Storyteller determines your action doesn't fit the
          scenario, you automatically fail to avoid danger and take the hit.
        </p>
        <p>
          On a <b>total success</b>, You avoid danger and come out unscathed.
        </p>
        <p>
          On a <b>partial success</b>, You manage to avoid most of the danger
          but you stumble, flinch, or otherwise hesitate and the Storyteller
          offers you a problematic outcome.
        </p>
        <p>
          On a <b>failure</b>, You're unable to avoid danger and take it in full
          force.
        </p>
      </div>
      <hr />
      <div>
        <h2>Assist</h2>
        <p>
          When you help an ally <b>Overcome</b>, roll the same skill.
        </p>
        <p>
          On a <b>total success</b>, give your ally 1 bonus die for their skill
          check.
        </p>
        <p>
          On a <b>partial success</b>, give your ally 1 bonus die for their
          skill check, but you fumble. Choose one:
        </p>
        <ul>
          <li>
            You are faced with a danger or a cost: The Storyteller will describe
            the situation.
          </li>
          <li>You are hurt or distressed: Suffer 1 damage.</li>
          <li>You are dispirited or afraid: Suffer 1 trauma.</li>
          <li>You are tired or exerted: Suffer 1 exhaustion.</li>
        </ul>
      </div>
      <hr />
      <div>
        <h2>Defend</h2>
        <p>
          When you move to the defense of a person, item, or location threatened
          by an attack, describe how you grant your protection. Roll the skill
          determined by the Storyteller.
        </p>
        <p>
          On a <b>Total success</b>, choose up to 3 defenses.
        </p>
        <ul>
          <li>Redirect the attack to yourself.</li>
          <li>Halve the amount of harm or effects of the attack.</li>
          <li>
            Open the attacker to one of your allies, giving that ally a bonus
            die against the attacker.
          </li>
          <li>Deal half your weapon's damage to the attacker.</li>
        </ul>
        <p>
          On a <b>partial success</b>, choose 1 defense.
        </p>
        <p>
          On a <b>failure</b>, you just get in the way and cause a complication
          for you and your ally.
        </p>
      </div>
      <hr />
    </>
  );
}

export default Actions;
