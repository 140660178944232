import { useState } from "react";

function SkillPercentage({ id, attribute, proficiency }) {
  const [halfPercent, setHalfPercent] = useState(true);

  const handleHalfPercent = (e) => {
    e.preventDefault();
    halfPercent ? setHalfPercent(false) : setHalfPercent(true);
  };

  if (attribute > 0 || proficiency > 0) {
    if (!attribute) {
      attribute = 0;
    }

    if (!proficiency) {
      proficiency = 0;
    }
    
    return (
      <a
        id={id}
        href="#"
        className={halfPercent === true ? "text-partial-success" : "text-total-success"}
        onClick={handleHalfPercent}
      >
        {halfPercent === true
          ? parseInt(attribute) + parseInt(proficiency)
          : Math.ceil((parseInt(attribute) + parseInt(proficiency)) / 2)}
        %
      </a>
    );
  }
}

export default SkillPercentage;
