import { Tab, Tabs } from "react-bootstrap";
import AbilitiesSection from "./AbilitiesSection";
import Section from "../Section";
import MagicSection from "./MagicSection";

function AbilitiesTab() {
  return (
    <Section>
      <Tabs defaultActiveKey={1}>
        <Tab eventKey={1} title="Talents">
          <AbilitiesSection />
        </Tab>
        <Tab eventKey={2} title="Magic">
          <MagicSection />
        </Tab>
      </Tabs>
    </Section>
  );
}

export default AbilitiesTab;
