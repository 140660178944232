import { useState, useContext } from "react";
import { LwCharacterDataContext } from "../../../hooks/leviathansWake/LwContext";
import { Button, Modal, Row } from "react-bootstrap";
import { FaPlus, FaPlusSquare } from "react-icons/fa";
import { getDocs } from "firebase/firestore";
import { lwTalentsCol } from "../../../firebaseSetup";
import TalentCard from "./TalentCard";

function LwTalentsSection() {
  const { lwCurrentCharacter, setLwCurrentCharacter } = useContext(
    LwCharacterDataContext
  );
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [prevTalent, setPrevTalent] = useState("");
  const talentList = JSON.parse(sessionStorage.getItem("lwTalentList"));
  const [filteredTalents, setFilteredTalents] = useState(talentList);

  // Check if talent list is in session storage and add it if it's not
  if (sessionStorage.getItem("lwTalentList") === null) {
    let talents = [];

    console.log("No talent list found in storage. Adding list from Firestore.");
    getDocs(lwTalentsCol)
      .then(snapshot => {
        snapshot.docs.forEach(doc => {
          const docData = doc.data();
          const talentData = { ...docData, id: doc.id };

          talents.push(talentData);
        });
      })
      .finally(() => {
        setFilteredTalents(talents);
        sessionStorage.setItem("lwTalentList", JSON.stringify(talents));
      });
  } else {
    console.log("Talent list found in storage.");
  }

  /**
   * Closes the add talent modal.
   */
  const handleCloseAddModal = () => setShowAddModal(false);

  /**
   * Opens the add talent modal.
   */
  const handleShowAddModal = () => setShowAddModal(true);

  /**
   * Closes the edit talent modal and resets the state variables.
   */
  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setPrevTalent("");
  };

  /**
   * Handles the addition of a new talent.
   *
   * @param {any} newTalent - The new talent to be added.
   * @returns {void}
   */
  const handleAddTalent = newTalent => {
    console.log("Adding talent");

    // if there are no talents, create a new array
    if (!lwCurrentCharacter.talents) {
      setLwCurrentCharacter(prevValue => ({
        ...prevValue,
        talents: [newTalent],
      }));
    } else {
      // Update react array state
      setLwCurrentCharacter(prevValue => ({
        ...prevValue,
        talents: [...prevValue.talents, newTalent],
      }));
    }

    handleCloseAddModal();
  };

  // Filter talent list based on search input
  const handleSearchTalent = () => {
    const search = document.getElementById("search-talent").value;
    // const talents = JSON.parse(sessionStorage.getItem("lwTalentList"));
    setFilteredTalents(
      talentList.filter(
        talent =>
          talent.name.toLowerCase().includes(search.toLowerCase()) ||
          talent.archetype.toLowerCase().includes(search.toLowerCase()) ||
          talent.effects.toLowerCase().includes(search.toLowerCase())
      )
    );

    return filteredTalents;
  };

  /**
   * Handles the deletion of a talent from the character's talents array.
   *
   * @param {string} talent - The talent to be deleted.
   * @returns {void}
   */
  const handleDeleteTalent = talent => {
    console.log(`Deleting talent: ${talent}`);

    // Update react array state
    setLwCurrentCharacter(prevValue => ({
      ...prevValue,
      talents: lwCurrentCharacter.talents.filter(s => s !== talent),
    }));

    handleCloseEditModal();
  };

  return (
    <div>
      <Button
        id="addTalentButton"
        className="mb-4"
        onClick={handleShowAddModal}
      >
        <FaPlus /> Add Talent
      </Button>
      {/* Display talents alphabetically */}
      <Row className="gy-4">
        {lwCurrentCharacter?.talents
          ?.sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((talent, index) => {
            return (
              <TalentCard
                key={index}
                talentName={talent}
                removeTalent={() => handleDeleteTalent(talent)}
              />
            );
          })}
      </Row>

      {/* New talent modal */}
      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Talent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            id="search-talent"
            name="search-talent"
            placeholder="Search"
            className="form-control"
            onChange={handleSearchTalent}
          />
          {filteredTalents?.map((talent, index) => {
            return (
              <div key={index} className="mb-3">
                <Button
                  key={index}
                  variant="link"
                  onClick={() => handleAddTalent(talent?.name)}
                >
                  <h4>
                    {talent?.name} <FaPlusSquare />
                  </h4>
                </Button>
                <p>
                  <i>{talent?.archetype}</i>
                </p>
                <div dangerouslySetInnerHTML={{ __html: talent.effects }} />
                <hr />
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit talent modal */}
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>{prevTalent}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            dangerouslySetInnerHTML={{
              __html: talentList?.filter(
                talent => talent.name === prevTalent
              )[0]?.effects,
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleDeleteTalent(prevTalent)}
          >
            Delete Talent
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LwTalentsSection;
