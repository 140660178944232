import { useContext, useState } from "react";
import Section from "../components/Section";
import Titlebar from "../components/Titlebar";
import { Nav } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import MainContainer from "../components/MainContainer";
import { UserPrefsContext } from "../hooks/UserContext";

const currentTab = sessionStorage.getItem("currentAdminTab")
  ? sessionStorage.getItem("currentAdminTab")
  : "EnemyCreatorTab";

function AdminTools() {
  const userPrefs = useContext(UserPrefsContext)
  const [activeTab, setActiveTab] = useState(currentTab);

  const handleChangeTab = (e) => {
    const tab = e.target.getAttribute("target-tab");
    setActiveTab(tab);
    sessionStorage.setItem("currentAdminTab", tab);
  };

  return (
    <div className="text-center">
      <Titlebar>Admin Tools</Titlebar>
      <Nav
        justify
        variant="pills"
        className={`bg-${userPrefs.userPrefs?.theme} sticky-top container-fluid`}
        defaultActiveKey={activeTab}
        style={{
          padding: "0.5em",
          marginBottom: "20px",
          top: "81px",
          zIndex: 100,
        }}
      >
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="enemy-creator"
            eventKey="EnemyCreatorTab"
            target-tab="EnemyCreatorTab"
            onClick={(e) => handleChangeTab(e)}
          >
            Enemy Creator
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="ability-creator"
            eventKey="AbilitiesTab"
            target-tab="AbilitiesTab"
            onClick={(e) => handleChangeTab(e)}
          >
            Ability Creator
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Section>
        <MainContainer>
          <Outlet />
        </MainContainer>
      </Section>
    </div>
  );
}

export default AdminTools;
