import { createContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, usersCol } from "../firebaseSetup";
import { doc, getDoc } from "firebase/firestore";

export const UserContext = createContext(null);
export const UserPrefsContext = createContext(null);
export const CharacterDataContext = createContext(null);
export const CharacterAbilityContext = createContext(null);
export const CharacterInjuryContext = createContext(null);
export const CharacterInventoryContext = createContext(null);

export function UserDataContext({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userPrefs, setUserPrefs] = useState(null);
  const [currentCharacter, setCurrentCharacter] = useState(null);
  const [characterAbilities, setCharacterAbilities] = useState(null);
  const [characterInjuries, setCharacterInjuries] = useState(null);
  const [characterInventory, setCharacterInventory] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        setCurrentUser(user);

        // add user Firestore document to user context
        const userDocRef = doc(usersCol, user.uid);
        getDoc(userDocRef).then(doc => {
          setUserPrefs(doc.data());
        });
      } else {
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("email");
      }
    });
  }, []);

  return (
    <>
      <UserContext.Provider value={{ currentUser, setCurrentUser }}>
        <CharacterDataContext.Provider
          value={{ currentCharacter, setCurrentCharacter }}
        >
          <CharacterAbilityContext.Provider
            value={{ characterAbilities, setCharacterAbilities }}
          >
            <CharacterInjuryContext.Provider
              value={{ characterInjuries, setCharacterInjuries }}
            >
              <CharacterInventoryContext.Provider
                value={{ characterInventory, setCharacterInventory }}
              >
                {children}
              </CharacterInventoryContext.Provider>
            </CharacterInjuryContext.Provider>
          </CharacterAbilityContext.Provider>
        </CharacterDataContext.Provider>
      </UserContext.Provider>
    </>
  );
}
