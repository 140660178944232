function RestingEffects() {
  return (
    <>
      <h2>Short Rest</h2>
      <p>
        A short rest requires at least two hours spent only on resting. You can
        lean up against a tree, take a short nap, or stop for a meal. You can
        move around the general area during a short rest, but you cannot travel.
        You can take actions which require skill checks during a short rest as
        long as the actions are considered light enough by the Storyteller. You
        can't perform any action which would require much exertion while
        resting.
      </p>
      <p>
        When you take a short rest, you may make a supply roll with -1. If you
        do, heal 1 harm for each of your character harm tracks which is not
        marked.
      </p>
      {/* <ul>
        <li>
          <b>Restore mana:</b> If you're resting near an Esael tree, increase
          your current mana level by 1. You cannot increase your current mana
          level beyond your maximum mana level.
        </li>
      </ul> */}
      <h2>Long Rest</h2>
      <p>
        A long rest requires at least seven hours of uninterrupted rest. A long
        rest can include a full night's sleep, a day of relaxation and
        energizing, or the treatment of injuries. As when taking a short rest,
        you may move around a little and perform light actions, but you can't do
        anything physically demanding.
      </p>
      <p>
        When you take a long rest, you may make a supply roll with -1. If you
        do, perform the following:
      </p>
      <ul>
        <li>
          <b>Heal character harm:</b> Heal each of your character harm tracks by
          half the corresponding skill bonus (Resistance, Willpower, Endurance),
          rounded up.
        </li>
        {/* <li>
          <b>Heal injury damage:</b> For each of your character harm tracks
          which has been completely healed, heal each consequence of the same
          type which has been healed by the corresponding skill bonus.
        </li> */}
        {/* <li>
          <b>Restore mana:</b> If you're resting near an Esael tree, increase
          your current mana level to your maximum mana level.
        </li> */}
        <li>
          <b>Spend fate:</b> Spend fate to increase an attribute, increase a
          proficiency, upgrade an ability, or purchase a new ability.
        </li>
      </ul>
    </>
  );
}

export default RestingEffects;
