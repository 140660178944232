import { Table } from "react-bootstrap";
import MagicIngredient from "../../../components/MagicIngredient";
import Section from "../../../components/Section";

function Conjuration() {
  return (
    <Section>
      <h1 className="title-header">Conjuration</h1>
      <p>
        Conjuration spells empower casters to call forth creatures and entities
        from distant realms, opening portals to other planes to bring forth aid
        or unleash powerful forces. Conjurers can summon elementals, celestial
        beings, or even ethereal spirits to fight alongside them, adding an
        array of unique abilities to their arsenal. These magical allies heed
        the Conjurer's call, ready to follow their commands and lend their might
        in times of need.
      </p>
      <p>
        In addition to summoning, Conjurers can create temporary objects and
        barriers, manipulate the fabric of reality, and even manipulate space
        itself. They can conjure weapons, barriers, and fortifications out of
        thin air, reshape the environment to their advantage, and traverse great
        distances with the blink of an eye. The versatility of Conjuration magic
        allows practitioners to adapt to a wide range of situations, making them
        formidable allies and adaptable spellcasters.
      </p>
      <p>
        The practice of Conjuration requires a deep connection to other planes
        of existence and an understanding of the intricate mechanics that govern
        the summoning and manipulation of extraplanar energies. Conjurers must
        possess a strong force of will and the ability to establish and maintain
        control over the beings and forces they conjure.
      </p>
      <hr />
      <MagicIngredient
        number={1}
        name="Animasprig"
        cost="Modest"
        tags="Conjuration"
      >
        <p>
          Target inanimate construct comes to life as your ally, following your
          commands. It remains under your control for 1 hour, then becomes
          inanimate again.
        </p>
        <p>
          When the construct is animated, give it an attribute score of 60, an
          attribute score of 50, and an attribute score of 40. These scores are
          used as the construct's skill percentages for each corresponding
          skill. The construct's health is equal to its Body bonus, multiplied
          by two.
        </p>
        <Table>
          <thead>
            <tr>
              <th>Attribute</th>
              <th>Skills</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Body</th>
              <td>STR, AGI, PRE, INV, RES</td>
            </tr>
            <tr>
              <th>Mind</th>
              <td>FOC, DEX, CUN, KND, WIL</td>
            </tr>
            <tr>
              <th>Spirit</th>
              <td>LCK, SVL, MLE, IST, END</td>
            </tr>
          </tbody>
        </Table>
        <p>
          This ingredient can be added to an effect multiple times. Each time
          it's added, increase the spellcrafting difficulty by 10% or the
          spellcrafting time by 4 hours, and increase one attribute score by 5.
        </p>
      </MagicIngredient>
    </Section>
  );
}

export default Conjuration;
