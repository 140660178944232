import Button from "react-bootstrap/Button";
import { useState } from "react";
import Section from "../../components/Section";
import MainContainer from "../../components/MainContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { addDoc, collection, updateDoc } from "firebase/firestore";
import { auth, lwCharactersCol } from "../../firebaseSetup";
import NumberInput from "../../components/CharacterSheet/NumberInput";
import Form from "react-bootstrap/Form";

function CreateLwCharacter() {
  const [name, setName] = useState("");
  const [pronouns, setPronouns] = useState("");
  const [occupation, setOccupation] = useState("");
  const [backgroundPursuit, setBackgroundPursuit] = useState("");
  const [supply, setSupply] = useState("0");
  const [coin, setCoin] = useState("0");
  const [might, setMight] = useState("0");
  const [finesse, setFinesse] = useState("0");
  const [wits, setWits] = useState("0");
  const [rapport, setRapport] = useState("0");
  const [resolve, setResolve] = useState("0");
  const [character, setCharacter] = useState({
    name: "",
    pronouns: "",
    occupation: "",
    backgroundPursuit: "",
    supply: "0",
    coin: "0",
    might: "0",
    finesse: "0",
    wits: "0",
    rapport: "0",
    resolve: "0",
    notableGear: "",
  });

  const handleSetCharacterData = dataId => {
    const data = document.getElementById(dataId).value;

    setCharacter({
      ...character,
      [dataId]: data,
    });
  };

  const handleCreateCharacter = () => {
    let charId = "";
    let charPursuitsCol = "";

    // Send character object to database
    addDoc(lwCharactersCol, {
      name: character.name,
      pronouns: character.pronouns,
      occupation: character.occupation,
      experience: "0",
      core: "6",
      curse: "0",
      damage: "0",
      fatigue: "0",
      confusion: "0",
      doubt: "0",
      fear: "0",
      supply: character.supply,
      coin: character.coin,
      might: character.might,
      finesse: character.finesse,
      wits: character.wits,
      rapport: character.rapport,
      resolve: character.resolve,
      notableGear: character.notableGear,
      criticalConditions: [],
      healingProgress: 0,
      isBroken: false,
      journals: [],
      pursuits: [],
      talents: [],
      owner: auth.currentUser.uid,
    }).then(docRef => {
      // Add background pursuit to pursuits collection
      charId = docRef.id;

      localStorage.setItem("currentLwCharacterId", charId);

      alert(`Character ${character.name} created!`);
    });
  };

  return (
    <MainContainer>
      <Section>
        <h1 className="title-header">Summary</h1>
        <Row className="gy-3 mb-3">
          <Col xs={8}>
            <input
              id="name"
              className="form-control"
              type="text"
              placeholder="Character Name"
              onChange={() => {
                handleSetCharacterData("name");
              }}
            />
          </Col>
          <Col xs={4}>
            <input
              id="pronouns"
              className="form-control"
              type="text"
              placeholder="Nickname and Pronouns"
              onChange={() => {
                handleSetCharacterData("pronouns");
              }}
            />
          </Col>
        </Row>
        <Row className="gy-3 mb-3">
          <Col>
            <input
              id="occupation"
              className="form-control"
              type="text"
              placeholder="Occupation"
              onChange={() => {
                handleSetCharacterData("occupation");
              }}
            />
          </Col>
        </Row>
        <Row className="gy-3 mb-3">
          <Col>
            <input
              id="backgroundPursuit"
              className="form-control"
              type="text"
              placeholder="Background Pursuit"
              onChange={() => {
                handleSetCharacterData("backgroundPursuit");
              }}
            />
          </Col>
        </Row>
        <Row className="gy-3">
          <Col xs={6}>
            <h2>Supply</h2>
            <NumberInput
              id="supply"
              value={character?.supply}
              justify={true}
              change={() => {
                handleSetCharacterData("supply");
              }}
            />
          </Col>
          <Col xs={6}>
            <h2>Coin</h2>
            <NumberInput
              id="coin"
              value={character?.coin}
              justify={true}
              change={() => {
                handleSetCharacterData("coin");
              }}
            />
          </Col>
        </Row>
      </Section>
      <Section>
        <h1>Attributes</h1>
        <Row>
          <Col xs={6} md={4}>
            <h2 className="text-primary">Might</h2>
            <NumberInput
              id="might"
              value={character?.might}
              justify={true}
              change={() => {
                handleSetCharacterData("might");
              }}
            />
          </Col>
          <Col xs={6} md={4}>
            <h2 className="text-primary">Finesse</h2>
            <NumberInput
              id="finesse"
              value={character?.finesse}
              justify={true}
              change={() => {
                handleSetCharacterData("finesse");
              }}
            />
          </Col>
          <Col xs={6} md={4}>
            <h2 className="text-primary">Wits</h2>
            <NumberInput
              id="wits"
              value={character?.wits}
              justify={true}
              change={() => {
                handleSetCharacterData("wits");
              }}
            />
          </Col>
          <Col xs={6}>
            <h2 className="text-primary">Rapport</h2>
            <NumberInput
              id="rapport"
              value={character?.rapport}
              justify={true}
              change={() => {
                handleSetCharacterData("rapport");
              }}
            />
          </Col>
          <Col xs={6}>
            <h2 className="text-primary">Resolve</h2>
            <NumberInput
              id="resolve"
              value={character?.resolve}
              justify={true}
              change={() => {
                handleSetCharacterData("resolve");
              }}
            />
          </Col>
        </Row>
      </Section>
      <Section>
        <h1 className="title-header">Details</h1>
        <Row className="gy-3">
          <Col>
            <h2>Notable Gear</h2>
            <input
              className="form-control"
              type="text"
              id="notableGear"
              placeholder="Notable Gear"
              onChange={() => {
                handleSetCharacterData("notableGear");
              }}
            />
          </Col>
        </Row>
      </Section>
      <Section>
        <Button variant="primary" onClick={handleCreateCharacter}>
          Create Character
        </Button>
      </Section>
    </MainContainer>
  );
}

export default CreateLwCharacter;
