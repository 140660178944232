import MagicIngredient from "../../../components/MagicIngredient";
import Section from "../../../components/Section";

const abjurationData = [
  {
    name: "Stormshield Willow",
    cost: "Modest",
    effect:
      "Grants resistance to electrical-based effects, reducing damage taken from lightning and providing insulation against electrical shocks.",
  },
  {
    name: "Spectral Wardbloom",
    cost: "Comfortable",
    effect:
      "Grants resistance to ethereal effects, providing defense against spirits and spectral illusions.",
  },
  {
    name: "Shadowveil Thistle",
    cost: "Modest",
    effect:
      "Provides protection against shadow magic and illusions, rendering the user less susceptible to manipulation and shadow-based effects.",
  },
  {
    name: "Enchanted Ironbark",
    cost: "Modest",
    effect: "Provides increased defense against physical damage.",
  },
  {
    name: "Netherbane Bramble",
    cost: "Modest",
    effect:
      "Weakens Necromancy effects, reducing the effectiveness of curses, life-draining spells, and undead creatures.",
  },
  {
    name: "Mindshield Blossom",
    cost: "Modest",
    effect:
      "Enhances mental fortitude and grants resistance against psychic-based effects, reducing the effectiveness of telepathic and illusory manipulation.",
  },
  {
    name: "Serpentguard Fern",
    cost: "Modest",
    effect:
      "Applies resistance to poisons and venoms, reducing damage taken from toxins.",
  },
  {
    name: "Celestialbane Thistle",
    cost: "Comfortable",
    effect:
      "Counters other abjuration or restoration effects, reducing the effectiveness of healing and protection.",
  },
  {
    name: "Cyclone Shieldleaf",
    cost: "Modest",
    effect:
      "Grants protection against wind-based effects, reducing the impact of gusts and whirlwinds.",
  },
  {
    name: "Earthguard Root",
    cost: "Modest",
    effect:
      "Fortifies the user against earth-based effects, reducing damage taken from earthquakes, landslides, and other geomantic attacks.",
  },
  {
    name: "Disrupetal",
    cost: "Modest",
    effect:
      "Reduces the power of spellcasting within the vicinity, lowering the effectiveness of any spells or magical effects.",
  },
  {
    name: "Resonance Truffle",
    cost: "Wealthy",
    effect:
      "Greatly enhances protective magic, amplifying the power of abjuration spells and effects.",
  },
  {
    name: "Wardstone Blossom",
    cost: "Comfortable",
    effect:
      "Creates a temporary ward in a designated area that prevents the entry of hostile characters and creatures.",
  },
  {
    name: "Mystic Shieldfern",
    cost: "Modest",
    effect:
      "Boosts the defensive properties of equipment such as armor or shields.",
  },
  {
    name: "Voidshroud",
    cost: "Comfortable",
    effect:
      "Creates a barrier against conjured entities and dispells transmutation effects.",
  },
];

function Abjuration() {
  return (
    <Section>
      <h1 className="title-header">Abjuration</h1>
      <MagicIngredient
        number={1}
        name="Sunshroud Fern"
        cost="Poor"
        tags="Abjuration"
      >
        <p>
          <b>Effect:</b> Generates a radiant aura that offers protection against
          harmful light-based effects and burns inflicted by sunlight. Gain 1
          resistance against <b>Radiant</b>.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={2}
        name="Silverleaf"
        cost="Poor"
        tags="Abjuration"
      >
        <p>
          <b>Effect:</b> Provides resistance to the bitter cold. Gain 1
          resistance against <b>Frost</b>.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={3}
        name="Flameheart Rose"
        cost="Poor"
        tags="Abjuration"
      >
        <p>
          <b>Effect:</b> Burns with inner fire, granting resistance to heat and
          fire-based effects. Gain 1 resistance against <b>Fire</b>.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={4}
        name="Illusionist's Bane"
        cost="Wealthy"
        tags="Abjuration"
      >
        <p>
          <b>Effect:</b> <b>Illusion</b> effects which target you are 10%
          harder. When this effect is resolved, dispel any active{" "}
          <b>Illusion</b> effects within close range.
        </p>
        <p>
          This ingredient can be added to an effect multiple times. Each time
          it's added, increase the spellcrafting difficulty by 10% or the
          spellcrafting time by 4 hours, and either increase the difficulty of{" "}
          <b>Illusion</b> effects targeting you by 5% or increase the range at
          which you dispel <b>Illusion</b> effects to the next range up.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={5}
        name="Mirrorchid"
        cost="Comfortable"
        tags="Abjuration"
      >
        <p>
          <b>Effect:</b> Summons a magical barrier for 1 minute, which can
          reflect offensive spells. While the barrier is active, whenever you
          are targeted by an effect which could deal damage, that effect deals
          half damage to the user. If you succeed a skill check to avoid danger
          against an offensive spell, that spell deals its full damage to the
          user instead.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={6}
        name="Stormshield Willow"
        cost="Modest"
        tags="Abjuration"
      >
        <p>
          <b>Effect:</b> Grants resistance to electrical shock. Gain 1
          resistance against <b>Shock</b>.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={7}
        name="Enchanted Ironbark"
        cost="Modest"
        tags="Abjuration"
      >
        <p>
          <b>Effect:</b> Hardens the flesh and increases one's defense. Gain 1d4
          bonus damage threshold for 10 minutes.
        </p>
        <p>
          The bonus damage threshold of this effect may be modified using the
          Damage property table.
        </p>
      </MagicIngredient>
    </Section>
  );
}

export default Abjuration;
