function Outcomes() {
  return (
    <>
      <div>
        <h2>Failure</h2>
        <p>
          If you roll greater than the target skill percentage, you fail your
          skill check and suffer a penalty imposed by the Storyteller. Gain 1
          fate whenever you fail as you learn from your shortfall.
        </p>
        <p>
          Failing a skill check doesn't necessarily mean you fail your entire
          action. Depending on the circumstances, you and the Storyteller could
          agree that your action succeeds at a great cost.
        </p>
      </div>
      <hr />
      <div>
        <h2>Partial Success</h2>
        <p>
          If you roll less than or equal to the target skill percentage, you
          partially succeed your skill check.
        </p>
        <p>
          When you roll a partial success, the action will describe a cost or
          complication you must face.
        </p>
      </div>
      <hr />
      <div>
        <h2>Total Success</h2>
        <p>
          If you roll less than or equal to half the target skill percentage,
          you totally succeed your skill check. Gain 1 fate whenever you totally
          succeed.
        </p>
        <p>
          A total success avoids the complication which comes about with a
          partial success.
        </p>
      </div>
      <hr />
    </>
  );
}

export default Outcomes;
