import { Table } from "react-bootstrap";
import Section from "../../../components/Section";
import ExampleSection from "../../../components/ExampleSection";

function SpellCrafting() {
  return (
    <Section>
      <h1 className="title-header">Spellcrafting</h1>
      <p>
        It takes a lot of work to craft a new spell. The art of spellcrafting
        requires ink created from magical flora, written on pages of Esael,
        precious arcane parchment. An aspiring mage uses the ink made from the
        plant which produces their desired magical effects to write complex
        properties and functions of a spell. This requires immense dedication
        and work.
      </p>
      <h2>Required Materials</h2>
      <p>
        In order to craft a spell, you must have a spellcrafter's kit (or have
        access to a spellcrafting workshop), a focus, and some arcane
        ingredients.
      </p>
      <p>
        Once you've obtained the magical flora you wish to use in your
        spellcrafting, you'll use the following tables and steps to determine
        the difficulty and time needed to successfully create your desired
        spell.
      </p>
      <h2>Focus</h2>
      <p>
        Magic in Emberbrand doesn't work without a focus. A focus is some kind
        of object that helps you target your spell or contain its effects. If
        your spell targets another character, your focus could be something like
        a wand or a staff. If you wish to enchant an item or piece of equipment
        with an effect, that object is the focus.
      </p>
      <h2>The Base Spell</h2>
      <p>
        You begin crafting a spell by choosing a magical ingredient in your
        possession. The chosen ingredient has its own base spell. This is the
        default effect for a spell crafted with that ingredient. From this
        point, you can add ingredients or properties to your spell to create
        something truly wonderous.
      </p>
      <p>
        You can combine as many ingredients as you wish to your spell to give it
        extra effects. Let your imagination run wild with spells of frozen
        lightning, teleporting thunderstorms, or poisonous winds.
      </p>
      <p>
        Unless stated as part of its effect, each ingredient may only be added
        to a spell a single time. Adjust the spell modifier by -1 for each
        additional ingredient.
      </p>
      <h2>Discipline of Magic</h2>
      <p>
        Before you begin the process of putting your spell together, you must
        determine its discipline of magic. Your spell's discipline of magic
        corresponds to the ingredients used to craft it. For example, if you use
        Emberthorn to craft a fire spell, your spell belongs to the Evocation
        discipline of magic.
      </p>
      <p>
        If your spell uses ingredients from different disciplines of magic, it
        belongs to them all.
      </p>
      <h1 className="title-header">Properties of Your Spell</h1>
      <p>
        Use the following tables to put the properties of your spell together.
        Properties include how many charges your spell holds, how far you can
        cast it, how it targets characters, and so on. You don't have to pick a
        property from each table if you don't want to. If your spell isn't
        offensive, you don't have to give it a damage property for instance.
        Each property has multiple levels, each increasing in power and
        complexity. Your spell may hold more charges, but will also be more
        difficult to craft.
      </p>
      <p>
        The Storyteller has the final say in which properties you can add to
        your spell. If a property doesn't make sense in the story or in
        mechanical theory, the Storyteller can disallow that property's use in
        your spell. That said, creative spells can often be more fun than strict
        rules in the fictional world of Nilihaad.
      </p>
      <hr />

      <h2>Level</h2>
      <p>
        Your spell's level determines how many times you can cast it before
        sapping your Esael of its arcane power. A spell's level dictates its
        starting charges and its maximum charges. These charges are a supply
        roll; when you cast the spell, roll the corresponding die. If you roll a
        1, you must reduce the dice level. If you reduce below d4, you can no
        longer cast the spell until you recharge it.
      </p>
      <Table striped style={{ marginBottom: "30px" }}>
        <thead>
          <tr>
            <th>Level</th>
            <th>Spell modifier</th>
            <th>Time / Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I - d4</td>
            <td>0</td>
            <td>0 / 0</td>
          </tr>
          <tr>
            <td>II - d6</td>
            <td>-1</td>
            <td>2 / -1</td>
          </tr>
          <tr>
            <td>III - 1d8</td>
            <td>-2</td>
            <td>4 / -2</td>
          </tr>
          <tr>
            <td>IV - d10</td>
            <td>-3</td>
            <td>8 / -3</td>
          </tr>
          <tr>
            <td>V - d12</td>
            <td>-4</td>
            <td>16 / -4</td>
          </tr>
          <tr>
            <td>VI - d20</td>
            <td>-5</td>
            <td>24 / -5</td>
          </tr>
        </tbody>
      </Table>
      <hr />

      <h2>Range</h2>
      <p>
        Pick a range. This is how far your spell can be cast before its potency
        runs so low the target won't be affected. If you give your spell a
        range, add the <b>Ranged</b> tag, providing the chosen range in
        (parenthesis).
      </p>
      <p>
        If you don't give your spell a range, it can only be used on an adjacent
        target, the caster, or an enchanted item.
      </p>
      <Table striped style={{ marginBottom: "30px" }}>
        <thead>
          <tr>
            <th>Range</th>
            <th>Spell modifier</th>
            <th>Time / Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Close</td>
            <td>-1</td>
            <td>4 / -1</td>
          </tr>
          <tr>
            <td>Short</td>
            <td>-2</td>
            <td>8 / -2</td>
          </tr>
          <tr>
            <td>Medium</td>
            <td>-3</td>
            <td>12 / -3</td>
          </tr>
          <tr>
            <td>Long</td>
            <td>-4</td>
            <td>24 / -4</td>
          </tr>
        </tbody>
      </Table>
      <hr />

      <h2>Target</h2>
      <p>
        Choose how your spell targets, whether a single target, an area of
        effect, or only enemies or allies. If a spell has restrictions the type
        of target, such as an object or a living character, you can modify the
        target type.
      </p>
      <p>
        You may add extra targets to your spell by increasing either the
        spellcrafting difficulty by -2 or the time by 6 hours.
      </p>
      <p>If you're enchanting an object you don't need a target.</p>
      <Table striped style={{ marginBottom: "30px" }}>
        <thead>
          <tr>
            <th>Targeting</th>
            <th>Spell modifier</th>
            <th>Time / Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Single target</td>
            <td>0</td>
            <td>0 / 0</td>
          </tr>
          <tr>
            <td>Area of effect</td>
            <td>-2</td>
            <td>8 / -1</td>
          </tr>
          <tr>
            <td>Target allies only</td>
            <td>-3</td>
            <td>16 / -2</td>
          </tr>
          <tr>
            <td>Target enemies only</td>
            <td>-3</td>
            <td>16 / -3</td>
          </tr>
        </tbody>
      </Table>
      <hr />

      <h2>Entity</h2>
      <p>
        Some base spells specify the type of entity which can be affected, such
        as an object or a character. An object is something inanimate which
        cannot move under its own volition, including a corpse. A character is
        anything that's alive, including animals and sentient beings. A
        construct lies somewhere in between as an inanimate husk which has been
        prepared for easy animation. You can modify the types of entities your
        spells can affect.
      </p>
      <Table striped style={{ marginBottom: "30px" }}>
        <thead>
          <tr>
            <th>Entity</th>
            <th>Spell modifier</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Remove an entity type that can be affected</td>
            <td>+1</td>
            <td>-4 / +1</td>
          </tr>
          <tr>
            <td>Can also affect characters</td>
            <td>-1</td>
            <td>2 / -1</td>
          </tr>
          <tr>
            <td>Can also affect objects</td>
            <td>-2</td>
            <td>16 / -3</td>
          </tr>
          <tr>
            <td>Can also affect constructs</td>
            <td>-1</td>
            <td>5 / -2</td>
          </tr>
        </tbody>
      </Table>
      <hr />

      <h2>Target Size</h2>
      <p>
        You can modify this property multiple times to adjust the maximum size
        your spell's target can be.
      </p>
      <Table stripedstyle={{ marginBottom: "30px" }}>
        <thead>
          <tr>
            <th>Size</th>
            <th>Spell modifier</th>
            <th>Time / Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Reduce size by 1</td>
            <td>+1</td>
            <td>-2 / +1</td>
          </tr>
          <tr>
            <td>Increase size by 1</td>
            <td>-1</td>
            <td>2 / -1</td>
          </tr>
        </tbody>
      </Table>
      <hr />

      <h2>Area of Effect</h2>
      <p>
        If you added an area of effect to your spell, choose how large that area
        is.
      </p>
      <Table striped style={{ marginBottom: "30px" }}>
        <thead>
          <tr>
            <th>Area of Effect</th>
            <th>Spell modifier</th>
            <th>Time / Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Adjacent</td>
            <td>0</td>
            <td>0 / 0</td>
          </tr>
          <tr>
            <td>Close</td>
            <td>-1</td>
            <td>4 / -1</td>
          </tr>
          <tr>
            <td>Short</td>
            <td>-2</td>
            <td>8 / -2</td>
          </tr>
          <tr>
            <td>Medium</td>
            <td>-3</td>
            <td>12 / -3</td>
          </tr>
        </tbody>
      </Table>
      <hr />

      <h2>Damage</h2>
      <p>
        If you're crafting an offensive spell, you'll need to give it a damage
        value. This is the die you roll to determine how much damage your spell
        does. Any elemental effects from the ingredient you're using to craft
        your spell will also apply as damage is dealt.
      </p>
      <p>
        Some base spells have a default damage of 1d4. If you wish to increase
        the damage these spells can deal, reduce the difficulty and time
        modifiers by one level. For example, a spell created with Emberthorn has
        a default damage of 1d4. Increasing the damage to 1d6 would give -1
        difficulty and +2 hours instead.
      </p>
      <p>
        If your spell combines multiple ingredients, you'll only roll one damage
        die, and can only increase the level of that single die. This means
        adding Stormcalla Lily to your Emberthorn spell would <i>not</i> give
        your spell 2d4 damage. You only combine the tags and effect of each
        ingredient.
      </p>
      <Table striped style={{ marginBottom: "30px" }}>
        <thead>
          <tr>
            <th>Damage</th>
            <th>Spell modifier</th>
            <th>Time / Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Remove damage characteristic</td>
            <td>+1</td>
            <td>-4 / +1</td>
          </tr>
          <tr>
            <td>d4</td>
            <td>-1</td>
            <td>2 / -1</td>
          </tr>
          <tr>
            <td>d6</td>
            <td>-2</td>
            <td>4 / -2</td>
          </tr>
          <tr>
            <td>d8</td>
            <td>-3</td>
            <td>8 / -3</td>
          </tr>
          <tr>
            <td>d10</td>
            <td>-4</td>
            <td>16 / -4</td>
          </tr>
          <tr>
            <td>d12</td>
            <td>-5</td>
            <td>24 / -5</td>
          </tr>
          <tr>
            <td>d20</td>
            <td>-6</td>
            <td>32 / -6</td>
          </tr>
        </tbody>
      </Table>
      <hr />

      <h2>Resistance</h2>
      <p>
        This property can be modified multiple times. Each time it's modified,
        adjust the spell modifier by -1, and gain +1 resistance against a tag of
        your choice. If your spell already grants you resistance, you can either
        gain +1 to a granted resistance, or add a new resistance.
      </p>
      <hr />

      <h2>Duration</h2>
      <p>
        Your spell's duration dictates how long it lasts before its effects wear
        off. This property may be modified multiple times. Each time it's
        modified, increase the spell's duration by one level on the table and
        adjust the spell modifier by -1.
      </p>
      <p>
        One action as noted in a spell's duration is the time between the
        caster's last action and the next one they take.
      </p>
      <Table striped className="w-auto" style={{ marginBottom: "30px" }}>
        <thead>
          <tr>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1 Action</td>
          </tr>
          <tr>
            <td>2 Actions</td>
          </tr>
          <tr>
            <td>3 Actions</td>
          </tr>
          <tr>
            <td>1 Minute</td>
          </tr>
          <tr>
            <td>10 Minutes</td>
          </tr>
          <tr>
            <td>1 Hour</td>
          </tr>
          <tr>
            <td>Until Sunrise or Sunset</td>
          </tr>
          <tr>
            <td>1 Day</td>
          </tr>
          <tr>
            <td>1 Week</td>
          </tr>
          <tr>
            <td>1 Month</td>
          </tr>
          <tr>
            <td>6 Months</td>
          </tr>
          <tr>
            <td>1 Year</td>
          </tr>
          <tr>
            <td>10 Years</td>
          </tr>
        </tbody>
      </Table>
      <hr />

      <h2>Delay</h2>
      <p>
        Giving your spell a delay allows you to cast it without immediately
        applying its effects. The spell instead waits until a specific delay
        condition is met before resolving its effects. You can only have one
        delayed spell active at a time. If you cast a delayed spell, you lose
        connection to any you already have active and they are dispelled.
      </p>
      <p>
        A <b>time</b> delay will automatically trigger the spell's effects once
        the specified time is up. A <b>defined</b> delay will trigger the
        spell's effects based on a situation you specify. You define this
        trigger as you cast the spell.
      </p>
      <Table striped style={{ marginBottom: "30px" }}>
        <thead>
          <tr>
            <th>Delay</th>
            <th>Spell modifier</th>
            <th>Time / Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Time</td>
            <td>-1 for every five minutes</td>
            <td>4 / -1</td>
          </tr>
          <tr>
            <td>Defined</td>
            <td>-3</td>
            <td>8 / -2</td>
          </tr>
        </tbody>
      </Table>
      <hr />

      <h2>Craft Your Spell</h2>
      <p>
        Once you've put together all of the properties of your spell, you'll
        calculate how difficult it'll be and how long it'll take to get just
        right before it's ready for use.
      </p>
      <p>
        Total the <b>spell modifier</b> of all chosen properties from each
        table. If the spell modifier to craft your spell would make it
        impossible to roll at least a 1 on your spellcrafting supply roll, the
        spell requires too many resources and you can't craft it without
        applying modifiers to reduce the modifier.
      </p>
      <p>
        If you can roll at least a 1 on your spellcrafting supply roll after all
        modifiers, you can attempt to craft your spell with the spellcraft
        action.
      </p>
      {/* <p>
        For every property included in your spell, you may substitute that
        property's spell modifier for its <b>time and cost</b>. This means your
        spellcraft skill check will be easier to make, but you'll need to spend
        more time and money working on your spell before it's ready to use.
      </p> */}
      <hr />
      <h2>Spellcraft</h2>
      <p>
        When you attempt to craft a spell, draw upon your understanding of
        magical principles and your education. Make a Knowledge check and a
        supply roll using your spellcrafting kit. Modify the supply roll by the
        total spell modifier from all properties of your spell. Use your
        knowledge level in the spell's discipline of magic to reduce the spell
        modifier.
      </p>
      <ul>
        <li>
          <b>Failure:</b> You attempt at spellcrafting fails and you are unable
          to create your desired spell. You consume the ingredients you used to
          craft the spell and they are destroyed.
        </li>
        <li>
          <b>Partial success:</b> You manage to create a functional spell, but
          with some flaw. Randomly choose a property applied to your spell which
          is not at its lowest level and reduce its level once.
        </li>
        <li>
          <b>Total success:</b> You successfully craft a potent and
          well-structured spell with your desired effects.
        </li>
      </ul>
      <hr />
      <p>
        Total the <b>time</b> and <b>cost</b> of all chosen properties from each
        table which you substituted for the corresponding spell modifier. This
        is how long it'll take you to write formulas, scratch out mistakes, and
        the monetary cost of replacing pages of Esael used in your experiments.
        You need to spend this much time crafting your spell before it's ready
        to use. You can work on your spell while resting without any sort of
        penalty to the effects of your rest.
      </p>
      <p>
        The total cost is the modifier you must apply to a wealth roll before
        you can begin the process of creating your spell. If it would be
        impossible for you to roll a 1 or higher after applying the cost
        modifier and reducing your wealth level, you can't afford the spell and
        need to make adjustments to its properties.
      </p>
      <ExampleSection>
        After adding up the time and cost of the properties for his new spell,
        Ardorim has totaled a cost of -10. His current wealth level is Poor - d6
        and his wealth progression is 2. Subtracting 10 from his current wealth,
        he decreases his progression by 2, drops his wealth level to squalid -
        d4, then further decreases it by 4. He then drops to destitute, but
        still has -4 to his spell's cost. At this point, Ardorim realizes it
        would be impossible to craft his spell. Back to the arcane drawing
        board.
      </ExampleSection>
      <h2>Effects</h2>
      <p>
        Now that your spell is ready to be cast, write down its effects. Copy
        the effects of each included ingredient and effects provided by
        properties. If a property adds a tag to your spell, you don't need to
        write out that effect as long as the tag covers how that property
        interacts with your spell.
      </p>
      <h2>Tags</h2>
      <p>
        Now that you have your spell crafted and applied all of its properties,
        give your spell descriptive tags.
      </p>
      <p>
        The first tag you provide will be <b>Spell</b> if you crafted a castable
        spell. If you instead enchanted an item, the tag will be{" "}
        <b>Enchantment</b>. The second tag will be <b>Level X</b>, where X is
        the spell property you gave your spell.
      </p>
      <p>
        Combine the tags of all ingredients included in your spell, along with
        any provided by properties. Ignore any duplicate tags, there's no reason
        to write them down twice. The final list of tags describe your spell and
        apply tag rules.
      </p>
      <h1 className="title-header">Recharging a Spell</h1>
      <p>
        If you roll a 1 while your spell has d4 charges, your spell has been
        expended and you can't cast it anymore. You can recharge your spell by
        bringing its focus and source close to a living Esael tree, or by
        consuming an Esael root.
      </p>
      <p>
        Esael trees, the source of the arcane parchment upon which spells are
        written, can be found throughout the warmer taigas of Higzaar. Most
        cities have at least one tree which can be used to recharge spells for
        free. These trees are often found around inns so spellcasters can rest,
        recuperate, and recharge.
      </p>
      <p>
        To recharge a spell using a living Esael tree, you must keep your spell
        focus and your spell source within close distance of the tree for at
        least the length of a short rest.
      </p>
      <p>
        If you find yourself in the cold tundras where Esael can't grow, you can
        purchase Esael root. One root has a <b>modest</b> price and is consumed
        to recharge your spell by one dice level.
      </p>
      <p>
        You cannot give a spell more charges than its level. That means a level
        III spell cannot be charged beyond d8 charges.
      </p>

      <h1 className="title-header">Upgrading a Spell</h1>
      <p>
        Once you've crafted a spell, you can later upgrade it to make its
        properties more potent. Doing so is much easier than writing a brand new
        spell from scratch.
      </p>
      <p>
        To create an upgraded spell, pick a spell you have access to. Having
        access to a spell means that you can get ahold of the physical Esael to
        read its arcane inscriptions. You cannot access a spell which has been
        obfuscated by Serie's Orchid. You must also have the ingredients
        required to craft the spell as you must rewrite it onto new Esael
        parchment.
      </p>
      <p>
        With the spell and ingredients at hand, choose which properties you wish
        to modify. You cannot add any new properties to your spell, you can{" "}
        <i>only</i> modify its existing properties. For example, you could
        upgrade a spell to give it more charges, you could increase the damage
        of a spell which deals damage, or you could increase the range of a
        spell which has been given the <b>Ranged</b> tag. But you cannot add a
        range to a spell which wasn't previously given one, you can't add a new
        target to the spell, and you can't add a delay if the original didn't
        have one.
      </p>
      <p>
        The difficulty and time to craft the upgraded spell is only modified by
        the properties you modify. You don't need to consider the difficulty
        modifier or time of any properties you keep the same from your original
        spell. After you've chosen which properties to modify, make your
        spellcrafting check.
      </p>

      <h1 className="title-header">Education</h1>
      <p>
        If you're educated in a discipline of magic, you'll have an easier time
        of spellcrafting. It'll cost less and the process won't take as much
        time. You can spend time learning from books, a mentor, or through
        experimentation to increase your knowledge in one or more disciplines of
        magic. Spending time learning during a rest does not penalize the
        effects of that rest.
      </p>
      <p>
        When you craft a spell, modify the total cost and time by your knowledge
        level in that spell's discipline of magic. A spell's discipline of magic
        corresponds to the ingredient used to craft it. If you're crafting a
        spell using ingredients from more than one discipline of magic, you must
        use your lowest knowledge level among all included disciplines of magic.
      </p>
      <p>
        For every 50 hours of knowledge you have in a discipline, adjust the
        spell modifier by +1.
      </p>

      {/* <Table striped style={{ marginBottom: "30px" }}>
        <thead>
          <tr>
            <th>Knowledge Level</th>
            <th>Hours of Experience</th>
            <th>Spell modifier</th>
            <th>Time / Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Novice</td>
            <td>10-50</td>
            <td>+1</td>
            <td>0 / 0</td>
          </tr>
          <tr>
            <td>Adept</td>
            <td>50-100</td>
            <td>+2</td>
            <td>-2 / +1</td>
          </tr>
          <tr>
            <td>Skilled</td>
            <td>100-200</td>
            <td>+3</td>
            <td>-4 / +2</td>
          </tr>
          <tr>
            <td>Expert</td>
            <td>200-500</td>
            <td>+4</td>
            <td>-8 / +3</td>
          </tr>
          <tr>
            <td>Master</td>
            <td>500+</td>
            <td>+5</td>
            <td>-16 / +4</td>
          </tr>
        </tbody>
      </Table> */}
    </Section>
  );
}

export default SpellCrafting;
