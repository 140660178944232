import Section from "../Section";

function Fate() {
  return (
    <>
      <Section>
        <h1 className="title-header">Fate</h1>
        <p>
          Fate is a measure of your experience and growth. During your
          adventure, you'll earn fate through the actions you perform and the
          challenges you overcome. You earn 1 fate every time you partially
          succeed a skill check without exerting, and 2 fate every time you
          totally succeed. The Storyteller may also award additional fate as
          they deem appropriate.
        </p>
        <p>
          When you take a long rest, you can spend fate to to increase a
          proficiency, increase an attribute, upgrade an ability, or purchase a
          new ability.
        </p>
        <h2>Increase Proficiency</h2>
        <p>
          Choose a proficiency to increase, then spend fate equal to that
          proficiency's score, rounded to the nearest 10. After spending the
          required amount of fate, increase the chosen proficiency by 2d4.
        </p>
        <p>A proficiency may never be greater than 50.</p>
        <h2>Increase Attribute</h2>
        <p>
          Choose an attribute to increase, then spend fate equal to two times
          that attribute's score, rounded to the nearest 10. After spending the
          required amount of fate, increase the chosen attribute by 2d4.
        </p>
        <p>An attribute may never be greater than 40.</p>
        <h2>Upgrade Ability</h2>
        <p>
          Choose an ability tier to increase, then spend fate equal to ten times
          the ability's next tier. This means it costs 20 fate to upgrade a tier
          1 ability to tier 2 and 30 fate to upgrade a tier 2 ability to tier 3.
        </p>
        <p>
          A tier 5 ability is a mastered ability and cannot be upgraded further.
        </p>
        <h2>Purchase Ability</h2>
        <p>
          Spend 30 fate to purchase a new ability at tier 1. This new ability
          can correspond to any attribute and does not follow any sort of
          restriction imposed when you chose your starting abilities.
        </p>
      </Section>
    </>
  );
}

export default Fate;
