import Section from "../../../components/Section";
import MagicIngredient from "../../../components/MagicIngredient";

function Transmutation() {
  return (
    <Section>
      <h1 className="title-header">Transmutation</h1>
      <p>
        Transmutation is the arcane discipline that delves into the manipulation
        and alteration of the fundamental properties of matter and energy.
        Practitioners of Transmutation, known as Transmuters, possess the
        ability to reshape and transform the physical world through their
        mastery of magical forces. This versatile discipline of magic empowers
        its wielders to transmute materials, change shapes, and modify
        properties, unlocking a realm of creative and pragmatic possibilities.
      </p>
      <p>
        Transmutation encompasses a diverse array of effects, from minor
        alterations like changing the color of an object, to more complex feats
        such as transmuting objects into creatures or creating temporary
        constructs. The discipline's breadth allows Transmuters to be
        resourceful problem solvers, adapting to challenges and creatively
        reshaping their environment to suit their needs.
      </p>
      <MagicIngredient
        number={1}
        name="Wraithbark"
        cost="Wealthy"
        tags="Transmutation"
      >
        <p>
          <b>Effect:</b> The caster becomes incorporeal for 1 minute. While
          incorporeal, a character can pass through objects and avoid physical
          attacks. However, the character cannot make physical attacks or
          otherwise physical touch anything.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={2}
        name="Driftstone Petal"
        cost="Wealthy"
        tags="Transmutation, Ranged (Close)"
      >
        <p>
          <b>Effect:</b> Telekinetically move, lift, shove, or throw a tiny
          object.
        </p>
        <p>
          This ingredient can be added to a spell multiple times. Each time it's
          added, increase the spellcrafting difficulty by 20% or the
          spellcrafting time by 6 hours, and increase the size of the target
          object or character to the next size up.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={3}
        name="Scalevine"
        cost="Modest"
        tags="Transmutation"
      >
        <p>
          <b>Effect:</b> Choose to either reduce target object one size tag
          smaller or enlarge it one size tag larger. The object returns to its
          original size after 1 minute.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={4}
        name="Polymorphoss"
        cost="Modest"
        tags="Transmutation"
      >
        <p>
          <b>Effect:</b> Transform target small object into another of the same
          size. This effect lasts for 1 hour, then the target returns to its
          original form.
        </p>
      </MagicIngredient>
    </Section>
  );
}

export default Transmutation;
