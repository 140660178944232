import { Table } from "react-bootstrap";

function WeaponsList() {
  return (
    <>
      <Table responsive="sm" striped>
        <thead>
          <tr>
            <th>Weapon</th>
            <th>Cost</th>
            <th>Damage</th>
            <th>Tags</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Battleaxe</th>
            <td>Poor</td>
            <td>1d8</td>
            <td>Light, Melee, Versatile</td>
          </tr>
          <tr>
            <th>Crossbow</th>
            <td>Modest</td>
            <td>1d6</td>
            <td>Ammo (Bolts), Heavy, Pierce 1, Ranged (Long), Slow</td>
          </tr>
          <tr>
            <th>Dagger</th>
            <td>Poor</td>
            <td>1d4</td>
            <td>Light, Melee, Pierce 1, Thrown</td>
          </tr>
          <tr>
            <th>Greataxe</th>
            <td>Modest</td>
            <td>1d12</td>
            <td>Heavy, Melee, Slow</td>
          </tr>
          <tr>
            <th>Greatsword</th>
            <td>Modest</td>
            <td>1d12</td>
            <td>Heavy, Melee, Slow</td>
          </tr>
          <tr>
            <th>Handaxe</th>
            <td>Poor</td>
            <td>1d6</td>
            <td>Light, Melee, Thrown</td>
          </tr>
          <tr>
            <th>Longbow</th>
            <td>Poor</td>
            <td>1d8</td>
            <td>Ammo (Arrows), Heavy, Ranged (Long)</td>
          </tr>
          <tr>
            <th>Longsword</th>
            <td>Poor</td>
            <td>1d8</td>
            <td>Light, Melee</td>
          </tr>
          <tr>
            <th>Rapier</th>
            <td>Poor</td>
            <td>1d6</td>
            <td>Ammo (Arrows), Heavy, Ranged (Medium)</td>
          </tr>
          <tr>
            <th>Shortbow</th>
            <td>Poor</td>
            <td>1d6</td>
            <td>Ammo (Arrows), Heavy, Ranged (Medium)</td>
          </tr>
          <tr>
            <th>Shortsword</th>
            <td>Poor</td>
            <td>1d6</td>
            <td>Finesse, Light, Melee</td>
          </tr>
          <tr>
            <th>Spear</th>
            <td>Poor</td>
            <td>1d6</td>
            <td>Light, Melee, Pierce 1, Thrown, Versatile</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

export default WeaponsList;
