export const LwMoveData = {
  facePeril: {
    name: "Face Peril",
    category: "Action Move",
    description:
      "<div><p><b>When you face a risky or uncertain situation,</b> describe your action. If you act...<ul><li>With strength, endurance, or aggression, roll +Might.</li><li>With mobility, stealth, or precision, roll +Finesse.</li><li>With insight, knowledge, or perception, roll +Wits.</li><li>With charm, deception, or persuasion, roll +Rapport.</li><li>With willpower, fortitude, or determination roll +Resolve.</li></ul></p><p><b>On a strong hit,</b> you are successful. Take +1 Core or -1 Curse.</p><p><b>On a weak hit,</b> you succeed but not without a cost. Suffer -1 Core or suffer +1 harm to the attribute you rolled.</p><p><b>On a miss,</b> you fail, or a momentary success is undermined by a dire turn of events.</p></div>",
  },
  secureAnAdvantage: {
    name: "Secure an Advantage",
    category: "Action Move",
    description:
      "<div><p><b>When you act to gain leverage or insight,</b> describe your action. If you act...<ul><li>With strength, endurance, or aggression, roll +Might.</li><li>With mobility, stealth, or precision, roll +Finesse.</li><li>With insight, knowledge, or perception, roll +Wits.</li><li>With charm, deception, or persuasion, roll +Rapport.</li><li>With willpower, fortitude, or determination roll +Resolve.</li></ul></p><p><b>On a hit,</b> you succeed. <b>On a strong hit,</b> take both. <b>On a weak hit,</b> choose one.<ul><li>Take +1 Core.</li><li>Take -1 Curse.</li></ul></p><p><b>On a miss,</b> your preparation or assumed leverage fails.</p></div>",
  },
  offerAid: {
    name: "Offer Aid",
    category: "Passive Move",
    description:
      "<div><p><b>When you help an ally,</b> describe your action. Then <b>Secure an Advantage</b> in direct support of them. If you score a hit, your ally takes the benefits of the move instead of you.</p></div>",
  },
  strainYourself: {
    name: "Strain Yourself",
    category: "Passive Move",
    description:
      "<div><p><b>When you push yourself to keep going,</b> if your Core is less than 6, set it to 6. Then, suffer a severe condition.</p></div>",
  },
  heal: {
    name: "Heal",
    category: "Action Move",
    description:
      "<div><p><b>When you provide medical treatment to yourself or another character,</b> roll +Wits. You cannot treat yourself if you are Broken.</p><p>On a <b>hit,</b> if the patient is Broken, they clear their Broken status. On a <b>strong hit,</b> the patient marks progress twice on their Healing Clock. On a <b>weak hit,</b> the patient marks progress on their Healing Clock.</p><p><b>On a miss,</b> you exacerbate the injury or make the situation worse.</p><p>Then, if the patient's Healing Clock is filled, clear it and they choose one.<ul><li>Heal all harm from an attribute.</li><li>Reduce the Recovery on a severe condition. If the recovery is reduced to 0, clear that condition.</li></ul></p></div>",
  },
  recuperate: {
    name: "Recuperate",
    category: "Action Move",
    description:
      "<div><p><b>When you rest and recover,</b> roll +Resolve. If you are Broken, you cannot recuperate.</p><p>On a <b>hit,</b> you mark progress on your Healing Clock. On a <b>strong hit,</b> you mark progress twice. On a <b>weak hit,</b> you mark progress but suffer -1 Core.</p><p><b>On a miss,</b> you are unable to rest or recover.</p><p>Then, if your Healing Clock is filled, clear it and choose one.<ul><li>Heal all harm from an attribute.</li><li>Reduce the Recovery on a severe condition. If the recovery is reduced to 0, clear that condition.</li><li>Take +1 Core or -1 Curse.</li></ul></p></div>",
  },
  traverseTheWake: {
    name: "Traverse the Wake",
    category: "Action Move",
    description:
      "<div><p><b>When you set sail through perilous seas or journey by land across dangerous wilds,</b> give your expedition a name and challenge level. Then, for each segment of your journey, roll +Wits.</p><p><b>On a strong hit,</b> you reach a waypoint and mark progress.</p><p><b>On a weak hit,</b> you reach a waypoint but face a hardship that delays you or presents a threat. Mark progress. You may mark progress again if you choose one.<ul><li>Suffer -2 Core.</li><li>Suffer +2 harm to one attribute, or +1 harm to two attributes.</li><li>Suffer -2 Supply.</li><li>Suffer +1 Curse.</li><li>Discover a threat at the waypoint.</li></ul></p><p><b>On a miss,</b> you are waylaid en route, or discover a threat at the waypoint. Do not mark progress.</p></div>",
  },
};
