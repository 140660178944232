import MagicIngredient from "../../../components/MagicIngredient";
import Section from "../../../components/Section";

function Divination() {
  return (
    <Section>
      <h1 className="title-header">Divination</h1>
      <p>
        Divination spells enable casters to peer into the unknown, unveil hidden
        truths, and gain insights that would remain concealed to ordinary
        perception. Diviners can scry on distant locations, peer into the
        thoughts and memories of others, and foretell the outcomes of future
        events. Through rituals and focused concentration, Divination magic
        allows practitioners to plumb the depths of the past, present, and
        potential futures.
      </p>
      <p>
        While Divination is often associated with seeing into the future, it
        encompasses a wide range of magical abilities. Diviners can decipher
        cryptic omens, interpret the meaning of dreams, locate lost objects, and
        discern the intentions of individuals. Skilled practitioners might
        employ a variety of tools, such as crystal balls, tarot cards, or
        scrying mirrors, to aid their magical insights.
      </p>
      <p>
        The practice of Divination demands a deep attunement to the flow of time
        and the threads of destiny. Diviners possess a heightened sense of
        intuition and an innate ability to unravel the subtle patterns that
        shape the world. They often balance on the edge of the present moment,
        glimpsing the interconnected web of events that unfold around them.
      </p>
      <MagicIngredient
        number={1}
        name="Fortunweave Silk"
        cost="Aristocratic"
        tags=" Divination"
      >
        <p>
          <b>Magnitude:</b> 1d4
        </p>
        <p>
          <b>Effect:</b> Influence fate and alter luck. Increase target
          character's Luck bonus by this effect's magnitude during their next
          skill check.
        </p>
        <p>
          The magnitude property of this effect can be modified as though it
          were a damage property.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={2}
        name="Etherbond Sap"
        cost="Comfortable"
        tags="Divination"
      >
        <p>
          <b>Effect:</b> Establishes an ethereal link between two or more
          objects enchanted with the same effect. The linked objects can
          interact with one another as defined by the enchantment as long as
          they are within 10 miles.
        </p>
        <p>
          This ingredient can be added to an effect multiple times. Each time
          it's added, increase the spellcrafting difficulty by 10% or the
          spellcrafting time by 4 hours, and increase the range in which the
          linked objects can interact by an additional 10 miles.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={3}
        name="Foresight Lotus"
        cost="Comfortable"
        tags="Divination"
      >
        <p>
          <b>Effect:</b> Target character gains advantage on their next skill
          check.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={4}
        name="Beastwhisper Leaf"
        cost="Modest"
        tags="Divination"
      >
        <p>
          <b>Effect:</b> For the next 10 minutes, target character may
          communicate with animals. The target and any animal they communicate
          with can understand each other as though they speak the same language
          at the same level of understanding.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={5}
        name="Echovine"
        cost="Wealthy"
        tags="Divination"
      >
        <p>
          <b>Effect:</b> This effect targets two characters, the seer and the
          charmed. The seer gains the ability to peer into the memories of the
          charmed. For the next 5 minutes, the seer can access memories of the
          charmed as far back as 1 year.
        </p>
        <p>
          This ingredient can be added to an effect multiple times. Each time
          it's added, increase the spellcrafting difficulty by 10% or the
          spellcrafting time by 4 hours, and add 1 year to the history of
          memories accessible by the seer.
        </p>
      </MagicIngredient>
    </Section>
  );
}

export default Divination;
