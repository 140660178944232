import { Col, Row } from "react-bootstrap";
import Section from "../Section";
import { useContext, useRef, useState } from "react";
import { CharacterDataContext } from "../../hooks/UserContext";
import { AutoSaveCharacter } from "../../backend/AutoSaveCharacter";
import { Collapse } from "react-bootstrap";
import RestingEffects from "../RulesReference/RestingEffects";
import ExhaustionRow from "./ExhaustionRow";
import TraumaRow from "./TraumaRow";
import DamageRow from "./DamageRow";
import NumberInput from "./NumberInput";
import ReferenceHeader from "../RulesReference/ReferenceHeader";
import HarmTable from "../RulesReference/HarmTable";

function VitalitySection({
  damageThreshold,
  traumaThreshold,
  exhaustionThreshold,
}) {
  const { currentCharacter, setCurrentCharacter } =
    useContext(CharacterDataContext);
  const [vitalityHelpOpen, setVitalityHelpOpen] = useState(false);
  const autoSaveTimer = useRef(null);

  const handleSetCharacterData = id => {
    const value = document.getElementById(id).value;

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }
    setCurrentCharacter(prevValue => ({
      ...prevValue,
      [id]: value,
    }));

    autoSaveTimer.current = setTimeout(() => {
      AutoSaveCharacter(id, value);
    }, 1000);
  };

  return (
    <Section>
      <ReferenceHeader
        id="vitality-help-text"
        title="Vitality"
        defaultTab={<RestingEffects />}
        defaultEventKey="resting"
        tabs={[
          {
            id: "resting-tab",
            title: "Resting",
            eventKey: "resting",
            content: <RestingEffects />,
          },
          {
            id: "harm-tab",
            title: "Harm",
            eventKey: "harm",
            content: <HarmTable />,
          },
        ]}
      />
      <Row>
        <Col xs={6}>
          <h3 className="text-primary">Fate</h3>
          <NumberInput
            id="survivalPoints"
            value={currentCharacter?.survivalPoints}
            justify={true}
            change={handleSetCharacterData}
          />
        </Col>
        <Col xs={6}>
          <h3 className="text-primary">Armor</h3>
          <NumberInput
            id="armor"
            value={currentCharacter?.armor}
            justify={true}
            change={handleSetCharacterData}
          />
        </Col>
      </Row>
      <hr />
      <DamageRow damageThreshold={damageThreshold} />
      <TraumaRow traumaThreshold={traumaThreshold} />
      <ExhaustionRow exhaustionThreshold={exhaustionThreshold} />
    </Section>
  );
}

export default VitalitySection;
