import ExampleSection from "../../../components/ExampleSection";
import RangeBands from "../../../components/RulesReference/RangeBands";
import Section from "../../../components/Section";

function CombatRange() {
  return (
    <Section>
      <h1 className="title-header">Range</h1>
      <p>
        At the beginning of combat, the Storyteller will describe the position
        of each combatant, setting your initial range from your enemies. There
        are four ranges at which you can be from your target: adjacent, close,
        near, far, and distant.
      </p>
      <p>
        A weapon's range tag specifies the furthest distance at which that
        weapon may be used effectively. You can fire a ranged weapon at a
        maximum range of one higher than its range tag, but no further. If you
        fire a weapon at its absolute maximum range, you do so with
        disadvantage.
      </p>
      <ExampleSection>
        <p>
          A weapon with the Ranged (Short) tag may be used against a target
          that's short distance or closer without any sort of penalty. That
          weapon can be used with disadvantage against a target at medium range,
          but may not be used against a target at long range.
        </p>
      </ExampleSection>
      <RangeBands />
      <ExampleSection>
        <p>
          Anything beyond long range could be classified as extreme range.
          Without very special equipment or incredible talent, you won't be able
          to interact with a target this far away.
        </p>
      </ExampleSection>
      <p>
        As combat progresses, you will need to keep track of your position as
        you move around the environment and changes in distance between you and
        enemies. If you lose track of your range to an enemy, coordinate with
        the Storyteller to regain your positioning. Range and distance are
        controlled narratively, meaning you would describe your intent of
        positioning closer to an enemy by stating you will move to a closer
        range.
      </p>
    </Section>
  );
}

export default CombatRange;
