import { Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import LwBasics from "../../../pages/LeviathansWake/rules/basics/LwBasics";
import LwActionRoll from "../../../pages/LeviathansWake/rules/basics/LwActionRoll";

export default (
  <Route path="lw-core-rules">
    <Route path="lw-basics" element={<LwBasics />} />
    <Route path="lw-rolls" element={<LwActionRoll />} />
    <Route index element={<Navigate to="lw-basics" />} />
  </Route>
);
