import React, { useContext } from "react";
import { doc, getDoc, getDocs, query, where } from "@firebase/firestore";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { lwCharactersCol } from "../../firebaseSetup";
import Form from "react-bootstrap/Form";
import { UserContext } from "../UserContext";
import { LwCharacterDataContext } from "./LwContext";
import { Link } from "react-router-dom";
import { BsPersonSquare } from "react-icons/bs";

export function LoadLwCharacter({ title }) {
  const { currentUser } = useContext(UserContext);
  const { lwCurrentCharacter, setLwCurrentCharacter } = useContext(
    LwCharacterDataContext
  );
  const [show, setShow] = useState(false);
  const [characters, setCharacters] = useState([]);
  const [chosenCharacterId, setchosenCharId] = useState({
    chosenCharId: localStorage.getItem("currentLwCharacterId"),
    another: "another",
  });
  const [charIdInputValue, setCharIdInputValue] = useState("");
  const { chosenCharId } = chosenCharacterId;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = e => {
    e.persist();

    setchosenCharId(prevState => ({
      ...prevState,
      chosenCharId: e.target.value,
    }));
  };

  const handleEnterCharId = e => {
    setCharIdInputValue(e.target.value);
    setchosenCharId(prevState => ({
      ...prevState,
      chosenCharId: e.target.value,
    }));
  };

  const handleLoadCharacter = () => {
    console.log("Loading character...");
    handleClose();

    let charData = {};

    // get Firestore references
    const charDocReference = doc(lwCharactersCol, chosenCharId);

    getDoc(charDocReference)
      .then(doc => {
        // Get character data from Firestore
        charData = doc.data();

        localStorage.setItem("currentLwCharacterId", chosenCharId);
      })
      .catch(error => {
        console.log("Error loading character:", error);
      })
      .finally(() => {
        setLwCurrentCharacter(charData);
        console.log("Character loaded successfully!");
      });
  };

  // load character if ID exists in storage
  useEffect(() => {
    if (!lwCurrentCharacter) {
      if (localStorage.getItem("currentLwCharacterId")) {
        console.log(`found character ID ${chosenCharId} in storage...`);
        handleLoadCharacter();
      } else {
        console.log("No character ID found in storage.");
      }
    } else {
      console.log("Character already loaded:", lwCurrentCharacter);
    }
  }, []);

  // Load user's list of characters
  useEffect(() => {
    const charArray = [];

    if (currentUser !== null) {
      console.log(
        "loading Leviathan's Wake character list for user y9tH4PZzyaRKF9f0XjMbrnaV4to2"
      );
      const char = query(
        lwCharactersCol,
        where("owner", "==", currentUser.uid)
      );

      getDocs(char).then(snapshot => {
        snapshot.docs.forEach(doc => {
          const id = doc.id;
          const name = doc.get("name");
          const charCheck = (
            <Form.Check
              name="charRadio"
              label={name}
              key={id}
              value={id}
              aria-label={name}
              type="radio"
              onChange={handleChange}
            />
          );

          charArray.push(charCheck);
        });
      });

      setCharacters(charArray);
    }
  }, [currentUser]);

  return (
    <>
      {/* <Button
        id="add-skill-button"
        className="floating-button"
        variant="secondary"
        onClick={handleShow}
        style={{
          bottom: "20px",
        }}
      >
        <BsPersonSquare />
      </Button> */}

      <Button
        variant="link"
        onClick={handleShow}
        style={{ textDecoration: "none" }}
      >
        My Characters
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>My Characters</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-left" style={{ margin: "10px" }}>
          <Form>
            <Form.Group controlId="chosenCharId">
              {characters}
              <hr />
              <Form.Check
                name="charRadio"
                label="Load by character ID"
                key="manual-char-id"
                value={charIdInputValue}
                aria-label="char-id"
                type="radio"
                onChange={handleChange}
              />
            </Form.Group>
            <div style={{ marginTop: "20px" }}>
              <h5>Character ID</h5>
              <input
                className="text-left"
                id="character-id-input"
                value={charIdInputValue}
                onChange={handleEnterCharId}
              />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to="../create-new-lw-character"
            >
              Create New
            </Link>
          </Button>
          <Button variant="danger">Delete</Button>
          <Button variant="primary" type="submit" onClick={handleLoadCharacter}>
            Load
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
