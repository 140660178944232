import { Outlet } from "react-router-dom";
import Titlebar from "../components/Titlebar";
import MainContainer from "../components/MainContainer";

function CampaignManager() {
  return (
    <>
      <Titlebar>Campaign Manager</Titlebar>
      <MainContainer>
        <div className="text-center">
          <Outlet />
        </div>
      </MainContainer>
    </>
  );
}

export default CampaignManager;
