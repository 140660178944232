import { Table } from "react-bootstrap";

function MentalConditionsTable() {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>d66</th>
          <th>Condition</th>
          <th>Fatal</th>
          <th>Time Limit</th>
          <th>Effect</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>11</td>
          <td>Confused</td>
          <td>No</td>
          <td>-</td>
          <td>
            <b>Observation</b> -1
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default MentalConditionsTable;
