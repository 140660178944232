import { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CharacterDataContext } from "../../hooks/UserContext";
import { AutoSaveCharacter } from "../../backend/AutoSaveCharacter";
import NumberInput from "./NumberInput";

function DamageRow({ damageThreshold }) {
  const { currentCharacter, setCurrentCharacter } =
    useContext(CharacterDataContext);
  const [thresholdExceeded, setThresholdExceeded] = useState(false);

  const autoSaveTimer = useRef(null);

  useEffect(() => {
    currentCharacter?.damage > damageThreshold
      ? setThresholdExceeded(true)
      : setThresholdExceeded(false);
  }, [currentCharacter?.damage, damageThreshold]);

  const ThresholdExceeded = () => (
    <div id="damage-threshold-exceeded" className="text-danger">
      <p>
        You've taken too much physical damage! Whenever you suffer damage above
        your DT, roll on the harm table and add the damage above your DT to the
        roll.
      </p>
    </div>
  );

  const handleSetCharacterData = id => {
    const value = document.getElementById(id).value;

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }
    setCurrentCharacter(prevValue => ({
      ...prevValue,
      [id]: value,
    }));

    autoSaveTimer.current = setTimeout(() => {
      AutoSaveCharacter(id, value);
    }, 1000);
  };

  return (
    <>
      <h2 className={thresholdExceeded ? "text-danger" : "text-primary"}>
        Damage Threshold ({damageThreshold})
      </h2>
      {thresholdExceeded ? <ThresholdExceeded /> : null}
      <Row>
        <Col xs={6}>
          <h3 className="text-primary">DT Modifier</h3>
          <NumberInput
            id="bonusDamageThreshold"
            value={currentCharacter?.bonusDamageThreshold}
            justify={true}
            change={handleSetCharacterData}
          />
        </Col>
        <Col xs={6}>
          <h3 className="text-primary">Damage</h3>
          <NumberInput
            id="damage"
            value={currentCharacter?.damage}
            justify={true}
            change={handleSetCharacterData}
          />
        </Col>
      </Row>
      <WoundedCheck wounded={currentCharacter?.wounded} />
      <hr />
    </>
  );
}

function WoundedCheck({ wounded }) {
  const [isWounded, setIsWounded] = useState(false);
  const autoSaveTimer = useRef(null);

  useEffect(() => {
    if (wounded) {
      setIsWounded(wounded);
    }
  }, [wounded]);

  const handleSetWoundedData = () => {
    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }

    setIsWounded(!isWounded);

    autoSaveTimer.current = setTimeout(() => {
      AutoSaveCharacter("wounded", !isWounded);
    }, 1000);
  };

  return (
    <Row className="text-left">
      <div className="magnitude-row">
        <h2 className="text-primary">Wounded</h2>
        <input
          id="wounded"
          type="checkbox"
          className="treated form-check-input checkbox-lg"
          checked={isWounded}
          onChange={handleSetWoundedData}
          style={{ width: "30px", height: "30px" }}
        />
      </div>
    </Row>
  );
}

export default DamageRow;
