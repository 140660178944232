function ItemsList() {
  return (
    <>
      <h1 className="title-header">Items</h1>
      <h2>Adventurer's Pack</h2>
      <p>
        The adventurer's pack is full of useful mundane items such as chalk,
        ropes, poles, rations, etc. When you retrieve an item from the pack, you
        find what you need and then make a supply roll.
      </p>
      <p>
        The adventurer's pack can be stocked to any supply level at a matching
        cost (i.e., a pack stocked with d8 supplies has a modest cost). Change
        the pack's Supplies tag as needed.
      </p>
      <p>
        <b>Cost:</b> Poor
      </p>
      <p>
        <b>Tags:</b> d6 Supplies
      </p>
      <hr />
      <h2>Esael Root</h2>
      <p>
        Consume one Esael root to recharge a spell or enchantment by one level.
        You can keep Esael root in a spellcrafting kit and it won't impact the
        kit's supply.
      </p>
      <p>
        <b>Cost:</b> Modest
      </p>
      <hr />
      <h2>Health Potion</h2>
      <p>
        Consume a health potion to heal your physical character damage by the
        potion's level. You cannot heal injury damage with a health potion.
      </p>
      <p>
        <b>Cost:</b> The cost of the health potion is equal to the desired level
        (i.e., a level d8 potion has a modest cost).
      </p>
      <p>
        <b>Tags:</b> Level d#
      </p>
      <hr />
      <h2>Spellcrafting Kit</h2>
      <p>
        The spellcrafting kit contains Esael parchment and all the supplies
        needed to turn ingredients into a potent spell. When you craft a spell,
        consume ingredients and make a supply roll for the spellcrafting kit.
        You can keep the ingredients you've collected in your supply kit and
        they don't impact the kit's supply.
      </p>
      <p>
        The spellcrafting kit can be stocked to any supply level at a matching
        cost (i.e., a kit stocked with d8 supplies has a modest cost). Change
        the kit's Supplies tag as needed.
      </p>
      <p>
        <b>Cost:</b> Modest
      </p>
      <p>
        <b>Tags:</b> d6 Supplies
      </p>
      <hr />
    </>
  );
}

export default ItemsList;
