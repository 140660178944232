import Section from "../../../components/Section";

function MagicSchools() {
  return (
    <>
      <Section>
        <h1 className="title-header">The Dsiciplines of Magic</h1>
        <p>
          All plants on Nilihaad are categorized based on their main effect.
          These categorizations have become the basis of Nilihaad's disciplines
          of magic. Institutes throughout the Higzaar continent specialize in
          certain combinations of discipline and provide educations in the
          crafting of spells. There are seven disciplines of magic in the world,
          each specializing in a specific type of spellcasting.
        </p>
        <h2>Evocation</h2>
        <p>
          Evocation is the art of harnessing and manipulating raw elemental
          energies to create powerful and destructive magical effects.
          Practitioners of Evocation, known as Evokers, wield the forces of
          fire, lightning, ice, and more, channeling these energies to shape
          reality itself. Evocation spells are renowned for their immediate and
          potent impact, making Evokers formidable offensive spellcasters.
        </p>
        <h2>Illusion</h2>
        <p>
          Illusion is the captivating art of bending perception, creating
          phantasmal constructs, and deceiving the senses to craft intricate
          illusions that blur the lines between reality and fantasy.
          Illusionists, the masters of this enigmatic discipline, manipulate
          light, sound, and even thought to create vivid and convincing
          illusions that can mystify, confuse, and beguile.
        </p>
        <h2>Divination</h2>
        <p>
          Divination is the ancient and mystical art of seeking hidden
          knowledge, glimpsing into the future, and uncovering the secrets of
          the universe through magical means. Diviners are practitioners who
          possess a unique connection to the fabric of time and space, allowing
          them to perceive events beyond the present moment and to unveil truths
          that are shrouded in mystery.
        </p>
        <h2>Abjuration</h2>
        <p>
          The protective school of magic employs physical resitances and
          countermagic to the other disciplines. Students of abjuration learn to
          create arcane armor, dispelling charms, and protective wards.
        </p>
        <h2>Conjuration</h2>
        <p>
          Conjuration is the mystical art of summoning, creating, and
          controlling beings, objects, and energies from elsewhere.
          Practitioners of Conjuration, known as Conjurers, have the remarkable
          ability to bridge the gap between worlds, channeling magical energies
          to manifest allies, creatures, and objects that serve their will.
        </p>
        <h2>Necromancy</h2>
        <p>
          Necromancy is the intricate and multifaceted art of harnessing the
          energies of life and death, wielding them to both mend and manipulate
          the vital forces that course through the living world. Practitioners
          of Necromancy, known as Necromancers, possess a unique mastery over
          the delicate balance of mortality, enabling them to mend wounds,
          commune with spirits, and command the very essence of existence.
        </p>
        <h2>Transmutation</h2>
        <p>
          The Transmutation discipline encompasses the profound art of altering
          and reshaping the fundamental nature of objects, substances, and
          reality itself. Spellcasters versed in Transmutation wield the power
          to transmute matter, change forms, and manipulate the inherent
          properties of the world around them.
        </p>
      </Section>
    </>
  );
}

export default MagicSchools;
