import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navigation from "./components/Navbar";
import { onAuthStateChanged } from "firebase/auth";
import { auth, usersCol } from "./firebaseSetup";
import { UserDataContext, UserPrefsContext } from "./hooks/UserContext";
import ScrollToTop from "./components/ScrollToTop";
import { doc, getDoc } from "firebase/firestore";
import { EmberbrandContext } from "./hooks/EmberbrandContext";
import EbCharacterSheetRoutes from "./routes/emberbrand/EbCharacterSheetRoutes";
import EbRulebookRoutes from "./routes/emberbrand/rulebook/EbRulebookRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import CampaignRoutes from "./routes/emberbrand/CampaignRoutes";
import LwRoutes from "./routes/leviathansWake/LwRoutes";

function App() {
  const [userPrefs, setUserPrefs] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        // add user Firestore document to user context
        const userDocRef = doc(usersCol, user.uid);
        getDoc(userDocRef).then(doc => {
          setUserPrefs(doc.data());
        });
      } else {
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("email");
      }
    });
  }, []);

  useEffect(() => {
    if (userPrefs !== null) {
      document.body.setAttribute("data-bs-theme", userPrefs?.theme);
      localStorage.setItem("theme", userPrefs?.theme);
    } else {
      document.body.setAttribute("data-bs-theme", "light");
    }
  }, [userPrefs]);

  return (
    <EmberbrandContext>
      <UserDataContext>
        <UserPrefsContext.Provider value={{ userPrefs, setUserPrefs }}>
          <Router>
            <Navigation />
            <Routes>
              <Route exact path="/" element={<Home />} />
              {AdminRoutes}

              {/* Emberbrand Routes */}
              <Route path="emberbrand">
                {EbRulebookRoutes}
                {EbCharacterSheetRoutes}
                {CampaignRoutes}
              </Route>

              {/* Leviathan's Wake Routes */}
              {LwRoutes}
            </Routes>
            <ScrollToTop />
          </Router>
        </UserPrefsContext.Provider>
      </UserDataContext>
    </EmberbrandContext>
  );
}

export default App;
