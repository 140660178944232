import LwCharacterSheetRoutes from "./LwCharacterSheetRoutes";
import CreateLwCharacter from "../../pages/LeviathansWake/CreateLwCharacter";
import { Route } from "react-router-dom";
import LwAdminRoutes from "./LwAdminRoutes";
import LwRulebookRoutes from "./rulebook/LwRulebookRoutes";

export default (
  <Route path="leviathans-wake">
    <Route path="create-new-lw-character" element={<CreateLwCharacter />} />
    {LwRulebookRoutes}
    {LwAdminRoutes}
    {LwCharacterSheetRoutes}
  </Route>
);
