import ExampleSection from "../../components/ExampleSection";
import Section from "../../components/Section";
import SkillMatrixReadOnly from "../../components/SkillMatrixReadOnly";

function CharacterSkills() {
  return (
    <>
      <Section>
        <h1 className="title-header">Starting Skills</h1>
        <p>
          Your skills define your strengths and weaknesses across a multitude of
          avenues. Every time you perform an action which requires a skill
          check, you'll refer to your skill matrix and figure your chance of
          success from among the fifteen skills.
        </p>
        <h2>The Skill Matrix</h2>
        <SkillMatrixReadOnly />
        <p>
          The Skill Matrix is the most important part of your character. It
          determines how well you will be able to complete tasks and overcome
          challenges. The Skill Matrix is made up of attributes and
          proficiencies, which combine to create skill percentages for each type
          of skill check your character could make. Assigning scores to your
          attributes and proficiencies will build your character's Skill Matrix.
        </p>
        <h2>Attributes</h2>
        <p>
          Assign one attribute a score of 30 and the other two a score of 20.
          These are your starting attribute scores.{" "}
          <b>An attribute may never have a score greater than 40.</b>
        </p>
        <p>
          Your character's attributes are the essence of your character. These
          are their Body, Mind, and Spirit.
        </p>
        <ul>
          <li>
            The <b>Body</b> attribute is the physical makeup of your character.
            How lean or overweight, how slender or muscular, how athletic or
            torpid your character is reflects their Body attribute.
          </li>
          <li>
            The <b>Mind</b> attribute is your character's mental ability. Your
            character's education, understanding of the world, and ability to
            tinker are enhanced by their Mind attribute.
          </li>
          <li>
            The <b>Spirit</b> attribute is your character's connection to
            themselves, those around them, and the world. The underlying
            subconscious senses and grounding fibers that give your character
            their place in the world comes from their Spirit attribute.
          </li>
        </ul>
        <h2>Proficiencies</h2>
        <p>
          Assign one proficiency a score of 40, two proficiencies a score of 30,
          and two proficiencies a score of 20. These are your starting
          proficiency scores.{" "}
          <b>A proficiency may never have a score greater than 50.</b>
        </p>
        <p>
          Your character's proficiencies build upon their foundation and define
          who they are. Every proficiency spans across each of your character's
          attributes, representing your character's ability to tap into each
          proficiency in all aspects of their being. These proficiencies are
          Power, Prowess, Charisma, Wisdom, and Constitution.
        </p>
        <ul>
          <li>
            The <b>Power</b> proficiency represents your character's sheer power
            of might, thought, and fortune.
          </li>
          <li>
            The <b>Prowess</b> proficiency represents your character's finesse,
            their ability to work with their hands, and their expertise in
            navigating the world around them.
          </li>
          <li>
            The <b>Charisma</b> proficiency represents your character's social
            standing with regard to their ability to influence, convince, and
            boost themselves and those around them.
          </li>
          <li>
            The <b>Wisdom</b> proficiency represents your character's attention
            to detail, intelligence, and understanding of their environment.
          </li>
          <li>
            The <b>Constitution</b> proficiency represents your character's will
            to live, to fight, and to thrive against their trials.
          </li>
        </ul>
        <p>
          Each Attribute meets with each Proficiency on the Skill Matrix to
          create a skill at the point they intersect. The way in which your
          character's Power combines with their Body is entirely different than
          the way it combines with their Mind or their Spirit. Every one of
          these combinations provides a unique way your character interacts with
          their world and the challenges they must overcome.
        </p>
        <ExampleSection>
          <h2>Point-Buy Skill Matrix</h2>
          <p>
            If you would like more granularity in your character's attributes
            and proficiencies, you may choose to use a point-buy system instead
            of following the preset score assignments. If you create your
            character this way, you get 70 points to spend on your attribute
            scores and 140 points to spend on your proficiency scores. You can
            spend these points however you wish, but no score may be less than
            10 or greater than 40.
          </p>
        </ExampleSection>
      </Section>
    </>
  );
}

export default CharacterSkills;
