import React from "react";
import { Route } from "react-router-dom";
import LwAdminTools from "../../pages/leviathansWakeAdmin/LwAdminTools";
import LwTalentCreator from "../../pages/leviathansWakeAdmin/LwTalentCreator";

export default (
  <Route path="lw-admin" element={<LwAdminTools />}>
    <Route path="talent-creator" element={<LwTalentCreator />} />
    <Route index element={<LwTalentCreator />} />
  </Route>
);
