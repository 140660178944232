import ExampleSection from "../../../components/ExampleSection";
import Section from "../../../components/Section";
import { Link } from "react-router-dom";

function Wealth() {
  return (
    <Section>
      <h1 className="title-header">Wealth</h1>
      <h2>Wealth Levels</h2>
      <p>
        In Emberbrand, the concept of wealth and the money you have on hand is
        abstracted into different levels which represent your character's
        financial status. The wealth levels are:
      </p>
      <ul>
        <li>Destitute - 0</li>
        <li>Squalid - d4</li>
        <li>Poor - d6</li>
        <li>Modest - d8</li>
        <li>Comfortable - d10</li>
        <li>Wealthy - d12</li>
        <li>Aristocratic - d20</li>
      </ul>
      <h2>Wealth progression</h2>
      <p>
        Wealth progression is a measure of your character's financial growth
        without counting individual coins. Your progression increases as you
        earn money by completing jobs, undertaking quests, looting enemies, or
        finding treasure.
      </p>
      <p>
        Whenever you gain money, the Storyteller will instruct you to add some
        number of points to your wealth progression. When your progression
        equals the maximum value of your current wealth level dice level, you
        advance to the next wealth level and increase your dice level, then
        reset your new level's wealth progression to 0.
      </p>
      <ExampleSection>
        <p>
          Currently at the "modest" wealth level, you have 7 wealth progression
          points. You return to the inn, where you show proof to your
          quest-giver that you accomplished your task and the Storyteller
          rewards you by instructing you to add 1 to your wealth progression. As
          your wealth level's dice level is d8, your progression has reached the
          die's maximum value and you get to advance from "modest" to
          "comfortable," increasing your wealth dice level to d10 and resetting
          your progression to 0.
        </p>
      </ExampleSection>
      <h2>Cost Levels and Purchasing Items</h2>
      <p>
        Goods and services in Emberbrand are categorized into cost levels that
        correspond with the wealth levels. These cost levels determine an item's
        affordability. If your wealth level is less than an item's cost, you
        can't afford it.
      </p>
      <p>
        If your wealth level equals the cost, you can afford the item or
        service, and you'll make a{" "}
        <Link to="/rules/supply-roll" className="link">
          supply roll
        </Link>{" "}
        to complete your purchase. Subtract 1 from your roll for each item
        included in your purchase, or as instructed by the Storyteller. For
        example, if you wish you buy 3 items with a cost of "poor", you must
        have a wealth level of "poor" or better, and subtract 3 from your supply
        roll.
      </p>
      <p>
        After applying modifiers to your wealth roll, if the result is less than
        2, you must decrease your wealth progression by the difference. If you
        can't, you drop a wealth level and reduce your wealth dice level,
        resetting your progression to 0.
      </p>
      <ExampleSection>
        <p>
          Your wealth roll result can go negative, in which case you may need to
          decrease your wealth progression by quite a bit. Be wary of such large
          purchases!
        </p>
      </ExampleSection>
      <p>
        If you purchase items of different cost levels, your wealth level must
        equal or exceed the greatest cost among all items. You still apply a
        modifier based on the number of items you buy as normal.
      </p>
      <h2>Selling Items</h2>
      <p>
        When you sell normal, common items, you add to your wealth progression
        based on the item's cost.
      </p>
      <ul>
        <li>Squalid: +1</li>
        <li>Poor: +2</li>
        <li>Modest: +3</li>
        <li>Comfortable: +4</li>
        <li>Wealthy: +5</li>
        <li>Aristocratic +6</li>
      </ul>
      <p>
        The Storyteller will determine the value of uncommon or magical items
        you sell.
      </p>
    </Section>
  );
}

export default Wealth;
