import { Accordion, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useState, useContext } from "react";
import { LwCharacterDataContext } from "../../../hooks/leviathansWake/LwContext";
import { AutoSaveLwCharacter } from "../../../backend/AutoSaveCharacter";
import { HiPencilSquare } from "react-icons/hi2";
import QuillEditor from "../../QuillEditor";
import ProgressClock from "../ProgressClock";
import { useAutosave } from "react-autosave";

function updatePursuitData(data) {
  AutoSaveLwCharacter(data);
}

/**
 * Represents a pursuit in Leviathan's Wake.
 *
 * @param {string} pursuitId - The ID of the pursuit.
 * @param {string} pursuitName - The name of the pursuit.
 * @param {number} challengeLevel - The challenge level of the pursuit.
 * @param {number} progress - The progress of the pursuit.
 * @returns {JSX.Element} The rendered pursuit component.
 */
function Pursuit({ pursuitId, pursuitName, challengeLevel }) {
  const { lwCurrentCharacter, setLwCurrentCharacter } = useContext(
    LwCharacterDataContext
  );
  const [showEditModal, setShowEditModal] = useState(false);
  const [name, setName] = useState(pursuitName);
  const [editPursuitName, setEditPursuitName] = useState("");
  const [chosenChallenge, setChosenChallenge] = useState("");

  const pursuitKey = Object.keys(lwCurrentCharacter?.pursuits).find(
    key => lwCurrentCharacter?.pursuits[key].id === pursuitId
  );

  useAutosave({ data: lwCurrentCharacter, onSave: updatePursuitData });

  const handleShowEditModal = () => setShowEditModal(true);
  const handleCloseEditModal = () => setShowEditModal(false);

  const handlesetEditPursuitName = e => {
    setEditPursuitName(e.target.value);
  };

  const handleChange = e => {
    setChosenChallenge(e.target.value);
  };

  const handleEditPursuit = () => {
    console.log(
      `Setting pursuit: ${editPursuitName} with challenge level ${chosenChallenge}`
    );
    handleCloseEditModal();

    // Update react array state
    const updatedPursuits = lwCurrentCharacter.pursuits.map(pursuit => {
      if (pursuit.id === pursuitId) {
        // If the pursuit name has been changed, update the pursuit name in the state
        if (editPursuitName !== null && editPursuitName !== "") {
          pursuit.name = editPursuitName;
          setName(editPursuitName);

          handleSavePursuitName();
        }

        // If the pursuit challenge level has been changed, update the pursuit challenge level in the state
        if (chosenChallenge !== null && chosenChallenge !== "") {
          pursuit.challenge = chosenChallenge;
          setChosenChallenge(chosenChallenge);

          handleSavePursuitChallenge();
        }
      }
      return pursuit;
    });

    setLwCurrentCharacter(prevState => ({
      ...prevState,
      pursuits: updatedPursuits,
    }));
  };

  const handleSavePursuitName = () => {
    console.log(`Saving pursuit name: ${editPursuitName}`);
  };

  const handleSavePursuitChallenge = () => {
    console.log(`Saving pursuit challenge level: ${chosenChallenge}`);
  };

  const handleSetProgress = (e, pursuitId, boxNum) => {
    let currentProgress =
      lwCurrentCharacter?.pursuits[pursuitKey]?.progress[boxNum];

    const challengeMax = {
      Troublesome: 2,
      Dangerous: 4,
      Perilous: 6,
      Legendary: 8,
    };

    // If progress is less than the max for the challenge level, increment progress by 1
    if (currentProgress < challengeMax[challengeLevel]) {
      currentProgress += 1;
    } else {
      currentProgress = 0;
    }

    // Update react array state
    const pursuitIndex = lwCurrentCharacter.pursuits.findIndex(
      pursuit => pursuit.id === pursuitId
    );
    const pursuit = lwCurrentCharacter.pursuits[pursuitIndex];
    pursuit.progress[boxNum] = currentProgress;

    setLwCurrentCharacter(prevValue => {
      const updatedPursuits = [...prevValue.pursuits];
      updatedPursuits[pursuitIndex] = pursuit;
      return { ...prevValue, pursuits: updatedPursuits };
    });
  };

  const handleDeletePursuit = () => {
    console.log(`Deleting pursuit with ID: ${pursuitId}`);

    // Get the pursuit from Firestore
    const charId = localStorage.getItem("currentLwCharacterId");

    // Update react array state
    const filteredPursuits = lwCurrentCharacter.pursuits.filter(
      pursuit => pursuit.id !== pursuitId
    );

    setLwCurrentCharacter(prevState => ({
      ...prevState,
      pursuits: filteredPursuits,
    }));

    handleCloseEditModal();
  };

  const handleSetPursuitNotes = (e, pursuitId) => {
    const pursuitKey = Object.keys(lwCurrentCharacter.pursuits).find(
      key => lwCurrentCharacter.pursuits[key].id === pursuitId
    );

    console.log(`Setting notes for pursuit ${pursuitId}: ${e}`);

    const updatedPursuitList = Object.assign([...lwCurrentCharacter.pursuits], {
      [pursuitKey]: { ...lwCurrentCharacter.pursuits[pursuitKey], notes: e },
    });

    setLwCurrentCharacter(prevState => ({
      ...prevState,
      pursuits: updatedPursuitList,
    }));
  };

  return (
    <>
      <Col id={`pursuit-${pursuitId}`}>
        <Button
          className="pursuit-header"
          variant="link"
          onClick={handleShowEditModal}
        >
          <h4>
            {name} <HiPencilSquare />
          </h4>
        </Button>
        <p>Challenge Level: {challengeLevel}</p>
        <Row className="mt-3 mb-3">
          {lwCurrentCharacter?.pursuits[pursuitKey]?.progress.map(
            (progress, index) => {
              return (
                <Col key={index} xs={2} md={1} className="mb-3">
                  <ProgressClock
                    key={index}
                    clockId={pursuitId}
                    challenge={challengeLevel}
                    progress={progress}
                    click={() => handleSetProgress(progress, pursuitId, index)}
                  />
                </Col>
              );
            }
          )}
        </Row>
        <Row>
          {
            // Pursuit notes accordion
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Notes</Accordion.Header>
                <Accordion.Body>
                  <QuillEditor
                    id={`pursuit-${pursuitId}-notes`}
                    value={lwCurrentCharacter.pursuits[pursuitKey]?.notes}
                    defaultPlaceholder="Pursuit notes..."
                    change={e => handleSetPursuitNotes(e, pursuitId)}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          }
        </Row>
      </Col>

      {/* Edit pursuit modal */}
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Pursuit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <input
              type="text"
              id="edit-pursuit"
              name="edit-pursuit"
              defaultValue={pursuitName}
              className="form-control"
              onChange={handlesetEditPursuitName}
            />
            <h4>Challenge Level</h4>
            <Form.Group controlId="edit-pursuit-challenge-level">
              <Form.Check
                type="radio"
                name="challenge"
                label="Troublesome"
                value="Troublesome"
                defaultChecked={challengeLevel === "Troublesome"}
                onChange={handleChange}
              />
              <Form.Check
                type="radio"
                name="challenge"
                label="Dangerous"
                value="Dangerous"
                defaultChecked={challengeLevel === "Dangerous"}
                onChange={handleChange}
              />
              <Form.Check
                type="radio"
                name="challenge"
                label="Perilous"
                value="Perilous"
                defaultChecked={challengeLevel === "Perilous"}
                onChange={handleChange}
              />
              <Form.Check
                type="radio"
                name="challenge"
                label="Legendary"
                value="Legendary"
                defaultChecked={challengeLevel === "Legendary"}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeletePursuit}>
            Delete
          </Button>
          <Button variant="primary" onClick={handleEditPursuit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Pursuit;
