import { Button, Col, Row, Table } from "react-bootstrap";
import Section from "../Section";
import { useEffect, useState } from "react";
import { BsFillTrash3Fill } from "react-icons/bs";
import { enemiesCol } from "../../firebaseSetup";
import { addDoc, doc, updateDoc } from "firebase/firestore";
import ExampleSection from "../ExampleSection";
import { LoadEnemyButton } from "../../hooks/LoadEnemyEdit";
import QuillEditor from "../QuillEditor";

function EnemyCreator() {
  const [loadedEnemy, setLoadedEnemy] = useState(null);
  const [enemyName, setEnemyName] = useState("New Enemy");
  const [enemyKeywords, setEnemyKeywords] = useState("Type, Subtype, Class");
  const [enemyHealth, setEnemyHealth] = useState("10");
  const [enemyArmor, setEnemyArmor] = useState("");
  const [enemyBody, setEnemyBody] = useState("50");
  const [enemyMind, setEnemyMind] = useState("50");
  const [enemySpirit, setEnemySpirit] = useState("50");
  const [enemyStatSpecial, setEnemyStatSpecial] = useState("");
  const [enemyWeaponsSpecial, setEnemyWeaponsSpecial] = useState("");
  const [enemyWeapons, setEnemyWeapons] = useState([
    { id: 0, name: "Weapon Name", damage: "Xd#", tags: "Tags" },
  ]);

  useEffect(() => {
    if (loadedEnemy) {
      setEnemyName(loadedEnemy?.name);
      setEnemyKeywords(loadedEnemy?.keywords);
      setEnemyHealth(loadedEnemy?.health);
      setEnemyArmor(loadedEnemy?.armor);
      setEnemyBody(loadedEnemy?.body);
      setEnemyMind(loadedEnemy?.mind);
      setEnemySpirit(loadedEnemy?.spirit);
      setEnemyStatSpecial(loadedEnemy?.statSpecial);
      setEnemyWeaponsSpecial(loadedEnemy?.weaponsSpecial);
      setEnemyWeapons(loadedEnemy?.weapons);

      console.log(`Loading existing enemy: ${loadedEnemy?.id}`);
    }
  }, [loadedEnemy]);

  const handleSetEnemyName = e => {
    setEnemyName(e.target.value);
  };

  const handleSetEnemyKeywords = e => {
    setEnemyKeywords(e.target.value);
  };

  const handleSetEnemyHealth = e => {
    setEnemyHealth(e.target.value);
  };

  const handleSetEnemyArmor = e => {
    setEnemyArmor(e.target.value);
  };

  const handleSetEnemyBody = e => {
    setEnemyBody(e.target.value);
  };

  const handleSetEnemyMind = e => {
    setEnemyMind(e.target.value);
  };

  const handleSetEnemySpirit = e => {
    setEnemySpirit(e.target.value);
  };

  const handleSetEnemyStatSpecial = e => {
    setEnemyStatSpecial(e);
  };

  const handleSetEnemyWeaponsSpecial = e => {
    setEnemyWeaponsSpecial(e);
  };

  const handleAddWeapon = () => {
    const newWeapon = {
      id: enemyWeapons.length + 1,
      name: "Weapon Name",
      damage: "Xd#",
      tags: "Tags",
    };

    setEnemyWeapons([...enemyWeapons, newWeapon]);
  };

  const handleRemoveWeapon = index => {
    setEnemyWeapons(prevWeapons => {
      // Remove weapon and return updated weapons array
      const updatedWeapons = [...prevWeapons];
      updatedWeapons.splice(index, 1);
      return updatedWeapons;
    });
  };

  const handleSetEnemyWeapons = (newValue, index, property) => {
    setEnemyWeapons(prevWeapons => {
      return prevWeapons.map((weapon, i) => {
        // Update the specific property of the weapon
        if (i === index) {
          return { ...weapon, [property]: newValue };
        }
        // Return other weapons unchanged
        return weapon;
      });
    });
  };

  const handleSaveExistingEnemy = () => {
    const enemyId = loadedEnemy?.id;
    const enemyDocRef = doc(enemiesCol, enemyId);

    try {
      updateDoc(enemyDocRef, {
        name: enemyName,
        keywords: enemyKeywords,
        health: enemyHealth,
        armor: enemyArmor,
        body: enemyBody,
        mind: enemyMind,
        spirit: enemySpirit,
        statSpecial: enemyStatSpecial,
        weapons: enemyWeapons,
        weaponsSpecial: enemyWeaponsSpecial,
      }).then(fulfilled => {
        alert(`${enemyName} updated.`);
      });
    } catch (error) {
      alert(error);
    }

    console.log(`Saving existing enemy: ${loadedEnemy?.name}`);
  };

  const handleSaveNewEnemy = () => {
    try {
      addDoc(enemiesCol, {
        name: enemyName,
        keywords: enemyKeywords,
        health: enemyHealth,
        armor: enemyArmor,
        body: enemyBody,
        mind: enemyMind,
        spirit: enemySpirit,
        statSpecial: enemyStatSpecial,
        weapons: enemyWeapons,
        weaponsSpecial: enemyWeaponsSpecial,
      }).then(doc => {
        alert(`${doc.id} created successfully`);
      });
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <Section>
        <h1 className="title-header">Enemy Creator</h1>
        <LoadEnemyButton setLoadedEnemy={setLoadedEnemy} />
        <Row>
          <Col md={4}>
            <h2>
              Name <span className="text-danger">*</span>
            </h2>
            <input
              id="enemy-name"
              className="data"
              value={enemyName}
              onChange={handleSetEnemyName}
            />
          </Col>
          <Col md={4}>
            <h2>
              Keywords <span className="text-danger">*</span>
            </h2>
            <input
              id="enemy-keywords"
              className="data"
              value={enemyKeywords}
              onChange={handleSetEnemyKeywords}
            />
          </Col>
          <Col md={4}>
            <h2>
              Health <span className="text-danger">*</span>
            </h2>
            <input
              id="enemy-health"
              className="data"
              value={enemyHealth}
              onChange={handleSetEnemyHealth}
            />
          </Col>
        </Row>
        <Table striped>
          <tbody>
            <tr>
              <th>Armor (optional)</th>
              <td>
                <input
                  id="enemy-armor"
                  value={enemyArmor}
                  onChange={handleSetEnemyArmor}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Body%</b>
                <br />
                <sub>STR, AGI, PRE, INV, RES</sub>
              </td>
              <td>
                <input
                  id="enemy-body"
                  value={enemyBody}
                  onChange={handleSetEnemyBody}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Mind%</b>
                <br />
                <sub>FOC, DEX, CUN, KND, WIL</sub>
              </td>
              <td>
                <input
                  id="enemy-mind"
                  value={enemyMind}
                  onChange={handleSetEnemyMind}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Spirit%</b>
                <br />
                <sub>LCK, SVL, MLE, IST, END</sub>
              </td>
              <td>
                <input
                  id="enemy-spirit"
                  value={enemySpirit}
                  onChange={handleSetEnemySpirit}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <h3>Stats Special Rules</h3>
        <QuillEditor
          id="enemy-stat-special"
          value={enemyStatSpecial}
          change={handleSetEnemyStatSpecial}
        />
        <div style={{ marginBottom: "30px" }} />
        <Table striped responsive>
          <thead className="text-left">
            <tr>
              <th>Weapon</th>
              <th>Damage</th>
              <th>Tags</th>
              <td></td>
            </tr>
          </thead>
          <tbody id="enemy-weapons">
            {enemyWeapons.map((weapon, index) => (
              <tr key={weapon.id} className="text-left">
                <td>
                  <input
                    value={weapon.name}
                    onChange={e =>
                      handleSetEnemyWeapons(e.target.value, index, "name")
                    }
                  />
                </td>
                <td>
                  <input
                    value={weapon.damage}
                    onChange={e =>
                      handleSetEnemyWeapons(e.target.value, index, "damage")
                    }
                  />
                </td>
                <td>
                  <input
                    value={weapon.tags}
                    onChange={e =>
                      handleSetEnemyWeapons(e.target.value, index, "tags")
                    }
                  />
                </td>
                <td>
                  <Button
                    className="btn-secondary"
                    onClick={() => handleRemoveWeapon(index)}
                  >
                    <BsFillTrash3Fill />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button onClick={handleAddWeapon} style={{ marginBottom: "30px" }}>
          Add Weapon
        </Button>
        <h3>Weapons Special Rules</h3>
        <QuillEditor
          id="enemy-weapons-special"
          value={enemyWeaponsSpecial}
          change={handleSetEnemyWeaponsSpecial}
        />
        <div style={{ marginBottom: "30px" }} />
      </Section>
      <Section>
        <h1 className="title-header">Preview Enemy</h1>
        <div className="enemy">
          <h2>{enemyName}</h2>
          <p>
            <i>{enemyKeywords}</i>
          </p>
          <h3>Health</h3>
          <input
            id="preview-enemy-health"
            value={enemyHealth}
            readOnly
            size={6}
          />
          <Table className="text-left" striped>
            <tbody>
              {enemyArmor ? (
                <tr>
                  <th>Armor</th>
                  <td>{enemyArmor}</td>
                </tr>
              ) : null}
              <tr>
                <td>
                  <b>Body</b>
                  <br />
                  <sub>STR, AGI, PRE, INV, RES</sub>
                </td>
                <td>{enemyBody}%</td>
              </tr>
              <tr>
                <td>
                  <b>Mind</b>
                  <br />
                  <sub>FOC, DEX, CUN, KND, WIL</sub>
                </td>
                <td>{enemyMind}%</td>
              </tr>
              <tr>
                <td>
                  <b>Spirit</b>
                  <br />
                  <sub>LCK, SVL, MLE, IST, END</sub>
                </td>
                <td>{enemySpirit}%</td>
              </tr>
            </tbody>
          </Table>
          {enemyStatSpecial &&
          enemyStatSpecial !== "<p><br></p>" &&
          enemyStatSpecial !== "<h2><br></h2>" ? (
            <ExampleSection>
              <div
                className="text-left"
                dangerouslySetInnerHTML={{ __html: enemyStatSpecial }}
              />
            </ExampleSection>
          ) : null}

          <Table striped responsive>
            <thead className="text-left">
              <tr>
                <th>Weapon</th>
                <th>Damage</th>
                <th>Tags</th>
              </tr>
            </thead>
            <tbody>
              {enemyWeapons.map((weapon, index) => (
                <tr key={weapon.id} className="text-left">
                  <td>{weapon.name}</td>
                  <td>{weapon.damage}</td>
                  <td>{weapon.tags}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          {enemyWeaponsSpecial &&
          enemyWeaponsSpecial !== "<p><br></p>" &&
          enemyWeaponsSpecial !== "<h2><br></h2>" ? (
            <ExampleSection>
              <div
                className="text-left"
                dangerouslySetInnerHTML={{ __html: enemyWeaponsSpecial }}
              />
            </ExampleSection>
          ) : null}
        </div>

        <Row>
          <Col xs={6}>
            <Button onClick={handleSaveExistingEnemy}>
              Save Existing Enemy
            </Button>
          </Col>
          <Col xs={6}>
            <Button onClick={handleSaveNewEnemy}>Create Enemy</Button>
          </Col>
        </Row>
      </Section>
      <Section>
        <h1 className="title-header">How to Create an Enemy</h1>
        <div className="text-left">
          <h2>Description & Tags</h2>
          <p>
            When creating an enemy, start with a description. What does the
            enemy look like? How does the enemy behave? What makes the enemy
            unique?
          </p>
          <p>
            Use this description to give the enemy tags. Tags are used to
            describe an enemy as humanoid, animal, or even otherworldly; they
            express how large the enemy is, how dangerous its attacks are, and
            how it interacts with other characters during combat.
          </p>
          <p>
            The first tag you give your enemy should be its type. Is the enemy
            similar to a human? Is it a beast or a serpent or a horror?
          </p>
          <ExampleSection>
            <h4>Examples of Type Tags</h4>
            <p>
              <i>Humanoid, Beast, Serpent, Insect, Vermin</i>
            </p>
          </ExampleSection>
          <p>
            If the enemy's size isn't similar to that of a normal human, it
            should also be given a size tag. You can use this tag to modify some
            of the enemy's stats if you wish. An enemy without a size tag is
            assumed to be around the size of a grown human.
          </p>
          <ul>
            <li>Tiny: Smaller than a house case. 0.5x health, -10 Body.</li>
            <li>Small: About the size of a halfling. -5 Body.</li>
            <li>Medium: The size of a normal adult human.</li>
            <li>Large: The size of a cart. 1.5x health, +5 Body.</li>
            <li>Huge: Much larger than a cart. 2x health, +10 Body.</li>
          </ul>
          <ExampleSection>
            <p>
              You can adjust size modifiers in any way you wish. You can even
              choose to ignore them completely. It's perfectly fine to create a
              goliath that's three times larger than a person who has the
              strength of a child, or a large ant with ten times the strength of
              a small creature.
            </p>
          </ExampleSection>
          <p>
            From these baseline tags, expand on your enemy with more descriptive
            tags, such as class or species. For example, a Bandit Leader and a
            Bandit are both separate enemies with the <i>Bandit</i> tag. The
            Bandit Leader has an ability which gives all friendly <i>bandits</i>{" "}
            advantage on Morale checks. This ability interacts with the{" "}
            <i>Bandit</i> tag in a mechanically significant way. Get creative
            with your tags and how they allow your enemies to interact with one
            another.
          </p>
          <hr />
          <h2>Attributes</h2>
          <p>
            Most enemies are simpler than player characters. A PC cares about
            the difference between their Agility and Dexterity when it comes to
            skill checks of acrobatics versus those of hand-eye coordination.
            This level of detail isn't important to enemies that will usually
            only take place during a single combat encounter. Instead, the three
            attributes, Body, Mind, and Spirit, are each given a score which
            applies to all skills that correspond to that attribute. An enemy
            with a Body score of 60 is considered to have that same score for
            their Strength, Agility, Presence, Investigation, and Resistance.
          </p>
          <p>
            When you create a brand new enemy, first assign each attribute a
            score of 50. From here, you can consider what the enemy should be
            good at and adjust scores accordingly.
          </p>
          <p>
            Decide what the enemy is known for and apply one or more of the
            following traits. Some traits offer additional tags that you can
            give the enemy, while others modify the enemy's health or
            attributes.
          </p>
          <ul>
            <li>Great strength: +5-15 Body.</li>
            <li>Great intelligence: +5-15 Mind.</li>
            <li>Great fortitude: +5-15 Spirit.</li>
            <li>
              Spells and magic: <i>Spellcaster</i>, +10 Mind.
            </li>
            <li>
              A particular viciousness: <i>Devastating</i>, +5-10 Body, +5-10
              Spirit.
            </li>
            <li>Elite training: +5-10 in all attributes.</li>
          </ul>
        </div>
      </Section>
    </>
  );
}

export default EnemyCreator;
