import { Col, Form, Row } from "react-bootstrap";
import NumberInput from "./NumberInput";
import { useContext, useRef } from "react";
import { CharacterDataContext } from "../../hooks/UserContext";
import { AutoSaveCharacter } from "../../backend/AutoSaveCharacter";
import Section from "../Section";
import ReferenceHeader from "../RulesReference/ReferenceHeader";
import { SupplyRollReference } from "../../pages/rules/basics/SupplyRoll";

function WealthSection() {
  const { currentCharacter, setCurrentCharacter } =
    useContext(CharacterDataContext);
  const autoSaveTimer = useRef(null);

  const handleSetCharacterData = id => {
    const value = document.getElementById(id).value;

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }
    setCurrentCharacter(prevValue => ({
      ...prevValue,
      [id]: value,
    }));

    autoSaveTimer.current = setTimeout(() => {
      AutoSaveCharacter(id, value);
    }, 1000);
  };

  const handleWealthChange = () => {
    handleSetCharacterData("currency");
  };
  return (
    <>
      <Section>
        <ReferenceHeader
          id="supply-help-text"
          title="Supply"
          defaultTab={<SupplyRollReference />}
          defaultEventKey="supply"
          tabs={[
            {
              id: "supply-tab",
              title: "Supply Roll",
              eventKey: "supply",
              content: <SupplyRollReference />,
            },
          ]}
        />
        <Row style={{ marginBottom: "30px" }}>
          <Col>
            <h2 className="text-primary">Supply Level</h2>
            <Form.Select
              id="currency"
              value={currentCharacter?.currency || ""}
              aria-label="Supply level select"
              onChange={handleWealthChange}
              // className="bg-white text-dark"
            >
              <option value={0}>Destitute - 0</option>
              <option value={1}>Squalid - 1d4</option>
              <option value={2}>Poor - 1d6</option>
              <option value={3}>Modest - 1d8</option>
              <option value={4}>Comfortable - 1d10</option>
              <option value={5}>Wealthy - 1d12</option>
              <option value={6}>Aristocratic - 1d20</option>
            </Form.Select>
          </Col>
          <Col>
            <h2 className="text-primary">Supply Progression</h2>
            <NumberInput
              id="currencyProgress"
              value={currentCharacter?.currencyProgress}
              justify={true}
              change={handleSetCharacterData}
            />
          </Col>
        </Row>
      </Section>
    </>
  );
}

export default WealthSection;
