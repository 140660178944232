import { useContext, useRef } from "react";
import Table from "react-bootstrap/Table";
import { CharacterDataContext } from "../../hooks/UserContext";
import { AutoSaveCharacter } from "../../backend/AutoSaveCharacter";
import SkillPercentage from "../../hooks/SkillPercentage";
import { charactersCol } from "../../firebaseSetup";

function SkillMatrix() {
  const { currentCharacter, setCurrentCharacter } =
    useContext(CharacterDataContext);
  const autoSaveTimer = useRef(null);

  const handleSetCharacterData = (e) => {
    if (currentCharacter !== null) {
      const id = e.target.getAttribute("id");
      const value = e.target.value;

      if (autoSaveTimer.current) {
        clearTimeout(autoSaveTimer.current);
      }
      setCurrentCharacter((prevValue) => ({
        ...prevValue,
        [id]: value,
      }));

      autoSaveTimer.current = setTimeout(() => {
        AutoSaveCharacter(charactersCol, id, value);
      }, 1000);
    }
  };

  return (
    <>
      <Table bordered className="text-center" size="sm">
        <thead>
          <tr>
            <th scope="col" className="table-title bg-primary">
              Skills
            </th>
            <th scope="col">
              Body
              <br />
              <input
                id="body"
                className="data"
                size={4}
                value={currentCharacter?.body || ""}
                onChange={handleSetCharacterData}
              />
            </th>
            <th scope="col">
              Mind
              <br />
              <input
                id="mind"
                className="data"
                size={4}
                value={currentCharacter?.mind || ""}
                onChange={handleSetCharacterData}
              />
            </th>
            <th scope="col">
              Spirit
              <br />
              <input
                id="spirit"
                className="data"
                size={4}
                value={currentCharacter?.spirit || ""}
                onChange={handleSetCharacterData}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              Power
              <br />
              <input
                id="power"
                className="data"
                size={4}
                value={currentCharacter?.power || ""}
                onChange={handleSetCharacterData}
              />
            </th>
            <td>
              Strength
              <br />
              <SkillPercentage
                id={"strength-span"}
                attribute={currentCharacter?.body}
                proficiency={currentCharacter?.power}
              />
            </td>
            <td>
              Focus
              <br />
              <SkillPercentage
                id={"focus-span"}
                attribute={currentCharacter?.mind}
                proficiency={currentCharacter?.power}
              />
            </td>
            <td>
              Luck
              <br />
              <SkillPercentage
                id={"luck-span"}
                attribute={currentCharacter?.spirit}
                proficiency={currentCharacter?.power}
              />
            </td>
          </tr>
          <tr>
            <th scope="row">
              Prowess
              <br />
              <input
                id="prowess"
                className="data"
                size={4}
                value={currentCharacter?.prowess || ""}
                onChange={handleSetCharacterData}
              />
            </th>
            <td>
              Agility
              <br />
              <SkillPercentage
                id={"agility-span"}
                attribute={currentCharacter?.body}
                proficiency={currentCharacter?.prowess}
              />
            </td>
            <td>
              Dexterity
              <br />
              <SkillPercentage
                id={"dexterity-span"}
                attribute={currentCharacter?.mind}
                proficiency={currentCharacter?.prowess}
              />
            </td>
            <td>
              Survival
              <br />
              <SkillPercentage
                id={"survival-span"}
                attribute={currentCharacter?.spirit}
                proficiency={currentCharacter?.prowess}
              />
            </td>
          </tr>
          <tr>
            <th scope="row">
              Charisma
              <br />
              <input
                id="charisma"
                className="data"
                size={4}
                value={currentCharacter?.charisma || ""}
                onChange={handleSetCharacterData}
              />
            </th>
            <td>
              Presence
              <br />
              <SkillPercentage
                id={"presence-span"}
                attribute={currentCharacter?.body}
                proficiency={currentCharacter?.charisma}
              />
            </td>
            <td>
              Cunning
              <br />
              <SkillPercentage
                id={"cunning-span"}
                attribute={currentCharacter?.mind}
                proficiency={currentCharacter?.charisma}
              />
            </td>
            <td>
              Morale
              <br />
              <SkillPercentage
                id={"morale-span"}
                attribute={currentCharacter?.spirit}
                proficiency={currentCharacter?.charisma}
              />
            </td>
          </tr>
          <tr>
            <th scope="row">
              Wisdom
              <br />
              <input
                id="wisdom"
                className="data"
                size={4}
                value={currentCharacter?.wisdom || ""}
                onChange={handleSetCharacterData}
              />
            </th>
            <td>
              Investigation
              <br />
              <SkillPercentage
                id={"investigation-span"}
                attribute={currentCharacter?.body}
                proficiency={currentCharacter?.wisdom}
              />
            </td>
            <td>
              Knowledge
              <br />
              <SkillPercentage
                id={"knowledge-span"}
                attribute={currentCharacter?.mind}
                proficiency={currentCharacter?.wisdom}
              />
            </td>
            <td>
              Instinct
              <br />
              <SkillPercentage
                id={"instinct-span"}
                attribute={currentCharacter?.spirit}
                proficiency={currentCharacter?.wisdom}
              />
            </td>
          </tr>
          <tr>
            <th scope="row">
              Constitution
              <br />
              <input
                id="constitution"
                className="data"
                size={4}
                value={currentCharacter?.constitution || ""}
                onChange={handleSetCharacterData}
              />
            </th>
            <td>
              Resistance
              <br />
              <SkillPercentage
                id={"resistance-span"}
                attribute={currentCharacter?.body}
                proficiency={currentCharacter?.constitution}
              />
            </td>
            <td>
              Willpower
              <br />
              <SkillPercentage
                id={"willpower-span"}
                attribute={currentCharacter?.mind}
                proficiency={currentCharacter?.constitution}
              />
            </td>
            <td>
              Endurance
              <br />
              <SkillPercentage
                id={"endurance-span"}
                attribute={currentCharacter?.spirit}
                proficiency={currentCharacter?.constitution}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

export default SkillMatrix;
