import Section from "../../../components/Section";
import ExampleSection from "../../../components/ExampleSection";

function Combat() {
  return (
    <>
      <Section>
        <h1 className="title-header">Initiating Combat</h1>
        <p>
          When combat is initiated, the Storyteller will describe the
          environment in which the fight is taking place and your distance from
          the other combatants. You'll use this information during the entirety
          of the battle to determine whether you are within the range
          requirement of your equipped weapons and whether the environment
          impacts your actions.
        </p>
        <ExampleSection>
          <p>
            The party had just finished a dangerous and exhausting hunt, where
            they gathered quite a bit of boar meat. Jericho is carrying the boar
            meat on his shoulders as he walks with Nym down the forest trail
            back toward town. Relwirn, Ardorim, and n'Eiruu'b'Ai are a little
            ways off from them scavenging for medicinal herbs.
          </p>
          <p>
            <b>Storyteller</b>: "You hear a laugh from behind you. You turn to
            see a group of four bandits emerge from the treeline. 'That's quite
            a bit of tasty looking boar meat you got there. Mind sharing?'"
          </p>
          <p>
            <b>Nym</b>: "We just spent all day hunting and cleaning these boars,
            go find your own meat."
          </p>
          <p>
            <b>Storyteller</b>: "'See I was hoping you'd just hand it over and
            be on your way,' the bandit replies with a nasty smile. 'Looks like
            we're gonna have to take it!' The bandits draw their crossbows and
            duck back behind the treeline."
          </p>
          <p>
            <b>Nym</b>: "I draw my weapon."
          </p>
          <p>
            <b>Jericho</b>: "I throw the boar meat I'm carrying at them."
          </p>
          <p>
            <b>Storyteller</b>: "Okay Jericho, that's going to be an attack.
            Make a Strength check."
          </p>
          <p>
            <b>Jericho</b>: "Whoops, looks like I failed with a couple banes.
            I'll spend them to completely miss and throw the boar meat near a
            tree."
          </p>
        </ExampleSection>
      </Section>
      <Section>
        <h1 className="title-header">The Flow of Combat</h1>
        <p>
          The flow of combat is managed by the Storyteller shifting focus
          between the players. The Storyteller will shift focus to you by
          presenting you with an enemy action. You then have an opportunity to
          respond to the Storyteller's action with an action of your own. Be
          sure to describe your response and the action you wish to take in
          detail to help the Storyteller paint a picture of the battle taking
          place.
        </p>
        <p>
          At any time during combat, you can also call out an action you would
          like to take, even if the Storyteller has not given you focus. Be sure
          you aren't unfairly shifting focus away from another player. You
          should always work with your team and show comradery in battle. The
          Storyteller will determine if the action you wish to perform is
          feasible under the circumstances and may respond with an action.
        </p>
        <p>
          While you have focus, you can try to take more than one action before
          focus shifts to another player. Beware however, that if you try to
          take too many actions, the Storyteller may impose penalties. You may
          be required to suffer exhaustion for pushing yourself too hard too
          quickly, you may leave yourself open to extra damage from an enemy's
          attack, or you may just fall flat on your face from exhaustion. The
          Storyteller always has the final say if they think you're taking more
          actions than make sense.
        </p>
        <ExampleSection>
          <h2>Basic Combat Flow</h2>
          <ol>
            <li>
              The Storyteller narrates the beginning of an NPC's action and
              shifts focus to a player.
            </li>
            <li>
              The player with focus responds to the Storyteller's action with an
              action of their own.
            </li>
            <li>The player makes a skill check as required.</li>
            <li>
              The Storyteller and player resolve their actions, applying any
              boons, banes, damage, and effects.
            </li>
          </ol>
        </ExampleSection>
      </Section>
    </>
  );
}

export default Combat;
