function WeaponTags() {
  return (
    <>
      <p>
        Weapon tags are keyword properties given to a weapon. Each tag helps
        descripte a weapon and provides a mechanical effect.
      </p>
      <p>
        <b>Ammo (Type):</b> This weapon may not be used for an attack unless you
        have the specified type of ammunition on hand. This tag is prepended
        with d# to denote your weapon's ammo capacity die. After you attack with
        this weapon, make a use roll to see if you've run out of ammunition.
      </p>
      <p>
        <b>Devastating:</b> This weapon deals its damage in a particularly
        destructive way, causing great damage or loss.
      </p>
      <p>
        <b>Finesse:</b> You may use your Dexterity instead of your Strength when
        attacking with this weapon.
      </p>
      <p>
        <b>Heavy:</b> This weapon must be wielded with two hands.
      </p>
      <p>
        <b>Light:</b> This weapon may be wielded with one hand.
      </p>
      <p>
        <b>Melee:</b> This weapon can only be used to attack an adjacent
        character.
      </p>
      <p>
        <b>Ranged (Range):</b> This weapon can be used to attack a character up
        to the maximum range specified. Increase the difficulty of your attack
        check based on your range from the target.
      </p>
      <p>
        <b>Pierce X:</b> Reduce the target's armor value by X for this attack.
      </p>
      <p>
        <b>Slow:</b> The weapon is slow and cumbersome to wield or requires
        reloading after use. It takes some time before the weapon can be used
        again.
      </p>
      <p>
        <b>Thrown:</b> This weapon may be thrown as a ranged weapon at short
        range.
      </p>
      <p>
        <b>Versatile:</b> You may wield this weapon with two hands. If you do,
        it becomes heavy and damage characteristic is increased by one dice
        level.
      </p>
    </>
  );
}

export default WeaponTags;
