import { auth } from "../firebaseSetup";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { UserContext } from "../hooks/UserContext";
import { Link } from "react-router-dom";

function SignIn() {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsername = (e) => setUsername(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);

  const handleLoginSubmit = () => {
    handleClose();
    signInWithEmailAndPassword(auth, username, password)
      .then((cred) => {
        console.log(cred.user.email + " logged in");
        setCurrentUser(cred.user);
      })
      .catch((err) => {
        var errorCode = err.code;

        if (errorCode === "auth/user-not-found") {
          alert("No account found with this email address.");
        } else if (errorCode === "auth/invalid-email") {
          alert("Please enter a valid email address.");
        } else if (errorCode === "auth/wrong-password") {
          alert("Incorrect email or password.");
        } else if (!password) {
          alert("Please enter your password.");
        } else {
          alert("Unable to log in.");
        }
      });
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      setCurrentUser(null);
    });
  };

  if (currentUser !== null) {
    return (
      <>
        {/* sign in button */}
        <NavDropdown
          title={currentUser.email}
          id="logout-dropdown"
          style={{ zIndex: 200 }}
        >
          <Nav.Link id="profile" as={Link} to="/my-profile" eventKey="0">
            My Profile
          </Nav.Link>
          <Nav.Link as={Link} to="/about" eventKey="2">
            About This Site
          </Nav.Link>
          {currentUser.uid === "y9tH4PZzyaRKF9f0XjMbrnaV4to2" ? (
            <AdminTools />
          ) : null}
          <Nav.Link id="loggedIn" onClick={handleLogout} eventKey="1">
            Sign Out
          </Nav.Link>
        </NavDropdown>
      </>
    );
  } else {
    return (
      <>
        <Nav.Item id="loggedOut">
          <Button variant="Link" onClick={handleShow}>
            Sign In
          </Button>
        </Nav.Item>

        {/* sign in modal */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Sign In</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-left" style={{ margin: "10px" }}>
            <Row>
              <input
                id="username"
                className="text-left"
                type="email"
                placeholder="Email"
                onChange={handleUsername}
              />
            </Row>
            <Row>
              <input
                id="password"
                className="text-left"
                type="password"
                placeholder="Password"
                onChange={handlePassword}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleLoginSubmit}>
              Sign In
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function AdminTools() {
  return (
    <>
      <Nav.Link id="admin-tools" as={Link} to="/admin-tools" eventKey="3">
        Admin Tools
      </Nav.Link>
      <Nav.Link
        id="database-migrations"
        as={Link}
        to="/database-migrations"
        eventKey="4"
      >
        Database Migrations
      </Nav.Link>
    </>
  );
}

export default SignIn;
