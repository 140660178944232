import { useContext, useState } from "react";
import { Button, Col, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { LwCharacterDataContext } from "../../../hooks/leviathansWake/LwContext";
import CriticalCondition from "../CriticalCondition";
import { FaPlus } from "react-icons/fa";
import ProgressClock from "../ProgressClock";
import { v4 as uuidv4 } from "uuid";
import PhysicalConditionsTable from "../../RulesReference/LeviathansWakeReference/PhysicalConditionsTable";
import MentalConditionsTable from "../../RulesReference/LeviathansWakeReference/MentalConditionsTable";

function CriticalConditionsSection() {
  const { lwCurrentCharacter, setLwCurrentCharacter } = useContext(
    LwCharacterDataContext
  );
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleAddCondition = condition => {
    const conditionId = uuidv4();

    // Update state
    setLwCurrentCharacter(prevValue => ({
      ...prevValue,
      criticalConditions: [
        ...(prevValue.criticalConditions || []),
        { ...condition, id: conditionId },
      ],
    }));

    handleClose();
  };

  // Remove condition from conditions array
  const handleRemoveCondition = conditionId => {
    const updatedConditions = lwCurrentCharacter?.criticalConditions.filter(
      condition => condition.id !== conditionId
    );

    // Update state
    setLwCurrentCharacter(prevValue => ({
      ...prevValue,
      criticalConditions: updatedConditions,
    }));
  };

  // Update condition in conditions array
  const handleUpdateCondition = (conditionId, dataType, value) => {
    console.log(`Condition: ${conditionId}, ${dataType}: ${value}`);

    // Update the array
    const updatedConditions = lwCurrentCharacter?.criticalConditions.map(
      condition => {
        if (condition.id === conditionId) {
          return {
            ...condition,
            [dataType]: value,
          };
        }
        return condition;
      }
    );

    // Update state
    setLwCurrentCharacter(prevValue => ({
      ...prevValue,
      criticalConditions: updatedConditions,
    }));
  };

  const handleClickHealing = () => {
    let healingValue = lwCurrentCharacter?.healingProgress;

    if (healingValue < 4) {
      healingValue += 1;
      setLwCurrentCharacter(prev => ({
        ...prev,
        healingProgress: healingValue,
      }));
    } else {
      healingValue = 0;
      setLwCurrentCharacter(prev => ({
        ...prev,
        healingProgress: healingValue,
      }));
    }
  };

  const handleSetBroken = () => {
    setLwCurrentCharacter(prevValue => ({
      ...prevValue,
      isBroken: !prevValue.isBroken,
    }));
  };

  return (
    <>
      <Row>
        <Col xs={10}>
          <Button
            id="add-critical-condition-button"
            className="mb-4"
            onClick={handleShow}
          >
            <FaPlus /> Add Condition
          </Button>
        </Col>
        <Col xs={2}>
          <Button
            id="severeConditionsHelpButton"
            variant="secondary"
            onClick={handleShow}
          >
            ?
          </Button>
        </Col>
      </Row>
      <Row className="pb-1 mb-4 border-bottom">
        <Col xs={6}>
          <h4 className="text-primary">Broken</h4>
          <Form>
            <Form.Check
              type="switch"
              className="form-switch-lg"
              id="isBroken"
              checked={lwCurrentCharacter?.isBroken}
              onChange={handleSetBroken}
            />
          </Form>
        </Col>
        <Col xs={6} className="text-center">
          <h4 className="text-primary">Healing Clock</h4>
          <ProgressClock
            clockId="healing-clock"
            challenge="dangerous"
            progress={lwCurrentCharacter?.healingProgress}
            click={handleClickHealing}
          />
        </Col>
      </Row>
      {lwCurrentCharacter?.criticalConditions?.map(condition => (
        <CriticalCondition
          key={condition.id}
          conditionId={condition.id}
          name={condition.injury}
          effects={condition.effects}
          recovery={condition.recovery}
          removeCondition={handleRemoveCondition}
          updateCondition={handleUpdateCondition}
        />
      ))}

      {/* Critical conditions modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Critical Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            className="mb-3"
            defaultActiveKey="physical"
            id="critical-conditions-tabs"
          >
            <Tab eventKey="physical" title="Physical">
              <p>Select a d66 result to add condition.</p>
              <PhysicalConditionsTable
                setCondition={condition => handleAddCondition(condition)}
              />
            </Tab>
            <Tab eventKey="mental" title="Mental">
              <MentalConditionsTable />
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CriticalConditionsSection;
