import { Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import MyProfile from "../pages/MyProfile";
import About from "../pages/About";
import AdminTools from "../pages/AdminTools";
import EnemyCreator from "../components/AdminTools/EnemyCreator";
import AbilityCreator from "../components/AdminTools/AbilityCreator";
import DatabaseMigrations from "../components/AdminTools/DatabaseMigrations";

export default (
  <>
    <Route path="my-profile" element={<MyProfile />} />
    <Route path="about" element={<About />} />
    <Route path="admin-tools" element={<AdminTools />}>
      <Route path="enemy-creator" element={<EnemyCreator />} />
      <Route path="ability-creator" element={<AbilityCreator />} />
      <Route index element={<Navigate to="enemy-creator" />} />
    </Route>
    <Route path="database-migrations" element={<DatabaseMigrations />} />
  </>
);
