import { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AbilityContext } from "./EmberbrandContext";
import { doc, getDoc } from "firebase/firestore";
import { abilitiesCol } from "../firebaseSetup";

export function LoadAbilityButton({ setLoadedAbility }) {
  const abilityContext = useContext(AbilityContext);
  const [abilityList, setAbilityList] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedAbility, setSelectedAbility] = useState(null);

  useEffect(() => {
    if (abilityContext.abilityList !== null) {
      const sortedList = abilityContext.abilityList.sort((a, b) => {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      setAbilityList(sortedList);
    }
  }, [abilityContext]);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleChange = e => {
    e.persist();
    const abilityId = e.target.value;

    setSelectedAbility(abilityId);
  };

  const handleLoadEditAbility = () => {
    const abilityDoc = doc(abilitiesCol, selectedAbility);

    getDoc(abilityDoc).then(doc => {
      const abilityData = { ...doc.data(), id: doc.id };
      setLoadedAbility(abilityData);
    });

    handleClose();
  };

  return (
    <>
      <div style={{ marginTop: "30px", marginBottom: "50px" }}>
        <Button onClick={handleShow}>Load Existing Ability</Button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Load Ability</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-left">
          <div style={{ height: "440px", overflowY: "auto" }}>
            <Form>
              {abilityList.length > 0 ? (
                abilityList.map(ability => {
                  return (
                    <Form.Check
                      name="abilityRadio"
                      key={ability?.id}
                      value={ability?.id}
                      label={ability?.name}
                      ability-name={ability?.name}
                      type="radio"
                      onChange={handleChange}
                    />
                  );
                })
              ) : (
                <p>Could not load abilities.</p>
              )}
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleLoadEditAbility}
          >
            Edit Ability
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
