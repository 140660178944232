import { Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import LwRules from "../../../pages/LeviathansWake/rules/LwRules";
import LwCoreRulesRoutes from "./LwCoreRulesRoutes";
import LwCharacterRoutes from "./LwCharacterRoutes";

export default (
  <Route path="lw-rules" element={<LwRules />}>
    {LwCoreRulesRoutes}
    {LwCharacterRoutes}
    <Route index element={<Navigate to="lw-core-rules/lw-basics" />} />
  </Route>
);
