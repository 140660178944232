import { Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import LWCharacterSheet from "../../pages/LeviathansWake/CharacterSheet/LwCharacterSheet";
import LwCharacterTab from "../../components/CharacterSheet/LeviathansWake/LwCharacterTab";
import LwPursuitsTab from "../../components/CharacterSheet/LeviathansWake/LwPursuitsTab";
import LwJournalTab from "../../components/CharacterSheet/LeviathansWake/LwJournalTab";
import LwTalentsSection from "../../components/CharacterSheet/LeviathansWake/LwTalentsSection";

export default (
  <Route path="character-sheet" element={<LWCharacterSheet />}>
    <Route path="character" element={<LwCharacterTab />} />
    <Route path="talents" element={<LwTalentsSection />} />
    <Route path="pursuits" element={<LwPursuitsTab />} />
    <Route path="journal" element={<LwJournalTab />} />
    <Route index element={<Navigate to="character" />} />
  </Route>
);
