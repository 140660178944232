import { Table } from "react-bootstrap";

function HarmTable() {
  return (
    <Table striped>
      <thead>
        <tr>
          <th>Roll</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th className="w-25">1 - 5</th>
          <td>You shrug the harm off and stay standing.</td>
        </tr>
        <tr>
          <th className="w-25">6 - 10</th>
          <td>The harm leaves a mark. Record Wounded/Shaken/Exhausted.</td>
        </tr>
        <tr>
          <th className="w-25">11 - 15</th>
          <td>
            The harm has you reeling and fighting to stay conscious. Record
            Wounded/Shaken/Exhausted. If you engage in any vigorous activity
            without giving yourself a break, roll harm again.
          </td>
        </tr>
        <tr>
          <th className="w-25">16 - 20</th>
          <td>
            The harm knocks you unconscious and leaves you vulnerable. Record
            Wounded/Shaken/Exhausted and a negative condition. If left alone for
            an hour or two, you come back to your senses.
          </td>
        </tr>
        <tr>
          <th className="w-25">21+</th>
          <td>The harm is lethal and leaves you facing death.</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default HarmTable;
