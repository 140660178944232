import { useContext } from "react";
import { LwCharacterDataContext } from "../../../hooks/leviathansWake/LwContext";
import { Row } from "react-bootstrap";
import { useAutosave } from "react-autosave";

function LwCharacterDetailsSection() {
  const { lwCurrentCharacter, setLwCurrentCharacter } = useContext(
    LwCharacterDataContext
  );

  const handleSetCharacterData = dataId => {
    const value = document.getElementById(dataId).value;

    setLwCurrentCharacter(prevValue => ({
      ...prevValue,
      [dataId]: value,
    }));
  };

  return (
    <div>
      <Row className="mb-4">
        <h4 className="text-primary">Name</h4>
        <input
          id="name"
          className="data form-control"
          value={lwCurrentCharacter?.name || ""}
          onChange={() => handleSetCharacterData("name")}
        />
      </Row>
      <Row className="mb-4">
        <h4 className="text-primary">Nickname and Pronouns</h4>
        <input
          id="pronouns"
          className="data form-control"
          value={lwCurrentCharacter?.pronouns || ""}
          onChange={() => handleSetCharacterData("pronouns")}
        />
      </Row>
      <Row className="mb-4">
        <h4 className="text-primary">Occupation</h4>
        <input
          id="occupation"
          className="data form-control"
          value={lwCurrentCharacter?.occupation || ""}
          onChange={() => handleSetCharacterData("occupation")}
        />
      </Row>
      <Row className="mb-4">
        <h4 className="text-primary">Notable Gear</h4>
        <input
          id="notableGear"
          className="data form-control"
          value={lwCurrentCharacter?.notableGear || ""}
          onChange={() => handleSetCharacterData("notableGear")}
        />
      </Row>
    </div>
  );
}

export default LwCharacterDetailsSection;
