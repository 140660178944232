import Section from "../../../components/Section";
import SkillMatrixReadOnly from "../../../components/SkillMatrixReadOnly";

function Skills() {
  return (
    <Section>
      <h1 className="title-header">Skills</h1>
      <SkillMatrixReadOnly />
      <div>
        <h3>Strength (STR)</h3>
        <p>
          Your raw physical power. Strength governs your ability to perform
          feats of strength, break objects, and overpower enemies. Strength is
          used to attack with most melee weapons.
        </p>
      </div>
      <hr />
      <div>
        <h3>Agility (AGI)</h3>
        <p>
          Your agility and coordination. Agility governs your ability to perform
          precise and nimble actions, such as dodging attacks, sneaking past
          others, and performing acrobatics.
        </p>
      </div>
      <hr />
      <div>
        <h3>Presence (PRE)</h3>
        <p>
          Your commanding aura, appealing looks or intimidating stature.
          Presence reflects your ability to influence others through your
          physical charm, your confidence, or your intensity.
        </p>
      </div>
      <hr />
      <div>
        <h3>Investigation (INV)</h3>
        <p>
          Your aptitude for gathering information, scrutinizing details, and
          uncovering hidden clues. Investigation includes skills such as
          searching for hidden objects, deciphering codes, and solving
          mysteries.
        </p>
      </div>
      <hr />
      <div>
        <h3>Resistance (RES)</h3>
        <p>
          Your resilience against damage and immediate harm. Resistance governs
          your ability to shrug off the bitter cold or sweltering heat of harsh
          environments, withstand poisons, and mitigate damage.
        </p>
      </div>
      <hr />
      <div>
        <h3>Focus (FOC)</h3>
        <p>
          Your ability to concentrate deeply. Focus represents your
          effectiveness in tasks requiring concentration, such as spellcasting,
          technical work, and meticulous research.
        </p>
      </div>
      <hr />
      <div>
        <h3>Dexterity (DEX)</h3>
        <p>
          Your hand eye coordination, finesse, and fine motor skills. Dexterity
          encompasses your expertise in archery, tinkering, and crafting.
          Dexterity is used to attack with ranged weapons and certain precise
          melee weapons.
        </p>
      </div>
      <hr />
      <div>
        <h3>Cunning (CUN)</h3>
        <p>
          Your ability to think quickly and mold a situation in your favor.
          Cunning signifies your deception, tactics, and persuasion.
        </p>
      </div>
      <hr />
      <div>
        <h3>Knowledge (KND)</h3>
        <p>
          Your breadth and depth of knowledge across various subjects, lore, and
          disciplines. Knowledge includes your understanding of history, arcane
          lore, and academia.
        </p>
      </div>
      <hr />
      <div>
        <h3>Willpower (WIL)</h3>
        <p>
          Your mental fortitude, determination, and resistance to psychic
          influences. Willpower enhances your ability to resist mental attacks,
          maintain your wits in stressful situations, and withstand manipulation
          or coercion.
        </p>
      </div>
      <hr />
      <div>
        <h3>Luck (LCK)</h3>
        <p>
          Your propensity for fortunate or serendipitous events. Luck influences
          the likelihood of favorable outcomes, random chances, and fortunate
          occurrences in various situations. You'll be more likely to avoid
          danger, find hidden treasures, or stumble upon valuable information.
        </p>
      </div>
      <hr />
      <div>
        <h3>Survival (SVL)</h3>
        <p>
          Your ability to thrive in the wilderness. Survival includes skills
          such as hunting, foraging, tracking, and navigating through unkonwn
          terrain. You can adapt to the unpredictability of the wild and find
          comfort in nature.
        </p>
      </div>
      <hr />
      <div>
        <h3>Morale (MLE)</h3>
        <p>
          Your talent for boosting the motivation of yourself and your allies.
          Morale empowers you to inspire courage, bolster determination, and
          enhance the resilience of your party in challenging situations. You
          can uplift spirits and foster unity within your group.
        </p>
      </div>
      <hr />
      <div>
        <h3>Instinct (IST)</h3>
        <p>
          Your heightened senses, intuition, and ability to read subtle cues
          from your environment. Instinct enhances your perception, allows you
          to anticipate danger, and provides insight that goes beyond logical
          deduction or conscious awareness.
        </p>
      </div>
      <hr />
      <div>
        <h3>Endurance (END)</h3>
        <p>
          Your stamina, ability to push through exhaustion, and resolve to heal.
          Endurance allows you to persevere, resist fatigue, and maintain
          performance in the face of grueling trials. When resting, your
          Endurance helps you shake off your injuries.
        </p>
      </div>
    </Section>
  );
}

export default Skills;
