import Section from "../../components/Section";

function MoneyLoot() {
  return (
    <>
      <Section>
        <h1 className="title-header">Money & Loot</h1>
        
      </Section>
    </>
  );
}

export default MoneyLoot;
