import Section from "../../../components/Section";

function Enchanting() {
  return (
    <Section>
      <h1 className="title-header">Enchanting</h1>
      <p>
        Enchanting imbues an otherwise mundane object with magical properties.
      </p>
      <hr />
    </Section>
  );
}

export default Enchanting;
