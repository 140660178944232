import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "../index.css";
import { Quill } from "react-quill";

function QuillEditor({ id, value, change, defaultPlaceholder }) {
  const icons = Quill.import("ui/icons");

  for (let i = 1; i <= 6; i += 1) {
    icons.header[i] = `<b class="ql-custom">H<sub>${i}</sub></b>`;
  }

  let modules = {
    toolbar: [
      [{ header: 1 }, { header: 2 }, { header: 3 }],
      ["bold", "italic", "underline"],
      [
        { list: "ordered" },
        { list: "bullet" },
        // { indent: "-1" },
        // { indent: "+1" },
      ],
      // ["link", "image"],
      ["clean"],
    ],
  };
  let formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  // additional attributes

  return (
    <div className="text-editor">
      <ReactQuill
        theme="snow"
        id={id}
        value={value}
        onChange={change}
        // placeholder={"Select text to style..."}
        placeholder={defaultPlaceholder ? defaultPlaceholder : "Test..."}
        modules={modules}
        formats={formats}
      />
    </div>
  );
}

export default QuillEditor;
