import { useContext, useEffect, useState } from "react";
import Section from "../Section";
import SkillMatrix from "./SkillMatrix";
import { CharacterDataContext } from "../../hooks/UserContext";
import { Row } from "react-bootstrap";
import { InjurySection } from "./InjurySection";
import VitalitySection from "./VitalitySection";
import ReferenceHeader from "../RulesReference/ReferenceHeader";
import Actions from "../RulesReference/Actions";
import Outcomes from "../RulesReference/Outcomes";
import WealthSection from "./WealthSection";

function SkillVitalitySection() {
  const { currentCharacter } = useContext(CharacterDataContext);
  const [damageThreshold, setDamageThreshold] = useState(0);
  const [traumaThreshold, setTraumaThreshold] = useState(0);
  const [exhaustionThreshold, setExhaustionThreshold] = useState(0);

  useEffect(() => {
    if (currentCharacter !== null) {
      // calculate damage threshold
      const resistancePercent =
        parseInt(currentCharacter?.body) +
        parseInt(currentCharacter?.constitution);
      const endurancePercent =
        parseInt(currentCharacter?.spirit) +
        parseInt(currentCharacter?.constitution);
      const resistanceBonus = parseInt(String(resistancePercent)[0]);
      const enduranceBonus = parseInt(String(endurancePercent)[0]);
      const bonusDamageThreshold = parseInt(
        currentCharacter?.bonusDamageThreshold
      )
        ? parseInt(currentCharacter?.bonusDamageThreshold)
        : 0;
      const damageThreshold = resistanceBonus + bonusDamageThreshold;

      // calculate mental threshold
      const willpowerPercent =
        parseInt(currentCharacter?.mind) +
        parseInt(currentCharacter?.constitution);
      const willpowerBonus = parseInt(String(willpowerPercent)[0]);
      const bonusTraumaThreshold = parseInt(
        currentCharacter?.bonusTraumaThreshold
      )
        ? parseInt(currentCharacter?.bonusTraumaThreshold)
        : 0;
      const traumaThreshold = willpowerBonus + bonusTraumaThreshold;

      // calculate exhaustion threshold
      const bonusExhaustionThreshold = parseInt(
        currentCharacter?.bonusExhaustionThreshold
      )
        ? parseInt(currentCharacter?.bonusExhaustionThreshold)
        : 0;
      const exhaustionThreshold = enduranceBonus + bonusExhaustionThreshold;

      setDamageThreshold(damageThreshold);
      setTraumaThreshold(traumaThreshold);
      setExhaustionThreshold(exhaustionThreshold);
    }
  }, [currentCharacter]);

  return (
    <>
      <Section>
        <Row>
          <ReferenceHeader
            id="skills-help-text"
            title="Skill Matrix"
            defaultTab={<Actions />}
            defaultEventKey="actions"
            tabs={[
              {
                id: "actions-tab",
                title: "Actions",
                eventKey: "actions",
                content: <Actions />,
              },
              {
                id: "outcomes-tab",
                title: "Outcomes",
                eventKey: "outcomes",
                content: <Outcomes />,
              },
            ]}
          />
          <SkillMatrix />
        </Row>
      </Section>
      <WealthSection />
      <VitalitySection
        damageThreshold={damageThreshold}
        traumaThreshold={traumaThreshold}
        exhaustionThreshold={exhaustionThreshold}
      />
      <InjurySection
        damageThreshold={damageThreshold}
        traumaThreshold={traumaThreshold}
        exhaustionThreshold={exhaustionThreshold}
      />
    </>
  );
}

export default SkillVitalitySection;
