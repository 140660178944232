import { Link } from "react-router-dom";
import Section from "../../../../components/Section";

function LwCharacterConcepts() {
  return (
    <Section>
      <h1 className="title-header">Character Concepts</h1>
      <p>
        Your character in Leviathan's Wake tells the story of an individual
        living in the Wake; from a swashbuckler striving for infamy, to a
        merchant looking for coin among the isles, to a member of the royal navy
        who helps drive back the horror of the Abyss.
      </p>
      <p>
        Whatever your story, your character is made up of the following core
        concepts and traits.
      </p>
      <h2>Occupation</h2>
      <p>
        Your occupation determines your background and role in your adventuring
        group. It influences your attributes, your skills, and your starting
        talent. The occupations are described in the{" "}
        <Link to="occupations" className="link">
          Occupations
        </Link>{" "}
        section.
      </p>
      <div className="example bg-info">
        <p>
          To start, choose one occupation to give your character. Envision the
          type of person your character is and think about how you want to tell
          their story. Think about your chosen occupation as you create your
          character.
        </p>
      </div>
      <h2>Attributes</h2>
      <p>
        Your character has four attributes which define your physical and mental
        capabilities, each given a rating from 1 to 5. You use your attributes
        when you roll dice to perform actions in the game.
      </p>
      <p>
        <b className="text-primary">Physique:</b> Muscle, dexterity, and
        proficiency in close-quarters combat.
      </p>
      <p>
        <b className="text-primary">Prowess:</b> Fine motor skills, sneakiness,
        and proficiency in ranged combat.
      </p>
      <p>
        <b className="text-primary">Wits:</b> Education, perception, and ability
        to survive in the wild.
      </p>
      <p>
        <b className="text-primary">Rapport:</b> Charisma, manipulation,
        leadership.
      </p>
      <div className="example bg-info">
        <p>
          To start, distribute a total of 14 points across your attributes. You
          may assign no less than 2 and no more than 4 points to any attribute,
          except your occupation's "key attribute," to which you may assign 5
          points.
        </p>
      </div>
    </Section>
  );
}

export default LwCharacterConcepts;
