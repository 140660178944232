import { Tab, Tabs } from "react-bootstrap";
import Section from "../Section";
import SkillVitalitySection from "./SkillVitalitySection";
import SummarySection from "./SummarySection";

function CharacterTab() {
  return (
    <Section>
      <Tabs defaultActiveKey={1}>
        <Tab eventKey={1} title="Skills & Vitality">
          <SkillVitalitySection />
        </Tab>
        <Tab eventKey={2} title="Description">
          <SummarySection />
        </Tab>
      </Tabs>
    </Section>
  );
}

export default CharacterTab;
