import { Button, Table } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";

const physicalConditionsData = [
  {
    d66: "11",
    injury: "Winded",
    lethal: "No",
    timeLimit: "—",
    effects: "None.",
    recovery: "—",
  },
  {
    d66: "12",
    injury: "Stunned",
    lethal: "No",
    timeLimit: "—",
    effects: "None.",
    recovery: "—",
  },
  {
    d66: "13",
    injury: "Crippling pain",
    lethal: "No",
    timeLimit: "—",
    effects: "None.",
    recovery: "—",
  },
  {
    d66: "14",
    injury: "Sprained ankle",
    lethal: "No",
    timeLimit: "—",
    effects: "Agility -2 and movement is hindered.",
    recovery: "1",
  },
  {
    d66: "15",
    injury: "Blood in eyes",
    lethal: "No",
    timeLimit: "—",
    effects: "Observation and Ranged -2.",
    recovery: "1",
  },
  {
    d66: "16",
    injury: "Concussion",
    lethal: "No",
    timeLimit: "—",
    effects: "Agility -2.",
    recovery: "d6",
  },
  {
    d66: "21",
    injury: "Severed ear",
    lethal: "No",
    timeLimit: "—",
    effects: "Observation -2.",
    recovery: "d6",
  },
  {
    d66: "22",
    injury: "Broken toes",
    lethal: "No",
    timeLimit: "—",
    effects: "Movement is hindered.",
    recovery: "d6",
  },
  {
    d66: "23",
    injury: "Broken hand",
    lethal: "No",
    timeLimit: "—",
    effects: "Hand cannot be used.",
    recovery: "d6",
  },
  {
    d66: "24",
    injury: "Knocked out teeth",
    lethal: "No",
    timeLimit: "—",
    effects: "Persuasion -2.",
    recovery: "d6",
  },
  {
    d66: "25",
    injury: "Impaled thigh",
    lethal: "No",
    timeLimit: "—",
    effects: "Movement is hindered.",
    recovery: "2d6",
  },
  {
    d66: "26",
    injury: "Slashed shoulder",
    lethal: "No",
    timeLimit: "—",
    effects: "Arm cannot be used.",
    recovery: "d6",
  }
];

function PhysicalConditionsTable({ setCondition }) {
  const handleSelectCondition = key => {
    const condition = physicalConditionsData.find(
      condition => condition.d66 === key
    );

    console.log(`${condition.injury}: ${condition.effects}`);
  };
  return (
    <Table striped bordered hover responsive="sm" className="text-center">
      <thead>
        <tr>
          <th>d66</th>
          <th>Injury</th>
          <th>Lethal</th>
          <th>Time Limit</th>
          <th>Effects</th>
          <th>Recovery</th>
        </tr>
      </thead>
      <tbody>
        {physicalConditionsData.map(condition => (
          <tr key={condition.d66}>
            <td>
              <Button variant="link" onClick={() => setCondition(condition)}>
                {condition.d66}
                {/* <br /> */}
                {/* <FaPlus /> */}
              </Button>
            </td>
            <td>{condition.injury}</td>
            <td>{condition.lethal}</td>
            <td>{condition.timeLimit}</td>
            <td>{condition.effects}</td>
            <td>{condition.recovery}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default PhysicalConditionsTable;
