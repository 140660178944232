import InjuryReference from "../../../components/RulesReference/InjuryReference";
import Section from "../../../components/Section";

function Damage() {
  return (
    <>
      <Section>
        <h1 className="title-header">Damage</h1>
        <p>
          Every grand adventure comes with risk. As you trek mountains, explore
          vast caverns, scale immense buildings, and battle foes, you walk the
          path of danger. The perils of your daring exploits and deadly
          conflicts are tracked by damage and consequences.
        </p>
        <p>
          You have three damage tracks, which correspond to your three
          attributes. Your <b>Damage Threshold</b> (DT) ties to your <b>Body</b>{" "}
          and is used to track the physical harm you suffer at the hands of
          enemies, traps, and perils. Your <b>Trauma Threshold</b> (TT) ties to
          your <b>Mind</b> and is used to track the dangers posed to your mental
          wellbeing. Your <b>Exhaustion Threshold</b> (ET) ties to your{" "}
          <b>Spirit</b> and is used to track the risk you impose on yourself as
          you push yourself beyond your limits.
        </p>
        <p>
          Whenever you suffer damage, increase your corresponding character
          damage by that amount. The damage you have suffered for each damage
          track goes against that track's threshold. These thresholds represent
          how much you can take before you must suffer a consequence.
        </p>
        <p>
          If you're wearing armor, reduce the incoming physical damage you would
          suffer by your armor value. If you have multiple pieces of equipment
          with armor values or you have any active effects which provide an
          armor value, combine them all and reduce incoming damage by the total.
        </p>
      </Section>
      <Section>
        <h1 className="title-header">Consequences</h1>
        <p>
          Whenever you suffer damage which brings your character damage greater
          than a threshold, you must suffer a consequence. Do this by reducing
          your character damage until it equals the corresponding threshold, and
          increasing a consequence's damage by that amount. The Storyteller will
          tell you whether you suffer a new consequence or aggravate an existing
          one.
        </p>
        <h2>Anatomy of a Consequence</h2>
        <h4>Severity</h4>
        <p>
          A consequence can be light, heavy, severe, or fatal depending on how
          much damage has been allocated.
        </p>
        <h4>Details</h4>
        <p>
          The details of a consequence differ depending on the type of
          consequence, the circumstances under which is was suffered, and any
          other factors the Storyteller may wish to impose. Each type of
          consequence has default effects and mechanics, but you and the
          Storyteller can modify them as you wish to make for a more compelling
          narrative.
        </p>
        <h4>Treatment Status</h4>
        <p>
          Consequences must be treated within a certain time window before they
          become worse. The treatment window for a consequence reduces as more
          damage is allocated. An untreated consequence cannot be healed. If you
          do not treat a consequence within its treatment window, you must
          allocate more damage to it until it reaches its next severity.
        </p>
        <h4>Damage</h4>
        <p>
          Consequence damage is tracked compared to the corresponding threshold
          to determine its severity. The more damage allocated to a consequence,
          the more it increases in severity, the harder it is to treat, the
          longer it takes to heal, and the greater its negative impact.
        </p>
        <ul>
          <li>1 damage to threshold: Light</li>
          <li>1x threshold to 2x threshold: Heavy</li>
          <li>2x threshold to 3X threshold: Severe</li>
          <li>Greater than 3x threshold: Fatal</li>
        </ul>
        <p>
          As you sustain consequences, your ability to withstand damage lessens.
          There is a limit to the total number of consequences you may have of
          each severity.
        </p>
        <ul>
          <li>2 light consequences</li>
          <li>2 heavy consequences</li>
          <li>1 severe consequence</li>
        </ul>
        <p>
          If you would suffer a consequence, but have already recorded the
          maximum number of consequences at that severity, you must record the
          new one at the next available severity. When you do, set the
          consequence's damage to the minimum for that severity (based on its
          threshold), then increase the consequence damage by the amount you
          reduced your character damage as normal.
        </p>
        <p>
          The total number of consequences you have at each severity is
          cumulative across all consequence types. That means if you already
          have two light injuries and would suffer a light trauma, you must
          record that trauma as heavy instead.
        </p>
        <InjuryReference />
      </Section>
    </>
  );
}

export default Damage;
