import { doc, updateDoc } from "firebase/firestore";
import { charactersCol, lwCharactersCol } from "../firebaseSetup";

export function AutoSaveCharacter(col, charId, dataId, value) {
  console.log(`Saving ${dataId}: ${value}`);

  try {
    const charDoc = doc(col, charId);

    updateDoc(charDoc, {
      [dataId]: value,
    });
  } catch (error) {
    console.log(error);
  }
}

export function AutoSaveAbilityData(abilityId, dataId, value) {
  console.log(`Saving ${dataId} ability data: ${value}`);

  try {
    const abilityDoc = doc(
      charactersCol,
      localStorage.getItem("currentCharacterId"),
      "charAbilities",
      abilityId
    );

    updateDoc(abilityDoc, {
      [dataId]: value,
    });
  } catch (error) {
    console.log(error);
    alert(error);
  }
}

export function AutoSaveInjuryData(injuryId, dataId, value) {
  console.log(`Saving injury ${injuryId} ${dataId} data: ${value}`);

  try {
    const injuryDoc = doc(
      charactersCol,
      localStorage.getItem("currentCharacterId"),
      "charInjuries",
      injuryId
    );

    updateDoc(injuryDoc, {
      [dataId]: value,
    });
  } catch (error) {
    console.log(error);
    alert(error);
  }
}

export function AutoSaveInventoryData(itemId, dataId, value) {
  console.log(`Saving item ${itemId} ${dataId}: ${value}`);

  try {
    const inventoryDoc = doc(
      charactersCol,
      localStorage.getItem("currentCharacterId"),
      "charInventory",
      itemId
    );

    updateDoc(inventoryDoc, {
      [dataId]: value,
    });
  } catch (error) {
    console.log(error);
    alert(error);
  }
}

export function AutoSaveLwCharacter(data) {
  const charId = localStorage.getItem("currentLwCharacterId");
  const charDoc = doc(lwCharactersCol, charId);

  console.log(`Autosaving...`);

  try {
    updateDoc(charDoc, data);
  } catch (error) {
    console.log(error);
  }
}
