import { Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Combat from "../../../pages/rules/combat/Combat";
import Attacking from "../../../pages/rules/combat/Attacking";
import CombatRange from "../../../pages/rules/combat/CombatRange";

export default (
  <Route path="combat">
    <Route path="combat" element={<Combat />} />
    <Route path="combat-attacking" element={<Attacking />} />
    <Route path="combat-range" element={<CombatRange />} />
    <Route index element={<Navigate to="combat" />} />
  </Route>
);
