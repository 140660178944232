import { Row, Col } from "react-bootstrap";

function MagicIngredient({ number, name, cost, tags, children }) {
  return (
    <>
      <Row>
        <Col xs={1}>{number}</Col>
        <Col xs={11}>
          <h3>{name}</h3>
          <p>
            <b>Cost:</b> {cost}
          </p>
          <p>
            <b>Tags:</b> {tags}
          </p>
          {children}
        </Col>
      </Row>
      <hr />
    </>
  );
}

export default MagicIngredient;
