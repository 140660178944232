import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { collection, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCBeIdeyeaa8BAEB9uAeM9c0EiuTYY3e3c",
  authDomain: "bg-configurator-88c75.firebaseapp.com",
  projectId: "bg-configurator-88c75",
  storageBucket: "bg-configurator-88c75.appspot.com",
  messagingSenderId: "963845480354",
  appId: "1:963845480354:web:2f061432ba815217439cfb",
};

// init firebase app
const app = initializeApp(firebaseConfig);

// init services
const auth = getAuth(app);
const db = getFirestore(app);

// create users collection
const usersCol = collection(db, "users");

// create Emberbrand collections
const abilitiesCol = collection(
  db,
  "emberbrand",
  "StNmaEObp8BWidV8Vs7P",
  "abilities"
);
const charactersCol = collection(
  db,
  "emberbrand",
  "pPWODYdBUXxVRpBAoKUx",
  "characters"
);
const injuriesCol = collection(
  db,
  "emberbrand",
  "DjxDJH2nej4zEvZ5YwoA",
  "injuries"
);
const enemiesCol = collection(
  db,
  "emberbrand",
  "itdqpIPeXa4Lstv3oU9j",
  "enemies"
);
const campaignsCol = collection(
  db,
  "emberbrand",
  "BhJiRnDpg6OauMlHqLec",
  "campaigns"
);
const statusesCol = collection(
  db,
  "emberbrand",
  "4xg1NulHUTDAjwRXeuwO",
  "statuses"
);

// create Leviathan's Wake collections
const lwCharactersCol = collection(
  db,
  "leviathansWake",
  "CI6uoFTWaa5g4CGj0MEz",
  "characters"
);

const lwTalentsCol = collection(
  db,
  "leviathansWake",
  "tSwXHBjN1IEXoTUavR4a",
  "talents"
);

// export database
export {
  app,
  auth,
  db,
  usersCol,
  abilitiesCol,
  charactersCol,
  injuriesCol,
  enemiesCol,
  campaignsCol,
  statusesCol,
  lwCharactersCol,
  lwTalentsCol,
};
