import Section from "../../components/Section";
import { Link } from "react-router-dom";

function CharacterCreation() {
  return (
    <>
      <Section>
        <h1 className="title-header">What Makes a Character?</h1>
        <p>
          The character you play in Emberbrand personifies the individual you
          guide through your adventures. You'll get the most out of Emberbrand
          as you immerse yourself into your character and their story. Their
          deeds, accomplishments, folies, and trials shape who they are and
          guides who they will become.
        </p>
        <p>
          The first chapter of your character's story begins with character
          creation. Character creation is the process of introducing your
          character to the world and the adventure. It's a collaborative process
          in which you work with the Storyteller and the other players involved
          in your game. You'll work together to fit your character into the band
          of adventurers you'll play with, collaborate on a history, and set the
          scene for how you'll forge your name in the world.
        </p>
        <p>
          Before you begin filling out your character sheet, think about your
          character's background. Imagine their personality, their motivations,
          their aspirations. You can jot down a list of traits to mold your
          character, you can write out a history beginning at birth, or you can
          simply start with a name. It's through your backstory that you'll
          bring an interesting character to the table and step into their shoes
          as you play Emberbrand.
        </p>
        <p>
          With the framework of your character in mind, you can begin filling
          out your{" "}
          <Link to="/character-sheet" className="link">
            character sheet
          </Link>{" "}
          and bringing your character to life!
        </p>
      </Section>
    </>
  );
}

export default CharacterCreation;
