import Col from "react-bootstrap/Col";
import { useContext, useEffect, useRef, useState } from "react";
import { AutoSaveCharacter } from "../../backend/AutoSaveCharacter";
import { CharacterDataContext } from "../../hooks/UserContext";
import { Row } from "react-bootstrap";
import NumberInput from "./NumberInput";

function ExhaustionRow({ exhaustionThreshold }) {
  const { currentCharacter, setCurrentCharacter } =
    useContext(CharacterDataContext);
  const [thresholdExceeded, setThresholdExceeded] = useState(false);

  const autoSaveTimer = useRef(null);

  const ThresholdExceeded = () => (
    <div id="exhaustion-threshold-exceeded" className="text-danger">
      <p>
        You've taken too much exhaustion! Whenever you suffer exhaustion above
        your ET, roll on the harm table and add the exhaustion above your ET to
        the roll.
      </p>
    </div>
  );

  useEffect(() => {
    currentCharacter?.exhaustion > exhaustionThreshold
      ? setThresholdExceeded(true)
      : setThresholdExceeded(false);
  }, [currentCharacter?.exhaustion, exhaustionThreshold]);

  const handleSetCharacterData = id => {
    const value = document.getElementById(id).value;

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }
    setCurrentCharacter(prevValue => ({
      ...prevValue,
      [id]: value,
    }));

    autoSaveTimer.current = setTimeout(() => {
      AutoSaveCharacter(id, value);
    }, 1000);
  };

  return (
    <>
      <h2 className={thresholdExceeded ? "text-danger" : "text-primary"}>
        Exhaustion Threshold ({exhaustionThreshold})
      </h2>
      {thresholdExceeded ? <ThresholdExceeded /> : null}
      <Row>
        <Col xs={6}>
          <h3 className="text-primary">ET Modifier</h3>
          <NumberInput
            id="bonusExhaustionThreshold"
            value={currentCharacter?.bonusExhaustionThreshold}
            justify={true}
            change={handleSetCharacterData}
          />
        </Col>
        <Col xs={6}>
          <h3 className="text-primary">Exhaustion</h3>
          <NumberInput
            id="exhaustion"
            value={currentCharacter?.exhaustion}
            justify={true}
            change={handleSetCharacterData}
          />
        </Col>
      </Row>
      <ExhaustedCheck exhausted={currentCharacter?.exhausted} />
      <hr />
    </>
  );
}

function ExhaustedCheck({ exhausted }) {
  const [isExhausted, setIsExhausted] = useState(false);
  const autoSaveTimer = useRef(null);

  useEffect(() => {
    if (exhausted) {
      setIsExhausted(exhausted);
    }
  }, [exhausted]);

  const handleSetExhaustedData = () => {
    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }

    setIsExhausted(!isExhausted);

    autoSaveTimer.current = setTimeout(() => {
      AutoSaveCharacter("exhausted", !isExhausted);
    }, 1000);
  };

  return (
    <Row className="text-left">
      <div className="magnitude-row">
        <h2 className="text-primary">Exhausted</h2>
        <input
          id="exhausted"
          type="checkbox"
          className="treated form-check-input checkbox-lg"
          checked={isExhausted}
          onChange={handleSetExhaustedData}
          style={{ width: "30px", height: "30px" }}
        />
      </div>
    </Row>
  );
}

export default ExhaustionRow;
