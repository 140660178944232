import { Container } from "react-bootstrap";

function MainContainer({ children }) {
  return (
    <Container id="main-container" fluid className="main">
      {children}
    </Container>
  );
}

export default MainContainer;
