import { Accordion, Button, Row, Col } from "react-bootstrap";
import Section from "../Section";
import { useContext, useEffect, useRef, useState } from "react";
import {
  CharacterDataContext,
  CharacterInventoryContext,
} from "../../hooks/UserContext";
import {
  doc,
  addDoc,
  collection,
  deleteDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, charactersCol } from "../../firebaseSetup";
import { AutoSaveInventoryData } from "../../backend/AutoSaveCharacter";
import WeaponTags from "../RulesReference/WeaponTags";
import ReferenceHeader from "../RulesReference/ReferenceHeader";
import WeaponsList from "../RulesReference/WeaponsList";
import QuillEditor from "../QuillEditor";

export function InventorySection() {
  const characterInventory = useContext(CharacterInventoryContext);
  const currentCharacter = useContext(CharacterDataContext);
  const [inventoryList, setInventoryList] = useState([]);
  const [maxInventorySlots, setMaxInventorySlots] = useState("");
  const [filledInventorySlots, setFilledInventorySlots] = useState("");

  const Encumbered = () => (
    <div className="text-danger">
      <p>
        You're carrying too much! You are encumbered until you offload items to
        your maximum inventory slots.
      </p>
    </div>
  );

  useEffect(() => {
    setTimeout(() => {
      // Sort inventory by name alphabetically
      if (characterInventory.characterInventory !== null) {
        const sortedList = characterInventory.characterInventory.sort(
          (a, b) => {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          }
        );
        setInventoryList(sortedList);
      }

      if (currentCharacter.currentCharacter !== null) {
        const charData = currentCharacter.currentCharacter;
        const strengthPercent =
          parseInt(charData.body) + parseInt(charData.power);
        const endurancePercent =
          parseInt(charData.spirit) + parseInt(charData.constitution);
        const strengthBonus = parseInt(String(strengthPercent)[0]);
        const enduranceBonus = parseInt(String(endurancePercent)[0]);
        var filledSlots = 0;

        setMaxInventorySlots(strengthBonus + enduranceBonus);

        if (characterInventory.characterInventory !== null) {
          characterInventory.characterInventory.forEach(item => {
            const slotValue = item.slots ? parseInt(item.slots) : 0;
            filledSlots += slotValue;
            setFilledInventorySlots(filledSlots);
          });
        }
      }
    }, 200);
  }, [currentCharacter, characterInventory]);

  return (
    <>
      <Section>
        <ReferenceHeader
          id="weapons-help-text"
          title="Inventory"
          defaultTab={<WeaponTags />}
          defaultEventKey="weapon-tags"
          tabs={[
            {
              id: "weapon-tags-tab",
              title: "Weapon Tags",
              eventKey: "weapon-tags",
              content: <WeaponTags />,
            },
          ]}
        />
        <div className="text-left">
          <h2
            className={
              filledInventorySlots > maxInventorySlots ? "text-danger" : ""
            }
          >
            Inventory Slots: {filledInventorySlots ? filledInventorySlots : 0} /{" "}
            {maxInventorySlots}
          </h2>
          {filledInventorySlots > maxInventorySlots ? <Encumbered /> : null}
        </div>
        <Accordion style={{ marginBottom: "50px" }}>
          {inventoryList.map(item => {
            return (
              <Item
                key={item.id}
                itemId={item.id}
                name={item.name}
                slots={item.slots}
                details={item.details}
              />
            );
          })}
        </Accordion>
        <AddItemButton />
      </Section>
    </>
  );
}

function Item({ itemId, name, slots, details }) {
  const { characterInventory, setCharacterInventory } = useContext(
    CharacterInventoryContext
  );
  const [itemName, setItemName] = useState(name);
  const [itemSlots, setItemSlots] = useState(slots);
  const [itemDetails, setItemDetails] = useState(details);
  const autoSaveTimer = useRef(null);

  const handleSetItemName = e => {
    const itemId = e.target.getAttribute("data-id");
    const value = e.target.value;

    const itemKey = Object.keys(characterInventory).find(
      key => characterInventory[key].id === itemId
    );

    const updatedItemList = Object.assign([...characterInventory], {
      [itemKey]: { ...characterInventory[itemKey], name: value },
    });

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }

    setCharacterInventory(updatedItemList);

    autoSaveTimer.current = setTimeout(() => {
      AutoSaveInventoryData(itemId, "name", value);
    }, 1000);

    setItemName(e.target.value);
  };

  const handleSetItemSlots = e => {
    const itemId = e.target.getAttribute("data-id");
    const value = e.target.value;

    const itemKey = Object.keys(characterInventory).find(
      key => characterInventory[key].id === itemId
    );

    const updatedItemList = Object.assign([...characterInventory], {
      [itemKey]: { ...characterInventory[itemKey], slots: value },
    });

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }

    setCharacterInventory(updatedItemList);

    autoSaveTimer.current = setTimeout(() => {
      AutoSaveInventoryData(itemId, "slots", value);
    }, 1000);

    setItemSlots(e.target.value);
  };

  const handleSetItemRichData = (e, itemId) => {
    const itemKey = Object.keys(characterInventory).find(
      key => characterInventory[key].id === itemId
    );

    const updatedItemList = Object.assign([...characterInventory], {
      [itemKey]: { ...characterInventory[itemKey], details: e },
    });

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }

    setCharacterInventory(updatedItemList);

    setItemDetails(e);

    autoSaveTimer.current = setTimeout(() => {
      AutoSaveInventoryData(itemId, "details", e);
    }, 1000);
  };

  const handleDeleteItem = itemId => {
    const charId = localStorage.getItem("currentCharacterId");
    const itemDoc = doc(charactersCol, charId, "charInventory", itemId);

    deleteDoc(itemDoc);

    const filterInventoryList = characterInventory.filter(
      item => item.id !== itemId
    );

    setCharacterInventory(filterInventoryList);
  };

  return (
    <Accordion.Item key={itemId} id={itemId} eventKey={itemId}>
      <Accordion.Header>{itemName}</Accordion.Header>
      <Accordion.Body>
        <Row>
          <Col>
            <h4 className="text-primary">Name</h4>
            <input
              id="name"
              value={itemName}
              data-id={itemId}
              onChange={handleSetItemName}
            />
          </Col>
          <Col>
            <h4 className="text-primary">Slots</h4>
            <input
              id="slots"
              value={itemSlots ? itemSlots : ""}
              data-id={itemId}
              onChange={handleSetItemSlots}
            />
          </Col>
        </Row>
        <h4 className="text-primary">Details</h4>
        <QuillEditor
          id="details"
          value={itemDetails}
          change={e => handleSetItemRichData(e, itemId)}
        />
        <Button
          className="btn-secondary"
          onClick={() => handleDeleteItem(itemId)}
        >
          Delete Item
        </Button>
      </Accordion.Body>
    </Accordion.Item>
  );
}

function AddItemButton() {
  const { setCharacterInventory } = useContext(CharacterInventoryContext);

  const handleAddItem = () => {
    const ownerId = auth.currentUser.uid;
    const charId = localStorage.getItem("currentCharacterId");
    const CharInventoryCol = collection(charactersCol, charId, "charInventory");

    addDoc(CharInventoryCol, {
      name: "_Item",
      details: "",
      owner: ownerId,
    }).then(doc => {
      updateDoc(doc, {
        id: doc.id,
      }).then(() => {
        getDoc(doc).then(docSnap => {
          const docData = docSnap.data();

          // check if inventory array already exists and append new item
          setCharacterInventory(characterInventory => [
            ...(characterInventory || []),
            docData,
          ]);
        });
      });
    });
  };

  return (
    <Button className="btn-primary" onClick={handleAddItem}>
      Add Item
    </Button>
  );
}
