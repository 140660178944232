import { InputGroup, Button } from "react-bootstrap";

/**
 * Renders a number input component.
 *
 * @param {string} id - The unique identifier for the input field.
 * @param {number} value - The current value of the input field.
 * @param {boolean} justify - Determines if the input field should be justified.
 * @param {function} change - The callback function to be called when the input value changes.
 * @returns {JSX.Element} The rendered number input component.
 */
function NumberInput({ id, value, justify, change }) {
  const handleModifyInput = modifier => {
    const inputField = document.getElementById(id);
    const prevValue = parseInt(inputField.value);

    if (modifier === "add") {
      inputField.value = prevValue + 1;
      change(id);
    } else if (modifier === "subtract") {
      inputField.value = prevValue - 1;
      change(id);
    }
  };

  return (
    <InputGroup
      className={
        justify
          ? "mb-3 d-flex justify-content-center border rounded"
          : "mb-3 d-flex border rounded"
      }
    >
      <Button
        variant="link"
        onClick={() => handleModifyInput("subtract")}
        style={{ textDecoration: "none" }}
      >
        -
      </Button>
      <input
        id={id}
        className="data form-control number-input border-0"
        size={6}
        value={value || ""}
        onChange={() => change(id)}
      />
      <Button
        variant="link"
        onClick={() => handleModifyInput("add")}
        style={{ textDecoration: "none" }}
      >
        +
      </Button>
    </InputGroup>
  );
}

export default NumberInput;
