import RestingEffects from "../../../components/RulesReference/RestingEffects";
import Section from "../../../components/Section";

function Resting() {
  return (
    <Section>
      <h1 className="title-header">Resting</h1>
      <p>
        When you find somewhere safe to lie down, eat some food, or just lean up
        against something for a while, you can take a rest.
      </p>
      <RestingEffects />
    </Section>
  );
}

export default Resting;
