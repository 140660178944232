import React from "react";
import { useState } from "react";
import { LoadLwCharacter } from "./leviathansWake/LoadLwCharacter";
import { LoadEbCharacter } from "./emberbrand/LoadEbCharacter";

function LoadCharacterButton({ gameId }) {
  const [currentGame, setCurrentGame] = useState(gameId);

  return (
    <div>
      {currentGame === "eb" ? (
        <LoadEbCharacter />
      ) : currentGame === "lw" ? (
        <LoadLwCharacter />
      ) : null}
    </div>
  );
}

export default LoadCharacterButton;
