import { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CharacterDataContext } from "../../hooks/UserContext";
import { AutoSaveCharacter } from "../../backend/AutoSaveCharacter";
import NumberInput from "./NumberInput";

function TraumaRow({ traumaThreshold }) {
  const { currentCharacter, setCurrentCharacter } =
    useContext(CharacterDataContext);
  const [thresholdExceeded, setThresholdExceeded] = useState(false);
  const autoSaveTimer = useRef(null);

  useEffect(() => {
    currentCharacter?.trauma > traumaThreshold
      ? setThresholdExceeded(true)
      : setThresholdExceeded(false);
  }, [currentCharacter?.trauma, traumaThreshold, currentCharacter?.shaken]);

  const ThresholdExceeded = () => (
    <div id="trauma-threshold-exceeded" className="text-danger">
      <p>
        You've taken too much mental trauma! Whenever you suffer trauma above
        your TT, roll on the harm table and add the trauma above your TT to the
        roll.
      </p>
    </div>
  );

  const handleSetCharacterData = id => {
    const value = document.getElementById(id).value;

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }
    setCurrentCharacter(prevValue => ({
      ...prevValue,
      [id]: value,
    }));

    autoSaveTimer.current = setTimeout(() => {
      AutoSaveCharacter(id, value);
    }, 1000);
  };

  return (
    <>
      <h2 className={thresholdExceeded ? "text-danger" : "text-primary"}>
        Trauma Threshold ({traumaThreshold})
      </h2>
      {thresholdExceeded ? <ThresholdExceeded /> : null}
      <Row>
        <Col xs={6}>
          <h3 className="text-primary">TT Modifier</h3>
          <NumberInput
            id="bonusTraumaThreshold"
            value={currentCharacter?.bonusTraumaThreshold}
            justify={true}
            change={handleSetCharacterData}
          />
        </Col>
        <Col xs={6}>
          <h3 className="text-primary">Trauma</h3>
          <NumberInput
            id="trauma"
            value={currentCharacter?.trauma}
            justify={true}
            change={handleSetCharacterData}
          />
        </Col>
        <ShakenCheck shaken={currentCharacter?.shaken} />
      </Row>
      <hr />
    </>
  );
}

function ShakenCheck({ shaken }) {
  const [isShaken, setIsShaken] = useState(false);
  const autoSaveTimer = useRef(null);

  useEffect(() => {
    if (shaken) {
      setIsShaken(shaken);
    }
  }, [shaken]);

  const handleSetShakenData = () => {
    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }

    setIsShaken(!isShaken);

    autoSaveTimer.current = setTimeout(() => {
      AutoSaveCharacter("shaken", !isShaken);
    }, 1000);
  };

  return (
    <Row className="text-left">
      <div className="magnitude-row">
        <h2 className="text-primary">Shaken</h2>
        <input
          id="shaken"
          type="checkbox"
          className="treated form-check-input checkbox-lg"
          checked={isShaken}
          onChange={handleSetShakenData}
          style={{ width: "30px", height: "30px" }}
        />
      </div>
    </Row>
  );
}

export default TraumaRow;
