export function Attributes() {
  return (
    <div>
      <p>
        <b>Attribute:</b> A character's core abilities, rated from 1 to 5.
        Attributes are used to determine the size of a character's dice pool
        when making moves.
      </p>
    </div>
  );
}

export function Core() {
  return (
    <div>
      <p>
        <b>Core:</b> The dice pool that a character uses when making moves. A
        character's core dice pool is determined by their attributes.
      </p>
    </div>
  );
}

export function Curse() {
  return (
    <div>
      <p>
        <b>Curse:</b> A condition that affects a character's actions and
        decisions. Curses are gained by making certain moves or by facing
        certain threats.
      </p>
    </div>
  );
}

export function Harm() {
  return (
    <div>
      <p>
        <b>Harm:</b> A condition that represents physical, mental, or spiritual
        damage. Harm is gained by making certain moves or by facing certain
        threats.
      </p>
    </div>
  );
}

export function Move() {
  return (
    <div>
      <p>
        <b>Move:</b> A set of rules that governs a specific action or situation.
        Moves are used to resolve actions and progress the story.
      </p>
    </div>
  );
}

export function Progress() {
  return (
    <div>
      <p>
        <b>Progress:</b> A measure of how close a character is to achieving
        their goal. Progress is gained by making certain moves or by facing
        certain threats.
      </p>
    </div>
  );
}
