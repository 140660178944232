import { Button, Form, Modal, Row } from "react-bootstrap";
import Section from "../../Section";
import { useState, useContext } from "react";
import { LwCharacterDataContext } from "../../../hooks/leviathansWake/LwContext";
import { auth } from "../../../firebaseSetup";
import { FaPlusSquare } from "react-icons/fa";
import Pursuit from "./Pursuit";
import { v4 as uuidv4 } from "uuid";

function LwPursuitsTab() {
  const { lwCurrentCharacter, setLwCurrentCharacter } = useContext(
    LwCharacterDataContext
  );
  const [showAddModal, setShowAddModal] = useState(false);
  const [newPursuitName, setNewPursuitName] = useState("");
  const [chosenChallenge, setChosenChallenge] = useState("");
  const [chosenPursuitType, setChosenPursuitType] = useState(null);

  const sortedPursuits = lwCurrentCharacter?.pursuits?.sort((a, b) => {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  const handleShowAddModal = pursuitType => {
    setShowAddModal(true);
    setChosenPursuitType(pursuitType);
  };

  const handleCloseAddModal = () => setShowAddModal(false);

  const handlesetNewPursuitName = e => {
    setNewPursuitName(e.target.value);
  };

  const handleChange = e => {
    setChosenChallenge(e.target.value);
  };

  const handleAddPursuit = () => {
    console.log(
      `Adding pursuit ${newPursuitName} with challenge level ${chosenChallenge}`
    );
    handleCloseAddModal();

    const ownerId = auth.currentUser.uid;
    const pursuitId = uuidv4();

    const newPursuit = {
      name: newPursuitName,
      challenge: chosenChallenge,
      owner: ownerId,
      id: pursuitId,
      progress: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      type: chosenPursuitType,
    };

    if (!lwCurrentCharacter.pursuits) {
      setLwCurrentCharacter(prevState => ({
        ...prevState,
        pursuits: [newPursuit],
      }));
    } else {
      setLwCurrentCharacter(prevState => ({
        ...prevState,
        pursuits: [...(prevState.pursuits || []), newPursuit],
      }));
    }

    setChosenPursuitType(null);
  };

  return (
    <Section>
      <Row>
        <Button
          id="add-pursuit-button"
          className="pursuit-header"
          variant="link"
          onClick={() => handleShowAddModal(0)}
        >
          <h1 className="title-header">
            Pursuits <FaPlusSquare />
          </h1>
        </Button>
      </Row>

      {
        // Render pursuits with type 0
        sortedPursuits?.map(pursuit => {
          let pursuitElement = null;
          if (pursuit.type === 0) {
            pursuitElement = (
              <div>
                <Row key={pursuit.id} style={{ marginBottom: "30px" }}>
                  <Pursuit
                    key={pursuit.id}
                    pursuitIndex={pursuit.id}
                    pursuitId={pursuit.id}
                    pursuitName={pursuit.name}
                    challengeLevel={pursuit.challenge}
                  />
                </Row>
                <hr />
              </div>
            );
          }
          return pursuitElement;
        })
      }

      <Row>
        <Button
          id="add-progress-button"
          className="pursuit-header"
          variant="link"
          onClick={() => handleShowAddModal(1)}
        >
          <h1 className="title-header">
            Progress <FaPlusSquare />
          </h1>
        </Button>
      </Row>

      {
        // Render pursuits with type 1
        sortedPursuits?.map(pursuit => {
          let pursuitElement = null;
          if (pursuit.type === 1) {
            pursuitElement = (
              <div>
                <Row key={pursuit.id} style={{ marginBottom: "30px" }}>
                  <Pursuit
                    key={pursuit.id}
                    pursuitIndex={pursuit.id}
                    pursuitId={pursuit.id}
                    pursuitName={pursuit.name}
                    challengeLevel={pursuit.challenge}
                  />
                </Row>
                <hr />
              </div>
            );
          }
          return pursuitElement;
        })
      }

      {/* New pursuit modal */}
      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Add{" "}
            {chosenPursuitType === 0
              ? "Pursuit"
              : chosenPursuitType === 1
              ? "Progress"
              : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <input
              type="text"
              id="new-pursuit"
              name="new-pursuit"
              placeholder="Description"
              className="form-control"
              onChange={handlesetNewPursuitName}
            />
            <h4>Challenge Level</h4>
            <Form.Group controlId="new-pursuit-challenge-level">
              <Form.Check
                type="radio"
                name="challenge"
                label="Troublesome"
                value={"Troublesome"}
                onChange={handleChange}
              />
              <Form.Check
                type="radio"
                name="challenge"
                label="Dangerous"
                value={"Dangerous"}
                onChange={handleChange}
              />
              <Form.Check
                type="radio"
                name="challenge"
                label="Perilous"
                value={"Perilous"}
                onChange={handleChange}
              />
              <Form.Check
                type="radio"
                name="challenge"
                label="Legendary"
                value={"Legendary"}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddPursuit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </Section>
  );
}

export default LwPursuitsTab;
