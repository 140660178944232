import { Link } from "react-router-dom";
import Section from "../../../components/Section";
import AttackAction from "../../../components/RulesReference/AttackAction";

function Attacking() {
  return (
    <>
      <Section>
        <h1 className="title-header">Attacking</h1>
        <p>
          To make an attack, choose a weapon you have equipped or, if you don't
          have a weapon equipped, attack with an unarmed strike or grapple
          attack. If you choose to attack with a weapon, that weapon's
          characteristics will determine what you can target and how much damage
          you'll deal.
        </p>
        <hr />
        <AttackAction />
        <hr />
        <h2>Damage</h2>
        <p>
          If your attack check succeeds, make a damage roll using the die
          indicated by the weapon's damage characteristic. This die is called
          the damage die. If an effect instructs you to roll additional damage
          dice without specifying a dice level, each of those additional damage
          dice must be of the same level as the original.
        </p>
        <p>
          Damage may be modified by the target's defenses or any special
          abilities. If an effect changes the level of your damage die, do so
          before making your damage roll. Calculate any other damage
          modifications after making your damage roll.
        </p>
        <h2>Tags</h2>
        <p>
          <Link to="/rules/weapons" className="link">
            Weapon tags
          </Link>{" "}
          are keyword properties given to a weapon. Each tag helps describe a
          weapon and provides a mechanical effect.
        </p>
        <p>
          Some weapons have additional special effects which don't fall under a
          tag. These special effects will be written out as part of the weapon
          profile and modify or enhance the weapon in some way.
        </p>
      </Section>
    </>
  );
}

export default Attacking;
