function LwBasics() {
  return (
    <div>
      <h1>Leviathans Wake Basics</h1>
      <p>
        This is the basics page for Leviathans Wake. It's a work in progress.
      </p>
    </div>
  );
}

export default LwBasics;