import LwSummarySection from "./LwSummarySection";
import LwSkillsSection from "./LwSkillsSection";
import Section from "../../Section";
import LwGearSection from "./LwGearSection";
import LwTalentsSection from "./LwTalentsSection";
import LwCriticalConditionsSection from "./LwCriticalConditionsSection";
import { Accordion } from "react-bootstrap";
import LwCharacterDetailsSection from "./LwCharacterDetailsSection";
import { useContext } from "react";
import { useAutosave } from "react-autosave";
import { AutoSaveLwCharacter } from "../../../backend/AutoSaveCharacter";
import { LwCharacterDataContext } from "../../../hooks/leviathansWake/LwContext";

function updateCharacter(data) {
  AutoSaveLwCharacter(data);
}

function LwCharacterTab() {
  const { lwCurrentCharacter } = useContext(LwCharacterDataContext);

  useAutosave({
    data: lwCurrentCharacter,
    onSave: updateCharacter,
  });

  return (
    <div style={{ marginBottom: "3em" }}>
      <Section>
        <LwSummarySection />
        <LwSkillsSection />
        <Accordion>
          <Accordion.Item eventKey="talents-accordion">
            <Accordion.Header>Talents</Accordion.Header>
            <Accordion.Body>
              <LwTalentsSection />
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="gear-accordion">
            <Accordion.Header>Gear</Accordion.Header>
            <Accordion.Body>
              <LwGearSection />
            </Accordion.Body>
          </Accordion.Item> */}
          <Accordion.Item eventKey="conditions-accordion">
            <Accordion.Header>Severe Conditions</Accordion.Header>
            <Accordion.Body>
              <LwCriticalConditionsSection />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="details-accordion">
            <Accordion.Header>Character Details</Accordion.Header>
            <Accordion.Body>
              <LwCharacterDetailsSection />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Section>
    </div>
  );
}

export default LwCharacterTab;
