import { createContext, useContext, useEffect, useState } from "react";
import { getDocs } from "firebase/firestore";
import { abilitiesCol, enemiesCol } from "../firebaseSetup";
import { UserContext } from "./UserContext";

export const AbilityContext = createContext(null);
export const EnemyContext = createContext(null);

export function EmberbrandContext({ children }) {
  const [abilityList, setAbilityList] = useState([]);
  const [enemyList, setEnemyList] = useState([]);
  const currentUser = useContext(UserContext);

  //   const enemyQuery = query(enemiesCol, where("name", "==", enemy));
  useEffect(() => {
    getDocs(enemiesCol).then(snapshot => {
      snapshot.docs.forEach(doc => {
        const docData = doc.data();
        const enemyData = { ...docData, id: doc.id };

        setEnemyList(enemyList => [...enemyList, enemyData]);
      });
    });

    getDocs(abilitiesCol).then(snapshot => {
      snapshot.docs.forEach(doc => {
        const docData = doc.data();
        const abilityData = { ...docData, id: doc.id };

        setAbilityList(abilityList => [...abilityList, abilityData]);
      });
    });
  }, [currentUser]);

  return (
    <AbilityContext.Provider value={{ abilityList }}>
      <EnemyContext.Provider value={{ enemyList }}>
        {children}
      </EnemyContext.Provider>
    </AbilityContext.Provider>
  );
}
