import React from "react";
import Titlebar from "../components/Titlebar";
import MainContainer from "../components/MainContainer";

const About = () => {
  const versionNumber = process.env.REACT_APP_VERSION;
  const rulesVersionNumber = process.env.REACT_APP_EMBERBRAND_RULES_VERSION;
  const leviathanRulesVersionNumber =
    process.env.REACT_APP_LEVIATHAN_RULES_VERSION;

  return (
    <>
      <Titlebar>About This Site</Titlebar>
      <MainContainer>
        <div className="text-center">
          <p>App version: {versionNumber}</p>
          <p>Emberbrand Rules version: {rulesVersionNumber}</p>
          <p>Leviathan's Wake Rules version: {leviathanRulesVersionNumber}</p>
        </div>
      </MainContainer>
    </>
  );
};

export default About;
