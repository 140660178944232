import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import SignIn from "../hooks/SignIn";
import { UserContext, UserPrefsContext } from "../hooks/UserContext";
import { NavDropdown } from "react-bootstrap";

function Navigation() {
  const currentUser = useContext(UserContext);
  const userPrefs = useContext(UserPrefsContext);
  const [userTheme, setUserTheme] = useState("light");

  useEffect(() => {
    if (userPrefs !== null) {
      setUserTheme(userPrefs.userPrefs?.theme);
    }
  }, [userPrefs]);

  return (
    <Navbar
      sticky="top"
      expand="md"
      className={`bg-${userTheme}`}
      collapseOnSelect
    >
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <img
            src={"./assets/emberbrand-logo.png"}
            alt="Emberbrand Logo"
            width={100}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="Emberbrand" id="emberbrand-dropdown">
              <Nav.Link as={Link} to="/emberbrand/rules" eventKey="1">
                Rules
              </Nav.Link>
              <Nav.Link as={Link} to="/emberbrand/character-sheet" eventKey="2">
                Character Sheet
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/emberbrand/campaign-manager"
                eventKey="3"
              >
                Campaign Manager
              </Nav.Link>
            </NavDropdown>
            <NavDropdown title="Leviathan's Wake" id="lw-dropdown">
              <Nav.Link as={Link} to="/leviathans-wake/lw-rules" eventKey="1">
                Rules
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/leviathans-wake/character-sheet"
                eventKey="2"
              >
                Character Sheet
              </Nav.Link>
              {currentUser?.currentUser?.uid === "y9tH4PZzyaRKF9f0XjMbrnaV4to2" ? (
                <Nav.Link
                  as={Link}
                  to="/leviathans-wake/lw-admin"
                  eventKey="3"
                >
                  Admin Tools
                </Nav.Link>
              ) : null}
            </NavDropdown>
          </Nav>
          <Nav>
            <SignIn />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
