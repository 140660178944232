import { useContext, useState } from "react";
import { LwCharacterDataContext } from "../../../hooks/leviathansWake/LwContext";
import Section from "../../Section";
import QuillEditor from "../../QuillEditor";
import { AutoSaveLwCharacter } from "../../../backend/AutoSaveCharacter";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FaPlusSquare } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { Accordion, Modal } from "react-bootstrap";
import { useAutosave } from "react-autosave";
import { v4 as uuidv4 } from "uuid";

function updateJournals(data) {
  AutoSaveLwCharacter(data);
}

function LwJournalTab() {
  const { lwCurrentCharacter, setLwCurrentCharacter } = useContext(
    LwCharacterDataContext
  );
  useAutosave({ data: lwCurrentCharacter, onSave: updateJournals });

  const handleAddJournal = () => {
    const journalId = uuidv4();

    const newJournal = {
      id: journalId,
      name: "New Journal Entry",
      content: "",
    };

    // if there are no journals, create a new array with the new journal
    if (!lwCurrentCharacter.journals) {
      setLwCurrentCharacter(prevState => ({
        ...prevState,
        journals: [newJournal],
      }));
      return;
    } else {
      setLwCurrentCharacter(prevState => ({
        ...prevState,
        journals: [...prevState.journals, newJournal],
      }));
    }
  };

  return (
    <Section>
      <Row>
        <Button
          className="pursuit-header"
          id="add-journal-button"
          variant="link"
          onClick={handleAddJournal}
        >
          <h1 className="title-header">
            Journal <FaPlusSquare />
          </h1>
        </Button>
      </Row>
      <Accordion>
        {lwCurrentCharacter?.journals?.length === 0 && (
          <p>No journal entries added yet.</p>
        )}
        {lwCurrentCharacter?.journals?.map(journal => {
          return (
            <Accordion.Item key={journal.id} eventKey={journal.id}>
              <Accordion.Header>{journal.name}</Accordion.Header>
              <Accordion.Body>
                <Row key={journal.id}>
                  <Col>
                    <Journal
                      journalIndex={journal.id}
                      journalId={journal.id}
                      journalName={journal.name}
                      journalContent={journal.content}
                    />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </Section>
  );
}

function Journal({ journalId, journalName, journalContent }) {
  const { lwCurrentCharacter, setLwCurrentCharacter } = useContext(
    LwCharacterDataContext
  );
  const [journalNameState, setJournalNameState] = useState(journalName);
  const [journalContentState, setJournalContentState] =
    useState(journalContent);
  const [show, setShow] = useState(false);

  const handleSetJournalName = value => {
    setJournalNameState(value);

    const updatedJournals = lwCurrentCharacter.journals.map(journal => {
      if (journal.id === journalId) {
        journal.name = value;
      }
      return journal;
    });

    setLwCurrentCharacter(prevState => ({
      ...prevState,
      journals: updatedJournals,
    }));
  };

  const handleSetJournalContent = (e, journalId) => {
    const journalKey = Object.keys(lwCurrentCharacter.journals).find(
      key => lwCurrentCharacter.journals[key].id === journalId
    );

    const updatedJournalList = Object.assign([...lwCurrentCharacter.journals], {
      [journalKey]: { ...lwCurrentCharacter.journals[journalKey], content: e },
    });

    setLwCurrentCharacter(prevState => ({
      ...prevState,
      journals: updatedJournalList,
    }));

    setJournalContentState(e);
  };

  const handleDeleteJournal = () => {
    const filteredJournals = lwCurrentCharacter.journals.filter(
      journal => journal.id !== journalId
    );

    // Delete the journal from the state
    setLwCurrentCharacter(prevState => ({
      ...prevState,
      journals: filteredJournals,
    }));
  };

  const handleCloseModal = () => setShow(false);

  const handleShowModal = () => setShow(true);

  return (
    <>
      <Row id={journalId}>
        <Col xs={10}>
          <input
            id={`${journalId}-name`}
            className="form-control"
            placeholder="New Journal Entry"
            value={journalNameState}
            onChange={e => handleSetJournalName(e.target.value)}
            style={{ marginBottom: "10px" }}
          />
        </Col>
        <Col xs={2}>
          <Button
            id={`${journalId}-delete-button`}
            variant="link"
            onClick={handleShowModal}
          >
            <FaTrash />
          </Button>
        </Col>
        <QuillEditor
          id={`${journalId}-content`}
          value={journalContentState}
          defaultPlaceholder="Session notes, scene details, character journals..."
          change={e => handleSetJournalContent(e, journalId)}
        />
      </Row>

      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Journal Entry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this journal entry?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteJournal}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LwJournalTab;
