import { Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import MoneyLoot from "../../../pages/rules/MoneyLoot";
import Wealth from "../../../pages/rules/money-loot/Wealth";
import Weapons from "../../../pages/rules/ItemsEquipment/Weapons";
import Items from "../../../pages/rules/ItemsEquipment/Items";

export default (
  <Route path="wealth-items">
    <Route path="wealth-items-equipment" element={<MoneyLoot />} />
    <Route path="wealth" element={<Wealth />} />
    <Route path="weapons" element={<Weapons />} />
    <Route path="items" element={<Items />} />
    <Route index element={<Navigate to="wealth" />} />
  </Route>
);
