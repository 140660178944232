import { useState } from "react";
import { Collapse, Nav } from "react-bootstrap";
import ExampleSection from "../ExampleSection";

function ReferenceHeader({ id, title, defaultTab, defaultEventKey, tabs }) {
  const [referenceOpen, setReferenceOpen] = useState(false);
  const [selectedEventKey, setSelectedEventKey] = useState(defaultEventKey);
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const handleSelect = (tab, eventKey) => {
    setSelectedTab(tab);
    setSelectedEventKey(eventKey);
  };

  return (
    <>
      <h1 className="title-header">
        {title}
        <div className="help">
          <button
            className="btn btn-secondary help-button"
            onClick={() => setReferenceOpen(!referenceOpen)}
            aria-controls={id}
            aria-expanded={referenceOpen}
          >
            ?
          </button>
        </div>
      </h1>
      <Collapse in={referenceOpen}>
        <div id={id} className="text-left">
          <ExampleSection>
            <Nav
              variant="tabs"
              defaultActiveKey={selectedEventKey}
              style={{ marginBottom: "20px" }}
            >
              {tabs.map((tab) => (
                <Nav.Item key={tab.id}>
                  <Nav.Link
                    eventKey={tab.eventKey}
                    onClick={() => handleSelect(tab.content, tab.eventKey)}
                  >
                    {tab.title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            {selectedTab}
          </ExampleSection>
        </div>
      </Collapse>
    </>
  );
}

export default ReferenceHeader;
