import { Table } from "react-bootstrap";

function RangeBands() {
  return (
    <Table striped>
      <thead>
        <tr>
          <th>Range</th>
          <th>Description</th>
          {/* <th>Difficulty</th> */}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Adjacent</th>
          <td>Your target is within arm's reach.</td>
          {/* <td>+20%</td> */}
        </tr>
        <tr>
          <th>Close</th>
          <td>
            Your target is just a few steps away (5 m / 15 ft). You can easily
            get close enough to attack with a melee weapon or grapple your
            target.
          </td>
          {/* <td>+0%</td> */}
        </tr>
        <tr>
          <th>Short</th>
          <td>
            Your target is up to several meters away (20 m / 60 ft). You could
            be within the same room or short enough that you could rush them with
            a melee weapon, hit them with a throwing dagger, or accurately fire
            a small firearm at them.
          </td>
          {/* <td>+10%</td> */}
        </tr>
        <tr>
          <th>Medium</th>
          <td>
            Your target is up to several dozen meters away (50 m / 150 ft). A
            longer range weapon is needed to accurately hit your target. You can
            move normally from short range to medium range while wearing no gear
            or light gear.
          </td>
          {/* <td>+20%</td> */}
        </tr>
        <tr>
          <th>Long</th>
          <td>
            Your target is over several dozen meters away (100 m / 300 ft).
            Unless you were yelling at the top of your lungs, you'd have a hard
            time hearing someone at long range.
          </td>
          {/* <td>+40%</td> */}
        </tr>
      </tbody>
    </Table>
  );
}

export default RangeBands;
