import { Table } from "react-bootstrap";
import Section from "../../../components/Section";

export function SupplyRoll() {
  return (
    <Section>
      <SupplyRollReference />
    </Section>
  );
}

export function SupplyRollReference() {
  return (
    <>
      <h1 className="title-header">Supply Rolls</h1>
      <p>
        Many items have a supply tag associated with them. This represents
        ammunition, spell components, money, or other general supplies. Whenever
        you expend supplies, you make a supply roll. When you make your supply
        roll, you use the dice level which corresponds to your supply level.
      </p>
      <p>
        If you roll a 1 on your supply roll, you must decrease your supply
        level. Supply levels correspond to dice levels, so 1d12 would be
        decreased to 1d10, which would then be decreased to 1d8, and so on. If
        the supply level is decreased below 1d4, the supply has been entirely
        exhausted. You can't use the weapon or item, the spell or enchanted item
        must be recharged, or you have completely run out of money.
      </p>
      <p>
        You can increase your supply level through various means, such as
        purchasing more stock or looting treasure.
      </p>
      <h2>Supply Tags</h2>
      <p>
        Any item, piece of equipment, or concept with one of the following tags
        uses supply rolls.
      </p>
      <Table striped>
        <thead>
          <tr>
            <th>Tag</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Ammo</th>
            <td>
              Ranged weapons such as bows, crossbows, or slings. Any weapon
              which has ammunition that needs to be restocked.
            </td>
          </tr>
          <tr>
            <th>Charges</th>
            <td>
              Spells and enchanted items. These items can't be used if they run
              low until they're recharged.
            </td>
          </tr>
          <tr>
            <th>Supplies</th>
            <td>
              Packs full of useful things, kits with a specific use, or other
              items filled with goods.
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}
