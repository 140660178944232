import { useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";

export function RulesSectionCollapse({
  link,
  title,
  id,
  setExpanded,
  children,
}) {
  const [openCollapse, setOpenCollapse] = useState(false);

  return (
    <div className="rules-header">
      <Link to={link} className="rules-link" onClick={() => setExpanded(false)}>
        {title}
      </Link>
      <span
        onClick={() => setOpenCollapse(!openCollapse)}
        aria-controls={id}
        aria-expanded={openCollapse}
        className="collapse-icon btn-link"
        style={{ float: "right", verticalAlign: "center" }}
      >
        {openCollapse ? <BsFillCaretUpFill /> : <BsFillCaretDownFill />}
      </span>
      <Collapse in={openCollapse} className="rules-collapse">
        <div id={id}>{children}</div>
      </Collapse>
    </div>
  );
}

export function RulesLink({ link, title, setExpanded }) {
  return (
    <div className="rules-link-div">
      <Link to={link} onClick={() => setExpanded(false)} className="rules-link">
        {title}
      </Link>
    </div>
  );
}
