import React from "react";
import { Button, Card, Col } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";

function TalentCard({ talentName, removeTalent }) {
  const talentList = JSON.parse(sessionStorage.getItem("lwTalentList"));

  return (
    <Col xs={12} md={6}>
      <Card>
        <Card.Header>
          <Card.Title>{talentName}</Card.Title>
          <Card.Subtitle>
            {
              talentList?.filter(talent => talent.name === talentName)[0]
                ?.archetype
            }
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
          <p
            dangerouslySetInnerHTML={{
              __html: talentList?.filter(
                talent => talent.name === talentName
              )[0]?.effects,
            }}
          />
        </Card.Body>
        <Card.Footer>
          <Button
            style={{ float: "right" }}
            variant="danger"
            onClick={() => removeTalent(talentName)}
          >
            <FaTrash />
          </Button>
        </Card.Footer>
      </Card>
    </Col>
  );
}

export default TalentCard;
