import { useContext, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { useState } from "react";
import Titlebar from "../components/Titlebar";
import Nav from "react-bootstrap/Nav";
import MainContainer from "../components/MainContainer";
import LoadCharacterButton from "../hooks/LoadCharacter";
import { CharacterDataContext, UserPrefsContext } from "../hooks/UserContext";
import { LoadEbCharacter } from "../hooks/emberbrand/LoadEbCharacter";

const currentTab = sessionStorage.getItem("currentTab")
  ? sessionStorage.getItem("currentTab")
  : "CharacterTab";

function CharacterSheet() {
  const userPrefs = useContext(UserPrefsContext);
  const currentCharacter = useContext(CharacterDataContext);
  const [userTheme, setUserTheme] = useState("light");
  const [activeTab, setActiveTab] = useState(currentTab);
  const [characterTitle, setCharacterTitle] = useState(
    "Emberbrand Character Sheet"
  );

  // update character sheet title with loaded character name
  useEffect(() => {
    if (currentCharacter.currentCharacter?.name) {
      setCharacterTitle(currentCharacter.currentCharacter?.name);
    } else {
      setCharacterTitle("Emberbrand Character Sheet");
    }
  }, [currentCharacter]);

  useEffect(() => {
    if (userPrefs !== null) {
      setUserTheme(userPrefs.userPrefs?.theme);
    }
  }, [userPrefs]);

  const handleChangeTab = e => {
    const tab = e.target.getAttribute("target-tab");
    setActiveTab(tab);
    sessionStorage.setItem("currentTab", tab);
  };

  return (
    <div className="text-center">
      <Titlebar>
        <LoadEbCharacter title={characterTitle} />
        {/* <span id="character-name-span">{characterTitle}</span> */}
      </Titlebar>
      <Nav
        justify
        variant="pills"
        className={`bg-${userTheme} sticky-top container-fluid`}
        defaultActiveKey={activeTab}
        style={{
          padding: "0.5em",
          marginBottom: "20px",
          top: "81px",
          zIndex: 100,
        }}
      >
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="character"
            eventKey="CharacterTab"
            target-tab="CharacterTab"
            variant="secondary"
            onClick={e => handleChangeTab(e)}
          >
            Character
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="talents"
            eventKey="TalentsTab"
            target-tab="TalentsTab"
            onClick={e => handleChangeTab(e)}
          >
            Talents
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="inventory"
            eventKey="InventoryTab"
            target-tab="InventoryTab"
            onClick={e => handleChangeTab(e)}
          >
            Inventory
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <MainContainer>
        <Outlet />
      </MainContainer>
    </div>
  );
}

export default CharacterSheet;
