import Table from "react-bootstrap/Table";

function SkillMatrixReadOnly() {
  return (
    <Table bordered className="text-center" size="sm">
      <thead>
        <tr>
          <th scope="col" className="table-title bg-primary">
            Skills
          </th>
          <th scope="col">
            Body
            <br />
            30
          </th>
          <th scope="col">
            Mind
            <br />
            20
          </th>
          <th scope="col">
            Spirit
            <br />
            10
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">
            Power
            <br />
            10
          </th>
          <td>
            Strength
            <br />
            40%
          </td>
          <td>
            Focus
            <br />
            30%
          </td>
          <td>
            Luck
            <br />
            20%
          </td>
        </tr>
        <tr>
          <th scope="row">
            Prowess
            <br />
            20
          </th>
          <td>
            Agility
            <br />
            50%
          </td>
          <td>
            Dexterity
            <br />
            40%
          </td>
          <td>
            Survival
            <br />
            30%
          </td>
        </tr>
        <tr>
          <th scope="row">
            Charisma
            <br />
            20
          </th>
          <td>
            Presence
            <br />
            50%
          </td>
          <td>
            Cunning
            <br />
            40%
          </td>
          <td>
            Morale
            <br />
            30%
          </td>
        </tr>
        <tr>
          <th scope="row">
            Wisdom
            <br />
            20
          </th>
          <td>
            Investigation
            <br />
            50%
          </td>
          <td>
            Knowledge
            <br />
            40%
          </td>
          <td>
            Instinct
            <br />
            30%
          </td>
        </tr>
        <tr>
          <th scope="row">
            Constitution
            <br />
            10
          </th>
          <td>
            Resistance
            <br />
            40%
          </td>
          <td>
            Willpower
            <br />
            30%
          </td>
          <td>
            Endurance
            <br />
            20%
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default SkillMatrixReadOnly;
