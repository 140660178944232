import Section from "../../../components/Section";
import WeaponTags from "../../../components/RulesReference/WeaponTags";
import WeaponsList from "../../../components/RulesReference/WeaponsList";

function Weapons() {
  return (
    <>
      <Section>
        <h1 className="title-header">Weapons</h1>
        <WeaponsList />
        <h1 className="title-header">Weapon Tags</h1>
        <WeaponTags />
      </Section>
    </>
  );
}

export default Weapons;
