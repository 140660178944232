import { useEffect, useState } from "react";

function ProgressClock({ clockId, challenge, progress, click }) {
  const [currentProgress, setCurrentProgress] = useState(progress);

  // Declare theme colors for each theme
  const themeColors = {
    "bridge-city": "#96e7b9",
    "cherry-blossom": "#ffa7a6",
    coven: "#9b96a3",
    dark: "#8b9dca",
    light: "#3d5ba6",
    nomad: "#af9cd9",
    ominous: "#925aa2",
    spectral: "#88b0bf",
  };

  // Get challenge to lower case
  challenge = challenge.toLowerCase();

  const currentTheme = localStorage.getItem("theme") || "light";
  const progressMultiplier = {
    troublesome: 100,
    dangerous: 50,
    perilous: 25,
    legendary: 16.67,
  };

  useEffect(() => {
    setCurrentProgress(progress);
  }, [progress]);

  const handleClick = () => {
    if (currentProgress * progressMultiplier[challenge] < 100) {
      setCurrentProgress(currentProgress + 1);
    } else if (currentProgress * progressMultiplier[challenge] >= 100) {
      setCurrentProgress(0);
    }

    click();
  };

  return (
    <div
      id={clockId}
      className="progress-clock"
      style={{
        backgroundImage: `conic-gradient(${themeColors[currentTheme]} ${
          currentProgress * progressMultiplier[challenge]
        }%, transparent 0)`,
      }}
      onClick={handleClick}
    >
      <div className="progress-clock-color" />
    </div>
  );
}

export default ProgressClock;
