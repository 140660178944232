import { Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Magic from "../../../pages/rules/magic/Magic";
import MagicSchools from "../../../pages/rules/magic/MagicSchools";
import Evocation from "../../../pages/rules/magic/Evocation";
import Illusion from "../../../pages/rules/magic/Illusion";
import Divination from "../../../pages/rules/magic/Divination";
import Abjuration from "../../../pages/rules/magic/Abjuration";
import Enchanting from "../../../pages/rules/magic/Enchanting";
import Conjuration from "../../../pages/rules/magic/Conjuration";
import Necromancy from "../../../pages/rules/magic/Necromancy";
import Transmutation from "../../../pages/rules/magic/Transmutation";
import SpellCrafting from "../../../pages/rules/magic/SpellCrafting";
import MagicEffects from "../../../pages/rules/magic/MagicEffects";

export default (
  <Route path="magic">
    <Route path="magic-alchemy" element={<Magic />} />
    <Route path="magic-disciplines" element={<MagicSchools />} />
    <Route path="magic-evocation" element={<Evocation />} />
    <Route path="magic-illusion" element={<Illusion />} />
    <Route path="magic-divination" element={<Divination />} />
    <Route path="magic-abjuration" element={<Abjuration />} />
    <Route path="magic-enchanting" element={<Enchanting />} />
    <Route path="magic-conjuration" element={<Conjuration />} />
    <Route path="magic-necromancy" element={<Necromancy />} />
    <Route path="magic-transmutation" element={<Transmutation />} />
    <Route path="spellcrafting" element={<SpellCrafting />} />
    <Route path="magic-effects" element={<MagicEffects />} />
    <Route index element={<Navigate to="magic-disciplines" />} />
  </Route>
);
