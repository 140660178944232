import MagicIngredient from "../../../components/MagicIngredient";
import Section from "../../../components/Section";

function Evocation() {
  return (
    <Section>
      <h1 className="title-header">Evocation</h1>
      <p>
        Evocation spells are characterized by their ability to unleash
        devastating attacks, conjure elemental phenomena, and create explosive
        bursts of energy. Evokers can call forth searing flames to engulf
        enemies, summon bolts of lightning to strike from the heavens, freeze
        foes in their tracks with icy blasts, and generate destructive
        shockwaves that shatter barriers. While Evocation is synonymous with
        offensive power, skilled practitioners can also manipulate elemental
        forces for utility purposes, such as creating light, igniting flammable
        objects, or even generating protective barriers.
      </p>
      <p>
        The study of Evocation demands a keen understanding of the fundamental
        elements and their interactions. Evokers often undergo rigorous training
        to control the intense energies they command, preventing unintended
        consequences or catastrophic backlashes. As masters of elemental
        manipulation, Evokers can reshape battles and alter the course of events
        by wielding the forces of nature with precision and mastery.
      </p>
      <hr />
      <MagicIngredient
        number={1}
        name="Emberthorn"
        cost="Poor"
        tags="Evocation, Fire"
      >
        <p>
          <b>Damage:</b> 1d4
        </p>
        <p>
          <b>Effect:</b> Create a small flame that bursts forth. If the flame
          touches a flammable material, a fire starts.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={2}
        name="Bramblesnatch"
        cost="Poor"
        tags="Evocation"
      >
        <p>
          <b>Damage:</b> 1d4
        </p>
        <p>
          <b>Effect:</b> Summon painful, thorny vines that entangle any
          character targeted. Entangled characters cannot move and must succeed
          a Strength check to break free, suffering damage on a failure or
          partial success. Small characters have disadvantage to break free,
          while large characters have advantage.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={3}
        name="Frostglade Fern"
        cost="Poor"
        tags="Evocation, Frost"
      >
        <p>
          <b>Effect:</b> Freeze the air, creating frigid conditions. A character
          dealt damage by this effect is slowed. Prolonged exposure may cause
          frostbite.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={4}
        name="Sunseeker Vine"
        cost="Poor"
        tags="Evocation, Radiant"
      >
        <p>
          <b>Effect:</b> Emit a light to illuminate the area within close range
          as brightly as a torch. If damage is applied to this effect, it can
          only harm undead, dark, or abyssal characters.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={5}
        name="Stormcalla Lily"
        cost="Modest"
        tags="Evocation, Lightning"
      >
        <p>
          <b>Damage:</b> 1d4
        </p>
        <p>
          <b>Effect:</b> Discharge a bolt of electricity. This effect gains the{" "}
          <b>Ignore Armor</b> tag if target character is wearing metal armor.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={6}
        name="Searoot"
        cost="Poor"
        tags="Evocation, Water"
      >
        <p>
          <b>Effect:</b> Pour up to 5 gallons of clean water to fill a container
          or drench the area.
        </p>
        <p>
          This ingredient can be added to an effect multiple times. Each time
          it's added, increase the spellcrafting difficulty by 5% or the
          spellcrafting time by 2 hours, and add another 5 gallons of water
          created by this effect.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={7}
        name="Toxinshade Mushroom"
        cost="Modest"
        tags="Evocation, Poison"
      >
        <p>
          <b>Effect:</b> Create a poison which causes sickness. A character
          dealt damage by this effect suffers a physical illness injury and
          becomes sick. Whenever a poisoned character takes an action, they must
          first make a Resistance check. On a failure, they suffer this effect's
          damage, or half on a partial success.
        </p>
        <p>
          <b>Reminder:</b> A sick character's Constitution skill checks are 10%
          harder if their illness is a light injury. The character has
          disadvantage on all Constitution skill checks instead if the illness
          is heavy or worse.
        </p>
      </MagicIngredient>
      <MagicIngredient
        number={8}
        name="Zephyrbloom Orchid"
        cost="Comfortable"
        tags="Evocation, Wind"
      >
        <p>
          <b>Effect:</b> Manipulate wind currents to create gusts that can push
          objects or characters. A character dealt damage by this effect is
          pushed up to close range from where they started.
        </p>
        <p>
          This ingredient can be added to an effect multiple times. Each time
          it's added, increase the spellcrafting difficulty by 10% or the
          spellcrafting time by 4 hours, and increase the range it can push a
          character to the next range up.
        </p>
      </MagicIngredient>
    </Section>
  );
}

export default Evocation;
