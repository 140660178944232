import Section from "../../components/Section";

function Exertion() {
  return (
    <>
      <Section>
        <h1 className="title-header">Exertion</h1>
        <p>
          Exertion allows you to push past your limits, tapping into your very
          core to overcome difficult challenges. You may exert yourself to force
          a failed skill check to succeed instead, but you must be careful not
          to overexert yourself.
        </p>
        <p>
          You can only exert a skill check. You can never exert to make any
          other kind of roll better.
        </p>
        <h2>Forcing Success</h2>
        <p>
          After it has been determined that your skill check has failed, you may
          reduce your percentile roll by increments of 10 until it equals or
          falls below the target skill percentage, changing the outcome of your
          skill check from a failure to a partial success. If you do, you take 1
          exertion for every 10 you reduced your percentile roll this way.
        </p>
        <p>
          You cannot partially exert yourself to reduce the number of banes
          generated by a failed skill check. You must fully exert yourself until
          the skill check succeeds. Additionally, you can only exert yourself
          until you reach a partial success. You cannot continue exerting to
          gain boons.
        </p>
        <div className="example">
          <p>
            Relwirn is trying to sneak past a camp of bandits to free some
            horses they had stolen. He makes a skill check to sneak, using his
            Agility of 40%. Unfortunately, he rolls a 62%, which causes his
            skill check to fail and generates two banes. Relwirn needs to exert
            himself to force his skill check to be successful if he hopes to
            sneak past the bandits. Because Relwirn's percentile roll needs to
            be under 40%, he must take 3 exertion to reduce his result from 62%
            to 32%.
          </p>
        </div>
        <h2>Overexertion</h2>
        <p>
          As you exert yourself to force skill checks to succeed, you accumulate
          exertion, which can eventually lead to your character becoming
          overexerted and future skill checks being more difficult.
        </p>
        <p>
          While you have exertion greater than or equal to your Exertion
          Threshold (ET), you are overexerted. As long as you're overexerted,
          you have disadvantage on all your skill checks and can no longer exert
          yourself to force a successful skill check.
        </p>
        <p>
          Your exertion can be reduced through various effects. Resting is the
          most common way to greatly reduce exertion, but some abilities and
          items may also provide a way to reduce it. When your exertion is
          reduced to your ET or lower, you are no longer overexerted, and may
          once again exert yourself to force successful skill checks.
        </p>
        <div className="example">
          <p>
            If your exertion is just below your ET and you find yourself in a
            situation where you really need to exert yourself, but doing so
            would bring your exertion well beyond your ET, you may still exert.
            For example, n'Eiruu'b'Ai (nye-ruby) has an ET of 10 and
            currently has 8 exertion. She makes an Investigation check and
            rolls 30 above her target percentage. She can only take two exertion
            before she's overexterted, but she can still fully exert this skill
            check. She does so and finds herself at 11 out of 10 exertion. Now
            that she's above her ET, she has disadvantage on all her skill
            checks and can't exert anymore until she rests.
          </p>
        </div>
      </Section>
    </>
  );
}

export default Exertion;
