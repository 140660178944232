import { Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import CharacterCreation from "../../../pages/rules/CharacterCreation";
import CharacterSkills from "../../../pages/rules/CharacterSkills";
import Fate from "../../../components/RulesReference/Fate";

export default (
  <Route path="your-character">
    <Route path="character-creation" element={<CharacterCreation />} />
    <Route path="character-skills" element={<CharacterSkills />} />
    <Route path="fate" element={<Fate />} />
    <Route index element={<Navigate to="character-creation" />} />
  </Route>
);
