import Section from "../../../components/Section";

function Basics() {
  return (
    <>
      <Section>
        <h1 className="title-header">The Basics</h1>
        <p>
          If you're familiar with tabletop role-playing games (TTRPGs), you'll
          feel right at home playing Emberbrand. If this is your first time
          playing a TTRPG, don't fear, Emberbrand is designed to get you and
          your friends telling grand stories of adventure quickly and easily. A
          TTRPG is all about telling an interactive story. You create a
          character, weave tales of adventure, and immerse yourself in the world
          you help build.
        </p>
        <p>
          Most of an Emberbrand game lies in the narrative, the conversation you
          have with the other players. Sometimes though, you'll use dice and the
          Emberbrand rules to give you challenges with uncertain outcomes.
          You'll lean into the unknown to build a story that's exciting and
          memorable.
        </p>
      </Section>
    </>
  );
}

export default Basics;
