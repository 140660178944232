import { Table } from "react-bootstrap";

function InjuryReference() {
  return (
    <>
      <h2>Physical Injuries</h2>
      <p>
        When you suffer a physical injury, you aggravate it based on the hit
        location which suffered that damage. The hit location is determined by
        the ones die of the percentile roll you made during the skill check
        which caused you to suffer damage beyond your DT.
      </p>
      <Table striped>
        <thead>
          <tr>
            <th>Roll</th>
            <th>Hit Location</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>1</th>
            <td>Head</td>
          </tr>
          <tr>
            <th>2-6</th>
            <td>Body</td>
          </tr>
          <tr>
            <th>7-8</th>
            <td>Arms</td>
          </tr>
          <tr>
            <th>9-10</th>
            <td>Legs</td>
          </tr>
        </tbody>
      </Table>
      <p>
        If the hit location already has an injury recorded, you must aggravate
        that same injury. If the hit location does not yet have an injury
        recorded, you must record a new injury at that location and aggravate
        it.
      </p>
      <p>
        If the damage you suffered was not caused as part of a skill check's
        resolution, roll 1d10 to determine where the damage hits. You don't need
        to worry about hit locations if the damage you suffer doesn't require
        you to aggravate an injury.
      </p>
      <h3>Light Injuries</h3>
      <p>
        Light injuries represent small cuts, bruises, and mild sprains. They
        don't pose much threat, are easy to treat, and can heal quickly. Skill
        checks you make which use the injured location are 10% harder.
      </p>
      <h3>Heavy Injuries</h3>
      <p>
        Heavy injuries impose negative effects and become more difficult to
        treat. These are deep lacerations, broken bones, and trauma. You have
        disadvantage on all skill checks you make which use the injured location
      </p>
      <h3>Severe Injuries</h3>
      <p>
        Severe injuries lead to permanent debilities, and are much more
        difficult to treat. Whenever you aggravate a severe injury, you must
        roll 1d100 on the severe injury table for the injured location, adding
        that injury's damage to your roll.
      </p>
      <p>
        Severe injuries are cumulative. The result of your severe injury roll is
        added to your existing severe injury. Any non-permanent effect of a
        severe injury is removed if the injury is completely healed. Permanent
        effects must be recorded as permanent injuries and cannot be removed
        without great magic or some incredible feat of medicine.
      </p>
      <h2>Illness</h2>
      <p>
        Particularly nasty illnesses are recorded as an injury and cause you to
        gain the Sick debility. Illnesses have damage allocated to them just
        like any other injury and increase in severity based on your damage
        threshold. However, healing illnesses works differently. Whenever you
        finish a long rest, make a Resistance check for each of your treated
        illnesses.
      </p>
      <ul>
        <li>
          If you fully succeed, you heal that illness by your Resistance bonus.
        </li>
        <li>If you partially succeed, nothing happens.</li>
        <li>If you fail, allocate 1d4 damage to that illness.</li>
      </ul>
    </>
  );
}

export default InjuryReference;
