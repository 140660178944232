import Section from "../../../components/Section";

function Participants() {
  return (
    <Section>
      <h1 className="title-header">Participants</h1>
      <p>
        <b>Storyteller:</b> The Storyteller manages the flow, events, story, and
        composition of one or more Emberbrand sessions. The Storyteller builds
        the world an adventure takes place in, sets the scenes visible to the
        players, creates trials and encounters, and delivers the outcomes of
        player actions.
      </p>
      <p>
        <b>Player:</b> A player is a participant who controls their own
        character as they interact with the Emberbrand session presented to them
        by the Storyteller. The player creates their character, defines their
        traits and ideals, and plays the part of who their character is.
      </p>
      <p>
        <b>Character:</b> A character is any individual in an Emberbrand
        adventure who can perform actions and interact with others. A player
        character (PC) is controlled by a player, while a non-player character
        (NPC) is controlled by the Storyteller.
      </p>
      <p>
        A game of Emberbrand is played by the Storyteller and the players. The
        Storyteller guides the players through the adventure and each player
        controls their own character. Though the Storyteller builds the world
        and adventure, the players should be just as involved in telling the
        story by playing as their character and diving into the narrative.
      </p>
    </Section>
  );
}

export default Participants;
