import { createContext, useState } from "react";

export const LwCharacterDataContext = createContext(null);

function LwCharacterProvider({ children }) {
  const [lwCurrentCharacter, setLwCurrentCharacter] = useState(null);

  return (
    <LwCharacterDataContext.Provider
      value={{ lwCurrentCharacter, setLwCurrentCharacter }}
    >
      {children}
    </LwCharacterDataContext.Provider>
  );
}

export default LwCharacterProvider;
