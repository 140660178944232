import React, { useRef } from "react";
import { useState } from "react";
import QuillEditor from "../../components/QuillEditor";
import Section from "../../components/Section";
import { Button, Col, Row } from "react-bootstrap";
import { lwTalentsCol } from "../../firebaseSetup";
import { addDoc } from "firebase/firestore";

function LwTalentCreator() {
  const [talentName, setTalentName] = useState("");
  const [talentArchetype, setTalentArchetype] = useState("");
  const [talentEffects, setTalentEffects] = useState("");
  const autoSaveTimer = useRef(null);

  const handleTalentNameChange = e => {
    setTalentName(e.target.value);
  };

  const handleTalentEffectsChange = e => {
    setTalentEffects(e);
  };

  const handleTalentArchetypeChange = e => {
    setTalentArchetype(e.target.value);
  };

  const handleAddTalent = () => {
    console.log(`Adding talent: ${talentName}`);

    // Save talent to Firestore
    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }

    autoSaveTimer.current = setTimeout(() => {
      try {
        addDoc(lwTalentsCol, {
          name: talentName,
          archetype: talentArchetype,
          effects: talentEffects,
        }).finally(() => {
          alert("Talent added successfully.");
        });
      } catch (error) {
        console.error("Error adding talent: ", error);
      }
    }, 1000);
  };

  return (
    <Section>
      <h1 className="title-header">LwTalentCreator</h1>
      <Row className="mt-3">
        <Col xs={6} md={4} className="mb-3">
          <h2 className="text-primary">
            <label htmlFor="talentName">Name</label>
          </h2>
          <input
            className="form-control"
            type="text"
            id="talentName"
            placeholder="Enter talent name"
            onChange={e => handleTalentNameChange(e)}
          />
        </Col>
        <Col xs={6} md={4} className="mb-3">
          <h2 className="text-primary">
            <label htmlFor="talentArchetyp">Archetype</label>
          </h2>
          <input
            className="form-control"
            type="text"
            id="talentArchetype"
            placeholder="Enter talent archetype"
            onChange={e => handleTalentArchetypeChange(e)}
          />
        </Col>
        <Col xs={12} md={4}>
          <h2 className="text-primary">
            <label htmlFor="talentEffects">Effects</label>
          </h2>
          <QuillEditor
            id="talentEffects"
            value={talentEffects}
            change={e => handleTalentEffectsChange(e)}
          />
        </Col>
      </Row>
      <Button variant="primary" className="mt-3" onClick={handleAddTalent}>
        Add Talent
      </Button>
    </Section>
  );
}

export default LwTalentCreator;
