import { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Section from "../components/Section";
import { UserContext, UserPrefsContext } from "../hooks/UserContext";
import { doc, updateDoc } from "firebase/firestore";
import { usersCol } from "../firebaseSetup";
import MainContainer from "../components/MainContainer";

function MyProfile() {
  const currentUser = useContext(UserContext);
  const { userPrefs, setUserPrefs } = useContext(UserPrefsContext);
  const [theme, setTheme] = useState("light");
  let userId = "";

  useEffect(() => {
    if (userPrefs !== null) {
      setTheme(userPrefs?.theme);
    }
  }, [userPrefs]);

  const handleChange = (e) => {
    if (currentUser !== null) {
      userId = currentUser.currentUser.uid;
    }

    e.persist();

    console.log(
      `setting ${currentUser.currentUser.uid} preferred theme to ${e.target.value}`
    );

    // change theme and update user prefs context
    setTheme(e.target.value);

    setUserPrefs((prevState) => ({
      ...prevState,
      theme: e.target.value,
    }));

    // update preferred theme in Firestore
    try {
      const userDoc = doc(usersCol, userId);

      updateDoc(userDoc, {
        theme: e.target.value,
      });
    } catch (error) {
      alert(error);
    }
  };

  return (
    <MainContainer>
      <Section>
        <h1 className="title-header">Theme</h1>
        <Form>
          <Form.Group controlId="selectedTheme">
            <Form.Check
              value="light"
              type="radio"
              aria-label="radio 1"
              label="Light"
              onChange={handleChange}
              checked={theme === "light"}
            />
            <Form.Check
              value="dark"
              type="radio"
              aria-label="radio 2"
              label="Dark"
              onChange={handleChange}
              checked={theme === "dark"}
            />
            <Form.Check
              value="bridge-city"
              type="radio"
              aria-label="radio 3"
              label="City of Bridges"
              onChange={handleChange}
              checked={theme === "bridge-city"}
            />
            <Form.Check
              value="cherry-blossom"
              type="radio"
              aria-label="radio 4"
              label="Cherry Blossom"
              onChange={handleChange}
              checked={theme === "cherry-blossom"}
            />
            <Form.Check
              value="nomad"
              type="radio"
              aria-label="radio 4"
              label="The Nomad"
              onChange={handleChange}
              checked={theme === "nomad"}
            />
            <Form.Check
              value="ominous"
              type="radio"
              aria-label="radio 4"
              label="Ominous"
              onChange={handleChange}
              checked={theme === "ominous"}
            />
            <Form.Check
              value="spectral"
              type="radio"
              aria-label="radio 4"
              label="Spectral"
              onChange={handleChange}
              checked={theme === "spectral"}
            />
            <Form.Check
              value="coven"
              type="radio"
              aria-label="radio 4"
              label="Coven"
              onChange={handleChange}
              checked={theme === "coven"}
            />
          </Form.Group>
        </Form>
      </Section>
    </MainContainer>
  );
}

export default MyProfile;
