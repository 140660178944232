import React from "react";
import { Col, Row } from "react-bootstrap";
import { useContext } from "react";
import { LwCharacterDataContext } from "../../../hooks/leviathansWake/LwContext";
import Section from "../../Section";
import NumberInput from "../NumberInput";

function LwSummarySection() {
  const { lwCurrentCharacter, setLwCurrentCharacter } = useContext(
    LwCharacterDataContext
  );

  const handleSetCharacterData = dataId => {
    const value = document.getElementById(dataId).value;

    setLwCurrentCharacter(prevValue => ({
      ...prevValue,
      [dataId]: value,
    }));
  };

  return (
    <Section>
      <h1 className="title-header">
        {lwCurrentCharacter?.name || "Character"}
      </h1>
      <Row className="gy-1">
        <Col xs={4}>
          <h4 className="text-primary">Experience</h4>
          <NumberInput
            id="experience"
            value={lwCurrentCharacter?.experience || "0"}
            justify={true}
            change={handleSetCharacterData}
          />
        </Col>
        <Col xs={4}>
          <h4 className="text-primary">Supply</h4>
          <NumberInput
            id="supply"
            value={lwCurrentCharacter?.supply || "0"}
            justify={true}
            change={() => handleSetCharacterData("supply")}
          />
        </Col>
        <Col xs={4}>
          <h4 className="text-primary">Coin</h4>
          <NumberInput
            id="coin"
            value={lwCurrentCharacter?.coin || "0"}
            justify={true}
            change={handleSetCharacterData}
          />
        </Col>
      </Row>
    </Section>
  );
}

export default LwSummarySection;
