import { useEffect, useContext, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Link, Outlet } from "react-router-dom";
import { UserPrefsContext } from "../../../hooks/UserContext";
import MainContainer from "../../../components/MainContainer";
import LwCharacterProvider from "../../../hooks/leviathansWake/LwContext";
import LwCharacterSidebar from "../../../components/CharacterSheet/LeviathansWake/LwCharacterSidebar";
import { Button } from "react-bootstrap";
import { FaBookOpen } from "react-icons/fa";

const currentTab = sessionStorage.getItem("currentTab")
  ? sessionStorage.getItem("currentTab")
  : "CharacterTab";

function LWCharacterSheet() {
  const userPrefs = useContext(UserPrefsContext);
  const [userTheme, setUserTheme] = useState("light");
  const [activeTab, setActiveTab] = useState(currentTab);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  const [sidebarToggled, setSidebarToggled] = useState(false);

  useEffect(() => {
    if (userPrefs !== null) {
      setUserTheme(userPrefs.userPrefs?.theme);
    }
  }, [userPrefs]);

  const handleChangeTab = e => {
    const tab = e.target.getAttribute("target-tab");
    setActiveTab(tab);
    sessionStorage.setItem("currentTab", tab);
  };

  const handleSetSidebarCollapsed = () => {
    console.log("Toggled");
    setSidebarCollapsed(!sidebarCollapsed);
    setSidebarToggled(!sidebarToggled);
  };

  return (
    <LwCharacterProvider>
      <Nav
        justify
        variant="pills"
        className={`bg-${userTheme} sticky-top container-fluid`}
        defaultActiveKey={activeTab}
        style={{
          padding: "0.5em",
          marginBottom: "20px",
          top: "81px",
          zIndex: 100,
        }}
      >
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="character"
            eventKey="CharacterTab"
            target-tab="CharacterTab"
            variant="secondary"
            onClick={e => handleChangeTab(e)}
          >
            Character
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="pursuits"
            eventKey="PursuitsTab"
            target-tab="PursuitsTab"
            variant="secondary"
            onClick={e => handleChangeTab(e)}
          >
            Pursuits
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to="journal"
            eventKey="JournalTab"
            target-tab="JournalTab"
            variant="secondary"
            onClick={e => handleChangeTab(e)}
          >
            Journal
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
        <MainContainer>
          <Outlet />
        </MainContainer>
        <LwCharacterSidebar
          collapsed={sidebarCollapsed}
          toggled={sidebarToggled}
          setCollapsed={handleSetSidebarCollapsed}
          theme={userTheme}
        />
        {/* <LoadLwCharacter /> */}
      </div>
      <Button
        className="floating-button"
        variant="secondary"
        onClick={handleSetSidebarCollapsed}
        style={{ bottom: "20px" }}
      >
        <FaBookOpen />
      </Button>
    </LwCharacterProvider>
  );
}

export default LWCharacterSheet;
