import { Accordion } from "react-bootstrap";
import Section from "../Section";
import WeaponsList from "../RulesReference/WeaponsList";
import ItemsList from "../../pages/rules/ItemsEquipment/ItemsList";

function CatalogSection() {
  return (
    <Section>
      <h1 className="title-header">Catalogues</h1>
      <div className="text-left">
        <Accordion>
          <Accordion.Item
            id="weapons-catalogue-accordion"
            eventKey="weaponsCatalogue"
          >
            <Accordion.Header>Weapons</Accordion.Header>
            <Accordion.Body>
              <WeaponsList />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            id="items-catalogue-accordion"
            eventKey="itemsCatalogue"
          >
            <Accordion.Header>Items</Accordion.Header>
            <Accordion.Body>
              <ItemsList />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </Section>
  );
}

export default CatalogSection;
