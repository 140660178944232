import Section from "../../../components/Section";
import ItemsList from "./ItemsList";

function Items() {
  return (
    <Section>
      <ItemsList />
    </Section>
  );
}

export default Items;
