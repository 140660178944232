import { Col, Form, Row } from "react-bootstrap";
import Section from "../Section";
import { useContext, useRef } from "react";
import { CharacterDataContext } from "../../hooks/UserContext";
import { AutoSaveCharacter } from "../../backend/AutoSaveCharacter";
import NumberInput from "./NumberInput";
import ExampleSection from "../ExampleSection";

function MagicTab() {
  const { currentCharacter, setCurrentCharacter } =
    useContext(CharacterDataContext);
  const autoSaveTimer = useRef(null);

  const handleSetCharacterData = id => {
    const value = document.getElementById(id).value;

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }
    setCurrentCharacter(prevValue => ({
      ...prevValue,
      [id]: value,
    }));

    autoSaveTimer.current = setTimeout(() => {
      AutoSaveCharacter(id, value);
    }, 1000);
  };

  const handleManaChange = id => {
    handleSetCharacterData(id);
  };

  return (
    <>
      {/* <Section>
        <h1 className="title-header">Mana</h1>
        <Row>
          <Col>
            <h3 className="text-primary">Current Mana Level</h3>
            <Form.Select
              id="mana"
              value={currentCharacter?.mana || ""}
              aria-label="Mana level select"
              onChange={() => handleManaChange("mana")}
            >
              <option value={0}>0 - 1d4</option>
              <option value={1}>I - 1d4</option>
              <option value={2}>II - 1d6</option>
              <option value={3}>III - 1d8</option>
              <option value={4}>IV - 1d10</option>
              <option value={5}>V - 1d12</option>
              <option value={6}>VI - 1d20</option>
            </Form.Select>
          </Col>
          <Col>
            <h3 className="text-primary">Maximum Mana Level</h3>
            <Form.Select
              id="manaMax"
              value={currentCharacter?.manaMax || ""}
              aria-label="Mana level select"
              onChange={() => handleManaChange("manaMax")}
            >
              <option value={0}>0 - 1d4 (0 Fate)</option>
              <option value={1}>I - 1d4 (10 Fate)</option>
              <option value={2}>II - 1d6 (20 Fate)</option>
              <option value={3}>III - 1d8 (30 Fate)</option>
              <option value={4}>IV - 1d10 (40 Fate)</option>
              <option value={5}>V - 1d12 (50 fate)</option>
              <option value={6}>VI - 1d20 (60 Fate)</option>
            </Form.Select>
          </Col>
        </Row>
        <ExampleSection>
          <p>
            When you cast a spell, make a mana supply roll using your Current
            Mana Level and apply the spell's mana modifier, your discipline
            knowledge modifier, and any other modifiers that relate to the
            spell. If the result is 1 or less, decrease your Current Mana Level
            by 1. If you decrease your level to 0, you can no longer cast
            spells.
          </p>
          <p>
            If you rest near an Esael tree, restore your Current Mana Level to
            your Maximum Mana Level. When you aren't resting, you can consume 1
            Esael Root to increase your Current Mana Level by 1. You can't
            increase your Current Mana Level higher than your Maximum Mana Level
            this way.
          </p>
        </ExampleSection>
      </Section> */}
      <Section>
        <h1 className="title-header">Discipline Knowledge</h1>
        <Row>
          <Col xs={6} md={3}>
            <h3 className="text-primary">Evocation</h3>
            <NumberInput
              id="evocationKnowledge"
              value={currentCharacter?.evocationKnowledge}
              justify={true}
              change={handleSetCharacterData}
            />
          </Col>
          <Col xs={6} md={3}>
            <h3 className="text-primary">Illusion</h3>
            <NumberInput
              id="illusionKnowledge"
              value={currentCharacter?.illusionKnowledge}
              justify={true}
              change={handleSetCharacterData}
            />
          </Col>
          <Col xs={6} md={3}>
            <h3 className="text-primary">Divination</h3>
            <NumberInput
              id="divinationKnowledge"
              value={currentCharacter?.divinationKnowledge}
              justify={true}
              change={handleSetCharacterData}
            />
          </Col>
          <Col xs={6} md={3}>
            <h3 className="text-primary">Abjuration</h3>
            <NumberInput
              id="abjurationKnowledge"
              value={currentCharacter?.abjurationKnowledge}
              justify={true}
              change={handleSetCharacterData}
            />
          </Col>
          <Col xs={6} md={3}>
            <h3 className="text-primary">Conjuration</h3>
            <NumberInput
              id="conjurationKnowledge"
              value={currentCharacter?.conjurationKnowledge}
              justify={true}
              change={handleSetCharacterData}
            />
          </Col>
          <Col xs={6} md={3}>
            <h3 className="text-primary">Necromancy</h3>
            <NumberInput
              id="necromancyKnowledge"
              value={currentCharacter?.necromancyKnowledge}
              justify={true}
              change={handleSetCharacterData}
            />
          </Col>
          <Col xs={6} md={3}>
            <h3 className="text-primary">Transmutation</h3>
            <NumberInput
              id="transmutationKnowledge"
              value={currentCharacter?.transmutationKnowledge}
              justify={true}
              change={handleSetCharacterData}
            />
          </Col>
        </Row>
        <ExampleSection>
          <p>
            You get +1 to your supply roll for every 50 hours of knowledge you
            have when casting a spell with a matching discipline tag. If a spell
            has multiple discipline tags, only consider the discipline in which
            you have the lowest knowledge.
          </p>
        </ExampleSection>
      </Section>
    </>
  );
}

export default MagicTab;
