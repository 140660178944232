import { Outlet } from "react-router-dom";
import MainContainer from "../../../components/MainContainer";
import Titlebar from "../../../components/Titlebar";
import { useState, useEffect } from "react";
import { Navbar, Container, Nav, Row, Col } from "react-bootstrap";
import {
  RulesLink,
  RulesSectionCollapse,
} from "../../../components/Rules/RulesSectionCollapse";

export function LwRules() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const breakPoint = 992;

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
  }, [windowWidth]);

  return (
    <>
      <Titlebar>Leviathan's Wake Rules</Titlebar>
      <MainContainer>
        {windowWidth < breakPoint ? (
          <div style={{ marginBottom: "55px" }}>
            <LwRulesMobile />
          </div>
        ) : (
          <LwRulesDesktop />
        )}
      </MainContainer>
    </>
  );
}

function LwRulesMobile() {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Outlet />
      <div className="rules-nav">
        <div className={`rules-nav-content footer bg-info`}>
          <Navbar expand="lg" expanded={expanded} className="navbar-nav-scroll">
            <Container>
              <h2>Rules</h2>
              <Navbar.Toggle
                aria-controls="rules-navbar-nav"
                onClick={() => setExpanded(expanded ? false : "expanded")}
              />
              <Navbar.Collapse id="rules-navbar-nav">
                <Nav className="me-auto">
                  <RulesLinks setExpanded={setExpanded} />
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <div style={{ paddingBottom: "10px" }}></div>
        </div>
      </div>
    </>
  );
}

function LwRulesDesktop() {
  const [expanded, setExpanded] = useState(false);

  return (
    <Row>
      <Col sm={2} md={2} lg={2} className="rules-nav">
        <div className={`rules-nav-content rules-nav-content-desktop bg-info`}>
          <h2>Rules</h2>
          <RulesLinks expanded={expanded} setExpanded={setExpanded} />
        </div>
      </Col>
      <Col sm={6} md={6} lg={6} className="rules-content">
        <Outlet />
      </Col>
    </Row>
  );
}

function RulesLinks({ setExpanded }) {
  return (
    <div>
      <hr />
      <RulesSectionCollapse
        link="lw-core-rules/lw-basics"
        title="Core Rules"
        id="lw-basic-collapse-links"
        setExpanded={setExpanded}
      >
        <RulesLink
          link="lw-core-rules/lw-rolls"
          title="Rolling the Dice"
          setExpanded={setExpanded}
        />
      </RulesSectionCollapse>
      <RulesSectionCollapse
        link="lw-character/core-concepts"
        title="Your Character"
        id="lw-character-collapse-links"
        setExpanded={setExpanded}
      ></RulesSectionCollapse>
    </div>
  );
}

export default LwRules;
