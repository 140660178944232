function AttackAction() {
  return (
    <div>
      <h2>Attack</h2>
      <p>
        When you attack an enemy, you'll make an attack check based on the type
        of weapon you use and how you wield it. The attack action has two
        additional outcomes, <b>double failure</b> and <b>double success</b>. A
        double success is achieved when your check succeeds and both percentile
        dice show the same number. A double failure is the inverse, when your
        check fails and both dice show the same number.
      </p>
      <hr />
      <h4>Melee Attack</h4>
      <p>When you attack an enemy adjacent to you, roll Strength.</p>
      <p>
        On a <b>double success</b>, You deal damage equal to twice your maximum
        damage roll and avoid the enemy's attack.
      </p>
      <p>
        On a <b>total success</b>, You deal your attack damage and avoid the
        enemy's attack.
      </p>
      <p>
        On a <b>partial success</b>, You deal your attack damage and the enemy
        attacks you.
      </p>
      <p>
        On a <b>failure</b>, You miss your attack and the enemy attacks you.
      </p>
      <p>
        On a <b>double failure</b>, You miss your attack and the enemy attacks
        you, dealing damage equal to twice their maximum damage roll.
      </p>
      <hr />
      <h4>Ranged Attack</h4>
      <p>When you attack an enemy with a ranged weapon, roll Dexterity.</p>
      <p>
        On a <b>double failure</b>, You miss your shot and the Storyteller may
        reveal your position. If your target has a ranged weapon, they can fire
        back, dealing damage equal to twice their maximum damage roll.
      </p>
      <p>
        On a <b>failure</b>, You miss your shot and the Storyteller may reveal
        your position. If your target has a ranged weapon, they can fire back.
      </p>
      <p>
        On a <b>partial success</b>, You hit your target and deal your attack
        damage, but suffer one of the following complications:
      </p>
      <ul>
        <li>
          You have to move to get the shot, revealing your position or placing
          you in danger as described by the Storyteller. If your target has a
          ranged weapon and your position is revealed, they can fire back.
        </li>
        <li>
          Your shot doesn't land quite true and your damage is halved, rounded
          up.
        </li>
        <li>
          You have to take several shots and your capacity roll is modified by
          the Storyteller.
        </li>
      </ul>
      <p>
        On a <b>total success</b>, You hit your target and deal your attack
        damage.
      </p>
      <p>
        On a <b>double success</b>, You hit your target and deal damage equal to
        twice your maximum damage roll.
      </p>
    </div>
  );
}

export default AttackAction;
