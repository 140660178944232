import { useContext, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { EnemyContext } from "./EmberbrandContext";
import { doc, getDoc } from "firebase/firestore";
import { enemiesCol } from "../firebaseSetup";

export function LoadEnemyButton({ setLoadedEnemy }) {
  const enemyContext = useContext(EnemyContext);
  const enemyList = enemyContext?.enemyList;
  const [show, setShow] = useState(false);
  const [selectedEnemy, setSelectedEnemy] = useState(null);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleChange = e => {
    e.persist();
    const enemyId = e.target.value;

    setSelectedEnemy(enemyId);
  };

  const handleLoadEditEnemy = () => {
    const enemyDoc = doc(enemiesCol, selectedEnemy);

    getDoc(enemyDoc).then(doc => {
      const enemyData = { ...doc.data(), id: doc.id };
      setLoadedEnemy(enemyData);
    });

    handleClose();
  };

  return (
    <>
      <div style={{ marginTop: "30px", marginBottom: "50px" }}>
        <Button onClick={handleShow}>Load Existing Enemy</Button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Load Enemy</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-left">
          <div style={{ height: "440px", overflowY: "auto" }}>
            <Form>
              {enemyList.map(enemy => {
                return (
                  <Form.Check
                    name="enemyRadio"
                    key={enemy.id}
                    value={enemy.id}
                    label={enemy.name}
                    enemy-name={enemy.name}
                    type="radio"
                    onChange={handleChange}
                  />
                );
              })}
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" onClick={handleLoadEditEnemy}>
            Edit Enemy
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
