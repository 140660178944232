import { Button, Col, Row } from "react-bootstrap";
import Section from "../Section";
import { useEffect, useState } from "react";
import { LoadAbilityButton } from "../../hooks/LoadAbilityEdit";
import { addDoc, doc, updateDoc } from "firebase/firestore";
import { abilitiesCol } from "../../firebaseSetup";
import QuillEditor from "../QuillEditor";

function AbilityCreator() {
  const [loadedAbility, setLoadedAbility] = useState(null);
  const [abilityName, setAbilityName] = useState("Ability Name");
  const [abilityAttribute, setAbilityAttribute] =
    useState("Body, Mind, Spirit");
  const [abilityRules, setAbilityRules] = useState(null);
  const [abilityExtraData, setAbilityExtraData] = useState("");

  useEffect(() => {
    if (loadedAbility) {
      setAbilityName(loadedAbility?.name);
      setAbilityAttribute(loadedAbility?.attribute);
      setAbilityRules(loadedAbility?.rules);
      setAbilityExtraData(loadedAbility?.extraDataName);
    }
  }, [loadedAbility]);

  const handleSetAbilityName = e => {
    setAbilityName(e.target.value);
  };

  const handleSetAbilityAttribute = e => {
    setAbilityAttribute(e.target.value);
  };

  const handleSetAbilityRules = e => {
    setAbilityRules(e);
  };

  const handleSetAbilityExtraData = e => {
    setAbilityExtraData(e.target.value);
  };

  const handleResetForm = () => {
    setAbilityName("Ability Name");
    setAbilityAttribute("Body, Mind, Spirit");
    setAbilityRules(null);
    setAbilityExtraData("");
  };

  const handleSaveExistingAbility = () => {
    const abilityId = loadedAbility?.id;
    const abilityDocRef = doc(abilitiesCol, abilityId);

    try {
      updateDoc(abilityDocRef, {
        name: abilityName,
        attribute: abilityAttribute,
        rules: abilityRules,
        extraDataName: abilityExtraData,
      }).then(() => {
        alert(`${abilityName} updated.`);
      });
    } catch (error) {
      alert(error);
    }
  };

  const handleSaveNewAbility = () => {
    try {
      addDoc(abilitiesCol, {
        name: abilityName,
        attribute: abilityAttribute,
        rules: abilityRules,
        extraDataName: abilityExtraData,
      }).then(() => {
        alert(`${abilityName} created`);
      });
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Section>
      <h1 className="title-header">Talent Creator</h1>
      <LoadAbilityButton setLoadedAbility={setLoadedAbility} />
      <Row className="gy-3">
        <Col md={4}>
          <h2>
            Name <span className="text-danger">*</span>
          </h2>
          <input
            id="talent-name"
            className="data"
            value={abilityName}
            onChange={handleSetAbilityName}
          />
        </Col>
        <Col md={4}>
          <h2>
            Attribute <span className="text-danger">*</span>
          </h2>
          <input
            id="ability-attribute"
            className="data"
            value={abilityAttribute}
            onChange={handleSetAbilityAttribute}
          />
        </Col>
        <Col md={4}>
          <h2>Extra Data</h2>
          <input
            id="extraDataName"
            value={abilityExtraData}
            onChange={handleSetAbilityExtraData}
          />
        </Col>
      </Row>
      <p>
        <i>
          If Extra Data is given a value, the ability will have an extra rich
          text editor to record applicable data.
        </i>
      </p>
      <Row>
        <h2>
          Talent Rules <span className="text-danger">*</span>
        </h2>
        <QuillEditor
          id="ability-rules"
          value={abilityRules}
          change={handleSetAbilityRules}
        />
      </Row>
      <Row className="gy-3">
        <Col md={4}>
          <Button onClick={handleSaveExistingAbility}>
            Save Existing Talent
          </Button>
        </Col>
        <Col md={4}>
          <Button onClick={handleSaveNewAbility}>Create New Talent</Button>
        </Col>
        <Col md={4}>
          <Button variant="secondary" onClick={handleResetForm}>
            Reset Form
          </Button>
        </Col>
      </Row>
    </Section>
  );
}

export default AbilityCreator;
