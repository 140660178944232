import Section from "../Section";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useContext, useRef } from "react";
import { AutoSaveCharacter } from "../../backend/AutoSaveCharacter";
import { CharacterDataContext } from "../../hooks/UserContext";
import QuillEditor from "../QuillEditor";

function SummarySection() {
  const { currentCharacter, setCurrentCharacter } =
    useContext(CharacterDataContext);
  const autoSaveTimer = useRef(null);

  const handleSetCharacterData = id => {
    const value = document.getElementById(id).value;

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }
    setCurrentCharacter(prevValue => ({
      ...prevValue,
      [id]: value,
    }));

    autoSaveTimer.current = setTimeout(() => {
      AutoSaveCharacter(id, value);
    }, 1000);
  };

  const handleSetCharacterRichData = (e, id) => {
    const value = e;

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }

    setCurrentCharacter(prevValue => ({
      ...prevValue,
      [id]: value,
    }));

    autoSaveTimer.current = setTimeout(() => {
      AutoSaveCharacter(id, value);
    }, 1000);
  };

  return (
    <Section>
      <h1 className="title-header">Summary</h1>
      <Row className="gy-3" style={{ marginBottom: "20px" }}>
        <Col>
          <h2 className="text-primary">Name</h2>
          <input
            id="name"
            className="data"
            value={currentCharacter?.name || ""}
            onChange={() => handleSetCharacterData("name")}
          />
        </Col>
        <Col>
          <h2 className="text-primary">Player</h2>
          <input
            id="name"
            className="data"
            value={currentCharacter?.player || ""}
            onChange={() => handleSetCharacterData("player")}
          />
        </Col>
        <Col>
          <h2 className="text-primary">Adventure</h2>
          <input
            id="name"
            className="data"
            value={currentCharacter?.campaign || ""}
            onChange={() => handleSetCharacterData("campaign")}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: "30px" }}>
        <Col>
          <h2 className="text-primary">Age</h2>
          <input
            id="name"
            className="data"
            size={6}
            value={currentCharacter?.age || ""}
            onChange={handleSetCharacterData}
          />
        </Col>
        <Col>
          <h2 className="text-primary">Height</h2>
          <input
            id="name"
            className="data"
            size={6}
            value={currentCharacter?.height || ""}
            onChange={handleSetCharacterData}
          />
        </Col>
        <Col>
          <h2 className="text-primary">Weight</h2>
          <input
            id="name"
            className="data"
            size={6}
            value={currentCharacter?.weight || ""}
            onChange={handleSetCharacterData}
          />
        </Col>
      </Row>
      <h2 className="text-primary">Description & Features</h2>
      <QuillEditor
        id="description"
        value={currentCharacter?.description || ""}
        change={e => handleSetCharacterRichData(e, "description")}
      />
      <h2 className="text-primary">Background</h2>
      <QuillEditor
        id="background"
        value={currentCharacter?.background || ""}
        change={e => handleSetCharacterRichData(e, "background")}
      />
    </Section>
  );
}

export default SummarySection;
