import React from "react";
import Titlebar from "../components/Titlebar";
import Section from "../components/Section";
import MainContainer from "../components/MainContainer";
import { Col, Row } from "react-bootstrap";

const Home = () => {
  return (
    <>
      <Titlebar>Emberbrand Roleplaying Game</Titlebar>
      <MainContainer>
        <Row>
          <Col lg={{ span: 6, offset: 3 }}>
            <Section>
              <h1 className="title-header">Welcome to Nilihaad</h1>
              <div className="home-text">
                <p>
                  Emberbrand is a tabletop role-playing game set in the world of
                  Nilihaad, where your journey begins on the frostbitten Higzaar
                  continent. For generations, an intense conflict has raged
                  between the mighty Chigtuv Empire, the resilient Kingdom of
                  Kausia, and the ruthless Hadeven Dynasty. The cause of this
                  strife lies in the arcane and enigmatic species of tree known
                  as Esael.
                </p>
                <p>
                  The northern continent of Higzaar is a harsh and frigid land,
                  with sprawling tundras and cold deserts. The extraordinary
                  Esael tree is difficult to grow, reaching maturity only in the
                  fertile soil of Higzaar's taigas. The prominent source of
                  magic in the world, Esael trees are highly sought after by all
                  factions. The Chigtuv Empire, driven by a relentless hunger
                  for control, has pushed southward from the frozen north, vying
                  for dominion over the lands where these trees thrive, igniting
                  a conflict that has spanned generations.
                </p>
                <p>
                  Meanwhile, the kingdoms of Kausia and Hadeven have fiercely
                  resisted the Empire's ambitions, fighting for their own
                  control of Esael. As a result, the three ruling territories
                  have become adversaries, forming alliances with other states
                  and amassing armies to protect their lands and stake their
                  claim to the invaluable Esael resources.
                </p>
                <p>
                  In the Emberbrand role-playing game, you will embark on
                  unforgettable adventures, exploring Higzaar's unrelenting
                  wilds, navigating its treacherous political landscape,
                  engaging in epic battles, and unraveling the mysteries of the
                  Esael trees. Choose your allegience carefully, for the fate of
                  the continent hangs in the balance. Will fight for the
                  expansionist Chigtuv Empire, the defiant kingdoms of Kausia or
                  Hadeven, or will you forge your own path among the independent
                  states of Higzaar?
                </p>
              </div>
            </Section>
          </Col>
        </Row>
      </MainContainer>
    </>
  );
};

export default Home;
