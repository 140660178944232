import { Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Basics from "../../../pages/rules/basics/Basics";
import Dice from "../../../pages/rules/basics/Dice";
import Skills from "../../../pages/rules/basics/Skills";
import Participants from "../../../pages/rules/basics/Participants";
import ActionsOutcomes from "../../../pages/rules/basics/ActionsOutcomes";
import Exertion from "../../../pages/rules/Exertion";
import Damage from "../../../pages/rules/basics/Damage";
import Resting from "../../../pages/rules/healing-resting/Resting";
import { SupplyRollReference } from "../../../pages/rules/basics/SupplyRoll";

export default (
  <Route path="core-rules">
    <Route path="basics" element={<Basics />} />
    <Route path="dice" element={<Dice />} />
    <Route path="skills" element={<Skills />} />
    <Route path="participants" element={<Participants />} />
    <Route path="actions-outcomes" element={<ActionsOutcomes />} />
    <Route path="exertion" element={<Exertion />} />
    <Route path="damage" element={<Damage />} />
    <Route path="taking-rest" element={<Resting />} />
    <Route path="supply-roll" element={<SupplyRollReference />} />
    <Route index element={<Navigate to="basics" />} />
  </Route>
);
