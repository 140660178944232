import { HashLink } from "react-router-hash-link";
import Section from "../../../components/Section";
import SkillMatrixReadOnly from "../../../components/SkillMatrixReadOnly";
import Actions from "../../../components/RulesReference/Actions";
import Outcomes from "../../../components/RulesReference/Outcomes";

function ActionsOutcomes() {
  return (
    <>
      <Section>
        <h1 id="actions">Actions</h1>
        <div>
          <h2>Fiction & Mechanics</h2>
          <p>
            Every action starts in the fiction. That is to say, you should focus
            on the fiction and the story of your adventure and your character
            before you begin resolving the game's mechanics. Many actions you
            take are simple, with obvious outcomes. These actions are handled
            entirely within the fiction and you won't have to make a skill
            check. Other actions are more difficult or have unknown outcomes.
          </p>
          <p>
            When you do something that has a chance to fail or has an uncertain
            outcome, you trigger an action in the game's mechanics. The
            Storyteller will tell you which action you've triggered, then you'll
            determine your target skill percentage and make your skill check.
          </p>
          <h2>Skill Percentage</h2>
          <SkillMatrixReadOnly />
          <p>
            Every skill in the Skill Matrix has a skill percentage, which is the
            combined scores of the attribute and proficiency which meet at that
            skill. The skill percentage is your chance to pass a given skill
            check before any modifiers and is the target number which you want
            to roll under.
          </p>
          <p>
            Based on how well you roll against the target skill percentage, your
            action will result in an{" "}
            <HashLink to="#outcomes" className="link">
              outcome
            </HashLink>
            .
          </p>
        </div>
        <hr />
        <Actions />
      </Section>
      <Section>
        <h1 id="outcomes">Outcomes</h1>
        <div>
          <p>
            Based on how well you roll against your target skill percentage
            while taking an{" "}
            <HashLink to="#actions" className="link">
              action
            </HashLink>
            , your check will result in one of three outcomes: <b>failure</b>,{" "}
            <b>partial success</b>, or <b>total success</b>.
          </p>
        </div>
        <hr />
        <Outcomes />
      </Section>
    </>
  );
}

export default ActionsOutcomes;
