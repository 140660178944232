import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import Section from "../../Section";
import { useContext, useEffect, useState } from "react";
import { LwCharacterDataContext } from "../../../hooks/leviathansWake/LwContext";
import NumberInput from "../../CharacterSheet/NumberInput";

function LwSkillsSection() {
  const { lwCurrentCharacter, setLwCurrentCharacter } = useContext(
    LwCharacterDataContext
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const breakPoint = 992;

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
  }, [windowWidth]);

  /**
   * Handles the setting of skill data for a specific skill.
   *
   * @param {string} skillId - The ID of the skill.
   * @returns {void}
   */
  const handleSetSkillData = skillId => {
    const value = document.getElementById(skillId).value;

    setLwCurrentCharacter(prevValue => ({
      ...prevValue,
      [skillId]: value,
    }));
  };

  return (
    <Section>
      <Row className="mb-2 border-bottom">
        <Col xs={6}>
          <h4 className="text-primary">Core</h4>
          <NumberInput
            id="core"
            value={lwCurrentCharacter?.core || "0"}
            justify={true}
            change={() => handleSetSkillData("core")}
          />
        </Col>
        <Col xs={6}>
          <h4 className="text-danger">Curse</h4>
          <NumberInput
            id="curse"
            value={lwCurrentCharacter?.curse || "0"}
            justify={true}
            change={() => handleSetSkillData("curse")}
          />
        </Col>
      </Row>
      {windowWidth < breakPoint ? (
        <MobileSkills
          character={lwCurrentCharacter}
          setSkill={handleSetSkillData}
        />
      ) : (
        <DesktopSkills
          character={lwCurrentCharacter}
          setSkill={handleSetSkillData}
        />
      )}
    </Section>
  );
}

function MobileSkills({ character, setSkill }) {
  return (
    <div>
      <Row>
        <Col xs={6}>
          <h4 className="text-primary">Might</h4>
          <NumberInput
            id="might"
            value={character?.might}
            justify={true}
            change={() => setSkill("might")}
          />
        </Col>
        <Col xs={6}>
          <h5 className="text-danger">Damage</h5>
          <NumberInput
            id="damage"
            value={character?.damage}
            justify={true}
            change={() => setSkill("damage")}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <h4 className="text-primary">Finesse</h4>
          <NumberInput
            id="finesse"
            value={character?.finesse}
            justify={true}
            change={() => setSkill("finesse")}
          />
        </Col>
        <Col xs={6}>
          <h5 className="text-danger">Fatigue</h5>
          <NumberInput
            id="fatigue"
            value={character?.fatigue}
            justify={true}
            change={() => setSkill("fatigue")}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <h4 className="text-primary">Wits</h4>
          <NumberInput
            id="wits"
            value={character?.wits}
            justify={true}
            change={() => setSkill("wits")}
          />
        </Col>
        <Col xs={6}>
          <h5 className="text-danger">Confusion</h5>
          <NumberInput
            id="confusion"
            value={character?.confusion}
            justify={true}
            change={() => setSkill("confusion")}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <h4 className="text-primary">Rapport</h4>
          <NumberInput
            id="rapport"
            value={character?.rapport}
            justify={true}
            change={() => setSkill("rapport")}
          />
        </Col>
        <Col xs={6}>
          <h5 className="text-danger">Doubt</h5>
          <NumberInput
            id="doubt"
            value={character?.doubt}
            justify={true}
            change={() => setSkill("doubt")}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <h4 className="text-primary">Resolve</h4>
          <NumberInput
            id="resolve"
            value={character?.resolve}
            justify={true}
            change={() => setSkill("resolve")}
          />
        </Col>
        <Col xs={6}>
          <h5 className="text-danger">Fear</h5>
          <NumberInput
            id="fear"
            value={character?.fear}
            justify={true}
            change={() => setSkill("fear")}
          />
        </Col>
      </Row>
    </div>
  );
}

function DesktopSkills({ character, setSkill }) {
  return (
    <div>
      <Row>
        <Col>
          <Card className="text-center">
            <Card.Header>
              <h4 className="text-primary">Might</h4>
              <NumberInput
                id="might"
                value={character?.might}
                justify={true}
                change={() => setSkill("might")}
              />
            </Card.Header>
            <Card.Body>
              <h5 className="text-danger">Damage</h5>
              <NumberInput
                id="damage"
                value={character?.damage}
                justify={true}
                change={() => setSkill("damage")}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="text-center">
            <Card.Header>
              <h4 className="text-primary">Finesse</h4>
              <NumberInput
                id="finesse"
                value={character?.finesse}
                justify={true}
                change={() => setSkill("finesse")}
              />
            </Card.Header>
            <Card.Body>
              <h5 className="text-danger">Fatigue</h5>
              <NumberInput
                id="fatigue"
                value={character?.fatigue}
                justify={true}
                change={() => setSkill("fatigue")}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="text-center">
            <Card.Header>
              <h4 className="text-primary">Wits</h4>
              <NumberInput
                id="wits"
                value={character?.wits}
                justify={true}
                change={() => setSkill("wits")}
              />
            </Card.Header>
            <Card.Body>
              <h5 className="text-danger">Confusion</h5>
              <NumberInput
                id="confusion"
                value={character?.confusion}
                justify={true}
                change={() => setSkill("confusion")}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="text-center">
            <Card.Header>
              <h4 className="text-primary">Rapport</h4>
              <NumberInput
                id="rapport"
                value={character?.rapport}
                justify={true}
                change={() => setSkill("rapport")}
              />
            </Card.Header>
            <Card.Body>
              <h5 className="text-danger">Doubt</h5>
              <NumberInput
                id="doubt"
                value={character?.doubt}
                justify={true}
                change={() => setSkill("doubt")}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="text-center">
            <Card.Header>
              <h4 className="text-primary">Resolve</h4>
              <NumberInput
                id="resolve"
                value={character?.resolve}
                justify={true}
                change={() => setSkill("resolve")}
              />
            </Card.Header>
            <Card.Body>
              <h5 className="text-danger">Fear</h5>
              <NumberInput
                id="fear"
                value={character?.fear}
                justify={true}
                change={() => setSkill("fear")}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default LwSkillsSection;
