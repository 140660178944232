import { Tab, Tabs } from "react-bootstrap";
import Section from "../Section";
import { InventorySection } from "./InventorySection";
import CatalogSection from "./CatalogSection";

function InventoryTab() {
  return (
    <Section>
      <Tabs defaultActiveKey={1}>
        <Tab eventKey={1} title="Inventory">
          <InventorySection />
        </Tab>
        <Tab eventKey={2} title="Catalogues">
          <CatalogSection />
        </Tab>
      </Tabs>
    </Section>
  );
}

export default InventoryTab;
