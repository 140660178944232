import DeleteOldContainers from "../../migrations/DeleteOldContainers"
import Section from "../Section";

function DatabaseMigrations() {
    return (
        <Section>
        <h1 className="title-header">Database Migrations</h1>
        <DeleteOldContainers />
        </Section>
    )
}

export default DatabaseMigrations;