import React, { useContext, useEffect, useRef, useState } from "react";
import Section from "../Section";
import {
  CharacterDataContext,
  CharacterAbilityContext,
} from "../../hooks/UserContext";
import { AutoSaveAbilityData } from "../../backend/AutoSaveCharacter";
import { Button, Modal, Form, Accordion } from "react-bootstrap/";
import {
  deleteDoc,
  doc,
  collection,
  addDoc,
  updateDoc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { charactersCol, abilitiesCol, auth } from "../../firebaseSetup";
import QuillEditor from "../QuillEditor";
import { AbilityContext } from "../../hooks/EmberbrandContext";

function AbilitiesSection() {
  const characterAbilities = useContext(CharacterAbilityContext);
  const [abilityList, setAbilityList] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      // Sort abilities list by name alphabetically
      if (characterAbilities.characterAbilities !== null) {
        const sortedList = characterAbilities.characterAbilities.sort(
          (a, b) => {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          }
        );

        setAbilityList(sortedList);
      }
    }, 200);
  }, [characterAbilities]);

  return (
    <Section>
      <Accordion style={{ marginBottom: "50px" }}>
        {abilityList.map(ability => {
          return (
            <Ability
              key={ability.id}
              abilityId={ability.id}
              name={ability.name}
              tier={ability.tier}
              rules={ability.rules}
              extraDataName={ability.extraData}
              extraData={ability.extra}
            />
          );
        })}
      </Accordion>
      <AddAbility />
    </Section>
  );
}

function Ability({ abilityId, name, tier, rules, extraDataName, extraData }) {
  const { characterAbilities, setCharacterAbilities } = useContext(
    CharacterAbilityContext
  );
  const [abilityTier, setAbilityTier] = useState(tier);
  const [abilityExtraData, setAbilityExtraData] = useState(extraData);
  const autoSaveTimer = useRef(null);

  const handleSetAbilityData = (e, abilityId) => {
    const dataId = e.target.getAttribute("id");
    const value = e.target.value;

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }

    if (e.target.getAttribute("id") === "tier") {
      setAbilityTier(e.target.value);
    }

    if (e.target.getAttribute("id") === "extra") {
      setAbilityExtraData(e.target.value);
    }

    const abilityKey = Object.keys(characterAbilities).find(
      key => characterAbilities[key].id === abilityId
    );

    const updatedAbilityList = Object.assign([...characterAbilities], {
      [abilityKey]: { ...characterAbilities[abilityKey], [dataId]: value },
    });

    setCharacterAbilities(updatedAbilityList);

    autoSaveTimer.current = setTimeout(() => {
      AutoSaveAbilityData(abilityId, dataId, value);
    }, 1000);
  };

  const handleSetAbilityRichData = (e, abilityId) => {
    const abilityKey = Object.keys(characterAbilities).find(
      key => characterAbilities[key].id === abilityId
    );

    const updatedAbilityList = Object.assign([...characterAbilities], {
      [abilityKey]: { ...characterAbilities[abilityKey], extra: e },
    });

    if (autoSaveTimer.current) {
      clearTimeout(autoSaveTimer.current);
    }

    setCharacterAbilities(updatedAbilityList);

    setAbilityExtraData(e);

    autoSaveTimer.current = setTimeout(() => {
      AutoSaveAbilityData(abilityId, "extra", e);
    }, 1000);
  };

  const handleDeleteAbility = abilityId => {
    const charId = localStorage.getItem("currentCharacterId");
    const abilityDoc = doc(charactersCol, charId, "charAbilities", abilityId);

    deleteDoc(abilityDoc);

    const filterAbilityList = characterAbilities.filter(
      ability => ability.id !== abilityId
    );

    setCharacterAbilities(filterAbilityList);
  };

  return (
    <Accordion.Item key={abilityId} id={abilityId} eventKey={abilityId}>
      <Accordion.Header>{name}</Accordion.Header>
      <Accordion.Body className="text-left">
        <div className="magnitude-row">
          <h4 className="text-primary">Tier</h4>
          <input
            id="tier"
            abilityid={abilityId}
            value={abilityTier}
            size={6}
            onChange={e => handleSetAbilityData(e, abilityId)}
          />
        </div>
        {extraDataName ? (
          <div>
            <h4 className="text-primary">{extraDataName}</h4>
            <QuillEditor
              id="extra"
              value={abilityExtraData}
              change={e => handleSetAbilityRichData(e, abilityId)}
            />
            <div style={{ marginBottom: "20px" }} />
          </div>
        ) : null}
        <div
          dangerouslySetInnerHTML={{ __html: rules }}
          style={{ marginTop: "30px" }}
        />
        <Button
          className="btn-secondary"
          onClick={() => handleDeleteAbility(abilityId)}
          style={{ marginTop: "20px" }}
        >
          Delete Ability
        </Button>
      </Accordion.Body>
    </Accordion.Item>
  );
}

function AddAbility() {
  const currentCharacter = useContext(CharacterDataContext);
  const { setCharacterAbilities } = useContext(CharacterAbilityContext);
  const abilityContext = useContext(AbilityContext);
  const abilityList = abilityContext.abilityList;

  const [show, setShow] = useState(false);
  const [abilities, setAbilities] = useState([]);
  const [chosenAbility, setchosenAbility] = useState({
    chosenAbility: "",
    another: "another",
  });
  const abilityArray = [];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = e => {
    e.persist();

    const abilityObject = {
      id: e.target.value,
      name: e.target.getAttribute("ability-name"),
    };

    setchosenAbility(abilityObject);
  };

  const handleAddAbility = e => {
    e.preventDefault();
    handleClose();

    // get ability data from context
    const abilityObject = abilityList.find(a => a.name === chosenAbility.name);

    if (currentCharacter !== null) {
      const ownerId = auth.currentUser.uid;
      const charId = localStorage.getItem("currentCharacterId");
      const charAbilitiesCol = collection(
        charactersCol,
        charId,
        "charAbilities"
      );

      addDoc(charAbilitiesCol, {
        name: chosenAbility.name,
        owner: ownerId,
        tier: 1,
        extra: "",
      }).then(doc => {
        updateDoc(doc, {
          id: doc.id,
        }).then(() => {
          getDoc(doc).then(docSnap => {
            const docData = docSnap.data();
            const docRules = abilityObject.rules;
            const docExtraDataName = abilityObject.extraDataName;

            const combinedDocData = {
              ...docData,
              rules: docRules,
              extraData: docExtraDataName,
            };

            setCharacterAbilities(characterAbilities => [
              ...(characterAbilities || []),
              combinedDocData,
            ]);
          });
        });
      });
    }
  };

  return (
    <>
      <Button id="add-ability-button" onClick={handleShow}>
        Add Talent
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Talent</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-left" style={{ margin: "10px" }}>
          <Form>
            {abilityList.map(ability => {
              return (
                <Form.Check
                  name="abilityRadio"
                  key={ability.id}
                  value={ability.id}
                  label={ability.name}
                  ability-name={ability.name}
                  type="radio"
                  onChange={handleChange}
                />
              );
            })}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" onClick={handleAddAbility}>
            Add Talent
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AbilitiesSection;
