import React, { useContext } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "@firebase/firestore";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { charactersCol, abilitiesCol } from "../../firebaseSetup";
import Form from "react-bootstrap/Form";
import {
  UserContext,
  CharacterDataContext,
  CharacterAbilityContext,
  CharacterInjuryContext,
  CharacterInventoryContext,
} from "../UserContext";
import { HiPencilSquare } from "react-icons/hi2";

export function LoadEbCharacter({ title }) {
  const { currentUser } = useContext(UserContext);
  const { currentCharacter, setCurrentCharacter } =
    useContext(CharacterDataContext);
  const { setCharacterAbilities } = useContext(CharacterAbilityContext);
  const { setCharacterInjuries } = useContext(CharacterInjuryContext);
  const { setCharacterInventory } = useContext(CharacterInventoryContext);
  const [show, setShow] = useState(false);
  const [characters, setCharacters] = useState([]);
  const [chosenCharacterId, setchosenCharId] = useState({
    chosenCharId: localStorage.getItem("currentCharacterId"),
    another: "another",
  });
  const [charIdInputValue, setCharIdInputValue] = useState("");
  const { chosenCharId } = chosenCharacterId;
  const charArray = [];
  const charAbilityArray = [];
  const charInjuryArray = [];
  const charInventoryArray = [];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = e => {
    e.persist();

    setchosenCharId(prevState => ({
      ...prevState,
      chosenCharId: e.target.value,
    }));
  };

  const handleEnterCharId = e => {
    setCharIdInputValue(e.target.value);
    setchosenCharId(prevState => ({
      ...prevState,
      chosenCharId: e.target.value,
    }));
  };

  const handleLoadCharacter = () => {
    handleClose();

    // get Firestore references
    const charDocReference = doc(charactersCol, chosenCharId);
    const charAbilityCollectionReference = collection(
      charactersCol,
      chosenCharId,
      "charAbilities"
    );
    const charInjuryCollectionReference = collection(
      charactersCol,
      chosenCharId,
      "charInjuries"
    );
    const charInventoryCollectionReference = collection(
      charactersCol,
      chosenCharId,
      "charInventory"
    );

    getDoc(charDocReference).then(doc => {
      // Get character data, abilities, injuries, and inventory
      const charData = doc.data();

      setCurrentCharacter(charData);

      // load character abilities
      getDocs(charAbilityCollectionReference).then(collectionSnapshot => {
        collectionSnapshot.docs.forEach(doc => {
          const docData = doc.data();
          const abilityRulesQuery = query(
            abilitiesCol,
            where("name", "==", docData.name)
          );

          getDocs(abilityRulesQuery).then(snapshot => {
            snapshot.docs.forEach(doc => {
              const charAbilityRules = doc.get("rules");
              const extraData = doc.get("extraDataName");
              const fullAbility = {
                ...docData,
                rules: charAbilityRules,
                extraData: extraData,
              };

              charAbilityArray.push(fullAbility);
            });
          });
        });

        setCharacterAbilities(charAbilityArray);
      });

      // load character injuries
      getDocs(charInjuryCollectionReference).then(collectionSnapshot => {
        collectionSnapshot.docs.forEach(doc => {
          const docData = doc.data();

          charInjuryArray.push(docData);
          setCharacterInjuries(charInjuryArray);
        });
      });

      // load character inventory
      getDocs(charInventoryCollectionReference).then(collectionSnapshot => {
        collectionSnapshot.docs.forEach(doc => {
          const docData = doc.data();

          charInventoryArray.push(docData);
          setCharacterInventory(charInventoryArray);
        });
      });

      localStorage.setItem("currentCharacterId", chosenCharId);
    });
  };

  // load character if ID exists in storage
  useEffect(() => {
    if (localStorage.getItem("currentCharacterId") && !currentCharacter) {
      console.log(`found character ID ${chosenCharId} in storage...`);

      handleLoadCharacter();
    }
  }, [chosenCharId]);

  // Load user's list of characters
  useEffect(() => {
    if (currentUser !== null) {
      console.log("loading user's character list");
      const char = query(charactersCol, where("owner", "==", currentUser.uid));

      getDocs(char).then(snapshot => {
        snapshot.docs.forEach(doc => {
          const id = doc.id;
          const name = doc.get("name");
          const charCheck = (
            <Form.Check
              name="charRadio"
              label={name}
              key={id}
              value={id}
              aria-label={name}
              type="radio"
              onChange={handleChange}
            />
          );

          charArray.push(charCheck);
        });
      });

      setCharacters(charArray);
    }
  }, [currentUser]);

  return (
    <>
      <Button
        className="pursuit-header"
        id="load-character-button"
        variant="link"
        onClick={handleShow}
      >
        <h1 className="title-header">
          {title} <HiPencilSquare />
        </h1>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>My Characters</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-left" style={{ margin: "10px" }}>
          <Form>
            <Form.Group controlId="chosenCharId">
              {characters}
              <Form.Check
                name="charRadio"
                label="Load by character ID"
                key="manual-char-id"
                value={charIdInputValue}
                aria-label="char-id"
                type="radio"
                onChange={handleChange}
              />
            </Form.Group>
            <div style={{ marginTop: "20px" }}>
              <h5>Character ID</h5>
              <input
                className="text-left"
                id="character-id-input"
                value={charIdInputValue}
                onChange={handleEnterCharId}
              />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger">Delete Character</Button>
          <Button variant="primary" type="submit" onClick={handleLoadCharacter}>
            Load Character
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
