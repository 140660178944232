import { Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import CampaignManager from "../../pages/CampaignManager";
import EncounterBuilder from "../../pages/CampaignManager/EncounterBuilder";

export default (
  <Route path="campaign-manager" element={<CampaignManager />}>
    <Route path="encounter-builder" element={<EncounterBuilder />} />
    <Route index element={<Navigate to="encounter-builder" />} />
  </Route>
);
