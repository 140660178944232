import ExampleSection from "../../../components/ExampleSection";
import Section from "../../../components/Section";

function Dice() {
  return (
    <Section>
      <h1 className="title-header">Dice</h1>
      <p>
        Emberbrand uses a set of seven polyhedral dice with varying numbers of
        faces. This set of dice contains one of each of the following: d4, d6,
        d8, d10, double-digit d10, d12, and d20. Rules and effects which
        reference these dice are written in the form of Xd#, where X is the
        number of dice to roll and # is the level of those dice.
      </p>
      <p>
        <b>d4:</b> Four-sided die
      </p>
      <p>
        <b>d6:</b> Six-sided die
      </p>
      <p>
        <b>d8:</b> Eight-sided die
      </p>
      <p>
        <b>d10:</b> Ten-sided die
      </p>
      <p>
        <b>d12:</b> Twelve-sided die
      </p>
      <p>
        <b>d20:</b> Twenty-sided die
      </p>
      <p>
        <b>Percentile:</b> A set of d10s rolled to determine a value between 1
        and 100. The double-digit d10 denotes the tens digit, while the normal
        d10 denotes the ones digit. For example, a roll of 20 + 4 results in 24.
        A roll of 00 + 0 results in 100, and a roll of 00 + 1 results in 1.
      </p>
      <p>
        <b>Dice level:</b> Each die used in Emberbrand has a level, which
        corresponds to the die's number of faces.
      </p>
      <ul>
        <li>I - d4</li>
        <li>II - d6</li>
        <li>III - d8</li>
        <li>IV - d10</li>
        <li>V - d12</li>
        <li>VI - d20</li>
      </ul>
      <p>
        If an effect causes you to reduce a die's level, replace the die you
        would roll with the die one level lower. If an effect causes you to
        increase a die's level, replace the die you would roll with the die one
        level higher. A die's level may never be reduced below d4 and may never
        be increased above d20.
      </p>
      <ExampleSection>
        <p>
          If you would roll a d8, but are instructed to reduce that die's level
          by 1, you would instead roll a d6. If you are instructed to increase
          that die's level by 2, you would instead roll a d12.
        </p>
      </ExampleSection>
    </Section>
  );
}

export default Dice;
