import Section from "../../../../components/Section";

function LwActionRoll() {
  return (
    <>
      <Section>
        <h1>Action Roll</h1>
        <p>
          Whenever you make an <b>action move</b>, you'll perform an action
          roll. The action roll uses a pool of standard and cursed dice which
          you'll create by combining an attribute with your Core, plus any adds.
          You will then determine the outcome of the move based on the number of
          6s rolled. Follow these steps to make an action roll.
        </p>
        <ol>
          <li>
            Gather a number of standard dice equal to the attribute instructed
            by the move, minus the amount of harm marked on that attribute.
          </li>
          <li>Add dice as granted by talents, moves, or other sources.</li>
          <li>Roll the dice pool.</li>
          <li>
            After rolling, you may add any number of dice up to your Core,
            suffering -1 to your Core for each die added. When adding core dice,
            you must first replace a number of core dice with cursed dice equal
            to your Curse. Added Core dice can give you a second chance to roll
            at least one 6, but you can only add Core dice once per action roll.
          </li>
          <li>
            After the final roll, count the number of 6s rolled. If you roll one
            6, you score a <b>weak hit</b>. If you roll more than one 6, you
            score a <b>strong hit</b>. If you roll no 6s, you <b>miss</b>. Also
            check to see if any cursed dice rolled a 1 and resolve a{" "}
            <b>cursed roll</b> if so.
          </li>
        </ol>
        <div className="example bg-info">
          <p>
            Aeden has a Wits score of 3, his current Core is 5, and his current
            Curse is 2. He needs to help navigate an expedition through troubled
            waters. The circumstances are dire, so it's vital this move
            succeeds.
          </p>
          <p>
            First, Aeden gathers three standard dice for his Wits score (he has
            no Confusion, so he takes the full number of dice). He currently has
            no adds, so he rolls the three dice. His roll shows a 1, 3, and 4,
            resulting in a miss. Desperate to succeed, Aeden decides to add
            three Core dice to his roll. He reduces his Core by 3 points, then
            checks his Curse. Because his Curse is 2, he must first add two
            cursed dice to his dice pool, then he adds one standard die, to meet
            the three Core he spent. He rolls the added dice, which show a 2 and
            a 6 on the cursed dice, and a 6 on the added standard die. Aeden
            scores a strong hit, successfully crossing a significant stretch of
            the expedition.
          </p>
        </div>
      </Section>
      <Section>
        <h1>Progress Roll</h1>
        <p>
          Many moves instruct you to mark progress on a progress track. When you
          feel you have made enough progress to reach your goal, make a{" "}
          <b>progress move</b> to determine the outcome. The progress move uses
          a pool of standard dice equal to the number of filled progress clocks.
        </p>
        <p>
          When you make a progress move, only gather a number of dice equal to
          the number of filled progress clocks on the relevant progress track.
          Ignore all attributes, Core, Curse, and any other stat for this roll.
          As with the action roll, count the number of 6s rolled to determine
          the outcome. If you roll one 6, you score a <b>weak hit</b>. If you
          roll more than one 6, you score a <b>strong hit</b>. If you roll no
          6s, you <b>miss</b>.
        </p>
      </Section>
      <Section>
        <h1>Cursed Roll</h1>
        <p>
          After making a move, if any cursed dice show a 1, you must resolve a{" "}
          <b>cursed roll</b>. A cursed roll can cause harm, confusion, or other
          negative effects. To make a cursed roll, roll a die on the{" "}
          <b>curse table</b> and add your Curse.
        </p>
      </Section>
    </>
  );
}

export default LwActionRoll;
